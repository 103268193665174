import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import styles for React Quill
import deleteBtn from "../../../assets/img/delete.svg";
import attechFile from "../../../assets/img/ios-attach.svg";
import filePic from "../../../assets/img/file-picture.svg";
import {
  MAX_FILE_SIZE,
  scopeTitleLimit,
  selectLoader,
  userNameLimit,
  validateTextInput,
} from "../../../utils/Helpers";
import APIServices from "../../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import DeleteModal from "../../../components/DeleteModal";
import { useParams } from "react-router-dom";

const ScopeOfWork = ({
  projectDetails,
  currentTab,
  setCurrentTab,
  setCompletedTabs,
}) => {
  const inputRefs = useRef([]);
  const { projectId: projectIdFromParams } = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [scopeId, setScopeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [showSkip, setShowSkip] = useState(true);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [sections, setSections] = useState([
    {
      id: "projectOverview",
      label: "Project Overview",
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    },
  ]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (projectDetails) {
      setProjectId(projectDetails._id);
    } else if (projectIdFromParams) {
      setProjectId(projectIdFromParams);
      setEditing(true);
    }
  }, [projectDetails, projectIdFromParams]);

  useEffect(() => {
    if (isEditing && currentTab == 3) {
      fetchScopeDetails();
      setFilesToDelete([]);
    }
  }, [currentTab]);

  const fetchScopeDetails = async () => {
    setPageLoading(true);
    try {
      const response = await APIServices.get(
        `/project-scope?projectId=${projectId}`
      );
      if (response.scope) {
        setFiles(response?.attachments);
        let scopeSections = response?.scope.map((scope) => {
          return {
            id: scope?._id,
            label: scope?.title,
            content: scope?.description,
            isEditable: !scope?.isAddendum,
            errors: { label: "", content: "" },
          };
        });
        setScopeId(response?._id);
        setSections(scopeSections);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error("Error fetching scope details:", error);
    } finally {
      setPageLoading(false);
    }
  };
  const handleTextChange = (value, id) => {
    // Find the section being updated
    const updatedSections = sections.map((section) =>
      section.id === id
        ? {
            ...section,
            content: value, // Update the content
            errors: { ...section.errors, content: "" },
          }
        : section
    );

    // Check if the content actually changed before updating state
    const sectionToUpdate = sections.find((section) => section.id === id);
    if (sectionToUpdate && sectionToUpdate.content !== value) {
      setSections(updatedSections);
    }
  };

  const handleTitleChange = (e, id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? {
            ...section,
            label: e.target.value,
            errors: { ...section.errors, label: "" },
          }
        : section
    );
    setSections(updatedSections);
  };

  const toggleEdit = (id) => {
    const updatedSections = sections.map((section) =>
      section.id === id
        ? { ...section, isEditable: !section.isEditable }
        : section
    );
    setSections(updatedSections);
  };

  const handleDelete = () => {
    const updatedSections = sections.filter(
      (section) => section.id !== sectionId
    );
    setSections(updatedSections);
    setDeleteOpen(false);
  };

  const handleDeleteOpen = (id) => {
    setSectionId(id);
    setDeleteOpen(true);
  };

  const addNewSection = () => {
    const newSection = {
      id: `newSection${sections.length + 1}`,
      label: ``,
      content: "",
      isEditable: true,
      errors: { label: "", content: "" },
    };
    setSections((prevSections) => {
      const updatedSections = [...prevSections, newSection];
      setTimeout(() => {
        const newIndex = updatedSections.length - 1;
        inputRefs.current[newIndex]?.focus();
      }, 0);
      return updatedSections;
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalFiles = files.length + selectedFiles.length;

    if (totalFiles > 5) {
      appToast("You can upload a maximum of 5 files.", TOAST_TYPE.ERROR);
      return;
    }

    const totalSize =
      files.reduce((sum, file) => sum + file.size, 0) +
      selectedFiles.reduce((sum, file) => sum + file.size, 0);

    if (totalSize > 40 * 1024 * 1024) {
      appToast(
        "The total size of uploaded files should not exceed 40MB.",
        TOAST_TYPE.ERROR
      );
      return;
    }

    setFiles([...files, ...selectedFiles]);
  };

  const handleRemoveFile = (index) => {
    if (isEditing) {
      let fileToDelete = files.filter((_, i) => i === index);
      if (fileToDelete && fileToDelete.length > 0 && fileToDelete[0]._id) {
        setFilesToDelete((prevFiles) => [...prevFiles, fileToDelete[0]._id]);
      }
    }
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const validateInputs = () => {
    let isValid = true;
    const updatedSections = sections.map((section) => {
      let labelError = "";
      let contentError = "";

      if (!validateTextInput(section.label).isValid) {
        if (!validateTextInput(section.label).cleanedValue) {
          labelError = "Scope Title is required.";
        } else {
          labelError = validateTextInput(section.label).message;
        }
        isValid = false;
      }

      if (!validateTextInput(section.content).isValid) {
        if (!validateTextInput(section.content).cleanedValue) {
          contentError = "Details are required.";
        } else {
          contentError = validateTextInput(section.content).message;
        }
        isValid = false;
      }

      return {
        ...section,
        errors: { label: labelError, content: contentError },
      };
    });

    setSections(updatedSections);
    return isValid;
  };

  const handleSubmit = async (isSkip = false) => {
    let contentType = {
      "Content-Type": "multipart/form-data",
    };

    if (!isSkip) {
      if (!validateInputs()) {
        return;
      }
    }
    setLoading(true);
    let formData = new FormData();

    formData.append("projectId", projectId);

    const scope = isSkip
      ? []
      : sections.map((section) => ({
          title: section.label,
          description: section.content,
        }));
    formData.append("scope", JSON.stringify(scope));

    files.forEach((file, index) => {
      formData.append(`attachments`, file);
    });

    if (isEditing && filesToDelete.length > 0) {
      formData.append("deletedAttachments", filesToDelete.join(","));
    }

    try {
      const response = isEditing
        ? await APIServices.put(
            `/project-scope/${scopeId}`,
            formData,
            contentType
          )
        : await APIServices.post("/project-scope", formData, contentType);

      if (response.projectId) {
        setScopeId(response._id);
        setCurrentTab(4);
        setCompletedTabs((prev) => ({
          ...prev,
          [4]: true,
        }));
        setShowSkip(false);
        setEditing(true);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="scope-of-work mt-5">
      {pageLoading ? (
        <div className="nodata-loader">{selectLoader(70)}</div>
      ) : (
        <form>
          {sections.map((section, index) => (
            <div className="mb-4" key={section.id}>
              <label
                htmlFor={section.id}
                className={`form-label bg-white d-flex  justify-content-between br-10 text-area-label ${
                  section.errors.label ? "form-invalid" : ""
                } ${!section.isEditable ? "input-desable" : ""}`}
              >
                {section.isEditable ? (
                  <input
                    readOnly={!section.isEditable}
                    maxLength={scopeTitleLimit}
                    value={section.label}
                    placeholder="Title"
                    onChange={(e) => handleTitleChange(e, section.id)}
                    className="text-left w-100 label-text-area border-none"
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ) : (
                  <span className="label-text-area w-100 text-left">
                    {section.label}
                  </span>
                )}
              </label>

              {section.errors.label && (
                <p className="text-danger">{section.errors.label}</p>
              )}

              {/* Replace textarea with ReactQuill for content */}
              <ReactQuill
                value={section.content}
                onChange={(value) => handleTextChange(value, section.id)}
                readOnly={!section.isEditable}
                placeholder="Write Details"
                className={`form-control br-10 mb-2 p-3 ${
                  section.errors.content ? "form-invalid" : ""
                } ${!section.isEditable ? "input-desable" : ""}`}
                style={{
                  maxHeight: "245px",
                  minHeight: "100px",
                  height: "auto",
                }}
              />
              {section.errors.content && (
                <p className="text-danger">{section.errors.content}</p>
              )}

              {index !== 0 && (
                <div className="delete-btn">
                  <button
                    disabled={!section.isEditable}
                    type="button"
                    className="btn btn-link"
                    onClick={() => handleDeleteOpen(section.id)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          ))}
          <div className="mb-4">
            <div className="cardbg-form-group">
              <div className="attech-file-cover">
                <label className="attech-btn">
                  <img src={attechFile} alt="comp1" className="attechFile" />
                  Attach Assets
                </label>
                <label className="add-btn-text cursor_pointer">
                  <input
                    accept="image/*"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  + Add
                </label>
              </div>
              {files.map((file, index) => (
                <div className="user-box-cover" key={index}>
                  <div className="username">
                    <img src={filePic} alt="comp1" className="filePic" />
                    <div className="username-content">
                      <p>{file.name}</p>
                      <small>
                      {(file.size / 1024).toFixed(2) !== "NaN" ? `${(file.size / 1024).toFixed(2)} KB` : file.size}
                      </small>
                    </div>
                  </div>
                  <img
                    src={deleteBtn}
                    alt="comp1"
                    className="deleteBtn"
                    onClick={() => handleRemoveFile(index)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="d-grid mt-5 mb-3 gap-3">
            <button
              className="add-new-section"
              type="button"
              onClick={addNewSection}
            >
              + Add new section
            </button>
            <button
              className="btn-black"
              type="button"
              onClick={() => {
                handleSubmit(false);
              }}
            >
              {!loading ? (isEditing ? "Update" : "Next") : selectLoader(35)}
            </button>
          </div>
        </form>
      )}
      <DeleteModal
        show={deleteOpen}
        onHide={() => {
          setDeleteOpen(false);
          setSectionId("");
        }}
        handleDelete={handleDelete}
        loading={loading}
        itemName={"section"}
      />
    </div>
  );
};

export default ScopeOfWork;
