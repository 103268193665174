import React, { useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import { AVATAR_IMAGE } from '../../assets/image';
import style from './InviteMember.module.css';

export default function InviteMember({ members, handleMember, assignees, handleRemove }) {
  const isSelected = (userId) => assignees?.some((value) => value._id === userId) || false;
  const [searchValue, setSearchValue] = useState('');
  const [filteredMembers, setFilteredMembers] = useState(members);

  const handleSearch = (e) => {
    const search = e.target.value;
    setSearchValue(e.target.value);
    const filteredValue = members?.filter((value) => value.fullName?.toLowerCase().includes(search?.toLowerCase()));
    setFilteredMembers(filteredValue);
  };

  return (
    <div className={style.invite_member_wrapper}>
      <div className={style.invite_member_header}>
        <div className={style.title}>Add Member</div>
        <div className={style.subtitle}>Share your project collaborate with your team</div>
      </div>
      <div className={style.invite_member_body}>
        <div className={style.autocomplete_selected}>
          <input value={searchValue} onChange={handleSearch} placeholder="Search" />
        </div>
        <div className={style.invite_member_item_wrapper}>
          {filteredMembers?.map((value) => (
            <div
              className={style.invite_member_item}
              key={value._id}
              onClick={() => !isSelected(value._id) && handleMember(value)}>
              <div className={style.member_info_view}>
                <img src={value.profilePic || AVATAR_IMAGE} alt="user" />
                <div>{value.fullName || value.email}</div>
              </div>
              {isSelected(value._id) && (
                <CloseButton
                  style={{ width: '14px', height: '14px', padding: 0 }}
                  onClick={() => handleRemove(value?._id)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
