import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/home.css';
import '../../assets/css/blank-project-list.css';
import notificationIcon from '../../assets/img/notification.svg';
import bellIcon from '../../assets/img/bell.svg';
import featherMenu from '../../assets/img/feather-menu.svg';
import { Dropdown, ProgressBar } from 'react-bootstrap';
import Header from '../../components/Header';
import akarFile from '../../assets/img/akar-file.svg';
import searchIcon from '../../assets/img/search.svg';
import listIcon from '../../assets/img/list.svg';
import gridIcon from '../../assets/img/grid.svg';
import listWhiteIcon from '../../assets/img/listwhite.svg';
import gridWhiteIcon from '../../assets/img/gridwhite.svg';
import chatBoxes from '../../assets/img/chatboxes.svg';
import { useAuth } from '../../contexts/AuthContext';
import ActionMenu from '../../components/ActionMenu';
import SidebarModal from '../../components/SidebarModal';
import KanbanIcon from 'assets/img/kanban.svg';
import Joyride from 'react-joyride';
import akarFile2 from '../../assets/img/akarFile2.svg';
import {
  clearLocalStorage,
  formatDate,
  formFields,
  getCookies,
  getformattedPhoneNumber,
  getPlainPhoneNumber,
  hasPermission,
  HomeSteps,
  orgNameLimit,
  PAGE_LIMIT,
  projectFilters,
  selectLoader,
  updateUserDetails
} from '../../utils/Helpers';
import { ProjectListStrings } from './strings/ProjectStrings';
import appToast, { TOAST_TYPE } from '../../utils/AppToast';
import { Link, useNavigate } from 'react-router-dom';
import APIServices from '../../services/APIServices';
import { useStore } from '../../hooks/useStore';
import UpdateProjectForm from './forms/UpdateProjectForm';
import SignOffForm from './forms/SignOffForm';
import Notifications from '../../components/Notifications';
import { fetchAllTeamMembers, fetchEnumOptions } from '../../utils/fetchData';
import Select from 'react-select';
import ProfileModalContent from './homeModals/ProfileModalContent';
import UpdateProfileModal from './homeModals/UpdateProfileModal';
import ProjectProgress from '../../components/ProjectProgress';

const Home = () => {
  const scrollRef = useRef(null);
  const { logout, fetchUserInfo, user, isUser1, userRole, socket } = useAuth();
  const [store, setStore] = useStore();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [isPojectsAvailable, setPojectsAvailable] = useState(true);
  const [projects, setProjects] = useState([]);
  const [formValues, setFormValues] = useState(formFields);
  const [profileUrl, setProfileUrl] = useState('');
  const [runTour, setRunTour] = useState(false);
  const [activeTab, setActiveTab] = useState('pills-profile');
  const [projectId, setProjectId] = useState('');
  const [updatedMilestoneId, setUpdatedMilestoneId] = useState('');
  const [userId, setUserId] = useState('');
  const [isUnread, setUnread] = useState(false);
  const [search, setSearch] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [memberId, setMemberId] = useState('');
  const [totalProjects, setTotalProjects] = useState(0);
  const [statusCounts, setStatusCounts] = useState({});
  const [showOpenProjects, setShowOpenProjects] = useState('open');
  const [loadProjects, setLoadProjects] = useState(false);
  const [hasMore, setHasMore] = useState(true); // To track if more projects exists
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [isVerified, setVerified] = useState({
    isEmailVerified: true,
    isPhoneNumberVerified: true
  });
  const [modals, setModals] = useState({
    profileModal: false,
    updateProfile: false,
    updateProject: false,
    notifications: false
  });

  // Handle tab change
  const handleTabChange = (tabId) => {
    setStore({ tabId: tabId });
    setActiveTab(tabId);
  };

  // functionalities for the paginations of projects while scrolling:
  const handleScroll = () => {
    const element = scrollRef.current;
    if (element) {
      const isAtBottom = (element.scrollHeight - element.scrollTop).toFixed(0) === element.clientHeight.toFixed(0);

      if (isAtBottom && !pageLoading && userId && currentPage && hasMore && !loadProjects) {
        fetchProjects(userId, currentPage + 1, true, setLoadProjects(true));
      }
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await fetchEnumOptions('projectupdate');
      if (response.length > 0) {
        const optionsData = response;
        const formattedStatus = optionsData.map((option) => ({
          id: option._id,
          value: option.value,
          label: option.name
        }));
        formattedStatus.unshift({
          value: '', // or permission._id if needed
          label: 'All'
        });
        setStatusOptions(formattedStatus);
        let onTimeStatus = formattedStatus.find((x) => x.value == 'ontime');
        // currentStatus(onTimeStatus.id);
      }
    } catch (error) {
      console.error('Error fetching status options:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch details of the user.
  const fetchUserDetails = async (user) => {
    const token = getCookies('authToken');

    if (!token) return; // Early return if no token

    try {
      setPageLoading(true);
      const userDetails = await fetchUserInfo();

      // setDataLoading(true);
      if (userDetails) {
        if (!userDetails.companyId) {
          navigate('/complete-profile');
        }
        const {
          email,
          countryDialCode,
          phoneNumber,
          fullName,
          countryCode,
          profilePic,
          companyId,
          isTotorialVisited,
          isEmailVerified,
          isPhoneNumberVerified
        } = userDetails;

        // Update form values and verification status in a single state update
        setFormValues((prevValues) => ({
          ...prevValues,
          email,
          countryDialCode,
          phoneNumber: getformattedPhoneNumber(phoneNumber),
          fullName,
          countryCode,
          profilePic,
          companyName: companyId?.name,
          isTotorialVisited
        }));

        setVerified({
          isEmailVerified,
          isPhoneNumberVerified
        });

        // Trigger the tour based on tutorial visited status
        setRunTour(!isTotorialVisited);
        setUserId(userDetails._id);
        fetchProjects(userDetails._id, currentPage);
        fetchAllTeamMembers(setTeamMembers);
        fetchStatusOptions();
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchProjects = async (userId, page = 0, isSocket = false, callback) => {
    if (!userId) return;
    if (callback) {
      callback();
    }
    if (!isSocket) {
      setPageLoading(true);
    }
    try {
      const response = await APIServices.get(
        `/project/user/${userId}?search=${search}&page=${page}&limit=${15}&status=${currentStatus}&teamUserId=${memberId}&projectStatus=${showOpenProjects}`
      );
      if (response.projects) {
        if (page == 1) {
          setProjects(response.projects);
          setHasMore(true);
        } else {
          setProjects((prev) => [...response.projects, ...prev]);
          console.log(response.projects);

          const scrollContainer = scrollRef.current;
          const previousScrollHeight = scrollContainer?.scrollHeight;
          const previousScrollTop = scrollContainer.scrollTop;
          setTimeout(() => {
            const newScrollHeight = scrollContainer?.scrollHeight;
            // Adjust scrollTop to maintain the same view position
            scrollContainer.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight - 20);
          }, 0);
          setHasMore(response.projects.length == 12);
        }
        setStatusCounts(response?.statusCounts);
        setTotalProjects(response?.totalProjects);
        if (response.projects.length > 0) {
          // setPojectsAvailable(true);
        }
        
        setCurrentPage(page);
      } else if (response?.data?.message) {
        appToast(response?.data?.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setPageLoading(false);
      setDataLoading(false);
      setLoadProjects(false);
    }
  };

  useEffect(() => {
    if (!userId) return;

    const handler = setTimeout(() => {
      fetchProjects(userId, 1);
    }, 300);

    // Cleanup function to clear the timeout if dependencies change before delay
    return () => clearTimeout(handler);
  }, [search, currentStatus, memberId, showOpenProjects]);

  useEffect(() => {
    fetchUserDetails();
    clearLocalStorage();
    setActiveTab(store.tabId || 'pills-profile');
  }, []);

  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen
    }));
  };

  const updateCloseModal = (modalname) => {
    if (modalname == 'updateProject') {
      setCurrentPage(1);
    }
    toggleModal(modalname, false);
    fetchProjects(userId, currentPage);
  };

  const handleKanbanIconClick = () => navigate('/project-boards'); // This goes back to the previous page
  const updateProjects = (apiProjects, socketProject) => {
    return apiProjects.map((project) => (project._id === socketProject._id ? socketProject : project));
  };

  const isEditor = (project) => {
    let isPermission = project?.projectTeam?.myTeam?.find((member) => member.userId._id === userId)?.permission;
    // console.log(isPermission.name);
    if (isPermission?.name === 'View') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on('Notification', (Notification) => {
        // scrollToBottom();
        setUnread(true);
        console.log('Notification', Notification);
      });
      socket.on('ProjectCreated', (Notification) => {
        setHasMore(true);
        fetchProjects(userId, currentPage, true);
        console.log('ProjectCreated', Notification);
      });
      socket.on('ProjectUpdated', (updatedProject) => {
        // setHasMore(true);
        // fetchProjects(userId, currentPage, true);
        // if (updatedProject && projects.length > 0) {
        setProjects((prevProjects) => updateProjects(prevProjects, updatedProject));
        // }
        console.log('ProjectUpdated', updatedProject);
      });
    }
  }, [socket]);

  const HeaderContent = (
    <>
      {isPojectsAvailable ? (
        <div className="btn-group-head">
          <button className="btn-black">
            <span>
              {totalProjects < 10 && 0}
              {totalProjects}
            </span>
            Project{totalProjects > 1 && 's'}
          </button>
          <button className="btn-black project-btn-list">
            <span
              className="light-blue-bg"
              onClick={() => {
                setCurrentStatus('early');
              }}>
              {statusCounts?.early < 10 && 0}
              {statusCounts?.early || 0}
            </span>

            <span
              className="light-green-bg"
              onClick={() => {
                setCurrentStatus('ontime');
              }}>
              {statusCounts?.ontime < 10 && 0}
              {statusCounts?.ontime || 0}
            </span>

            <span
              className="light-red-bg"
              onClick={() => {
                setCurrentStatus('late');
              }}>
              {statusCounts?.late < 10 && 0}
              {statusCounts?.late || 0}
            </span>
          </button>
        </div>
      ) : (
        ''
      )}
      <div className="head-right">
        {isUser1 && hasPermission(userRole, 'update_projects') && (
          <div onClick={handleKanbanIconClick}>
            <img src={KanbanIcon} alt="kanban" style={{ cursor: 'pointer' }} />
          </div>
        )}
        <Dropdown className="notify-drp">
          <Dropdown.Toggle
            as="a"
            className="dropdown-toggle"
            onClick={() => {
              toggleModal('notifications', true);
              setUnread(false);
            }}>
            {isUnread ? (
              <img src={notificationIcon} alt="comp1" className="notification-img" />
            ) : (
              <img src={bellIcon} alt="comp1" className="notification-img" />
            )}
          </Dropdown.Toggle>
        </Dropdown>
        <a>
          <img
            src={featherMenu}
            alt="menu"
            className="feather-img menuicon"
            onClick={() => {
              toggleModal('profileModal', true);
            }} // Sidebar toggle handler
          />
        </a>
      </div>
    </>
  );

  // Handle events like close or skip
  const handleJoyrideCallback = (data) => {
    const { action } = data;

    if (action == 'reset' || action === 'close') {
      setRunTour(false);
      let plainNumber = getPlainPhoneNumber(formValues.phoneNumber);

      const updatedFormValues = {
        ...formValues,
        isTotorialVisited: true,
        phoneNumber: plainNumber
      };

      setFormValues(updatedFormValues);
      let isUpdated = updateUserDetails(updatedFormValues, setError, setLoading, false);
    }
    // console.log(data); // Log the Joyride event for debugging
  };

  const handleUpdateProject = (project) => {
    navigate('/overview', {
      state: { projectId: project._id, projectDetails: project }
    });
  };
  const handleUpdateMilestone = (projectId) => {
    toggleModal('updateProject', true);
    setProjectId(projectId);
    console.log(projectId);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="wrapper">
      <div className="main-content">
        <Header content={HeaderContent} />
        {dataLoading ? (
          <div className="nodata-loader">{selectLoader(75)} </div>
        ) : isPojectsAvailable ? (
          <section className="dashboard-sec">
            <Joyride
              callback={handleJoyrideCallback}
              steps={HomeSteps}
              continuous={true}
              showSkipButton={false}
              hideCloseButton={true}
              showProgress={true}
              run={runTour}
              locale={{
                back: ProjectListStrings.prevButton,
                close: ProjectListStrings.closeButton,
                last: ProjectListStrings.lastButton,
                next: ProjectListStrings.nextButton,
                skip: ProjectListStrings.skipButton
              }}
            />
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card-top-sec">
                    <div className="top-field-cover">
                      <div className="form-group search-box">
                        <input
                          maxLength={orgNameLimit}
                          type="search"
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          id="search-box-tour"
                          className="form-control search-box-control"
                          placeholder={ProjectListStrings.searchPlaceholder}
                        />
                        <img src={searchIcon} className="search-icon" />
                      </div>

                      <div className="form-group select-project form-group-select-icon">
                        <Select
                          className={`form-control select2 `}
                          options={teamMembers || []}
                          value={teamMembers.find((option) => option.value === memberId)}
                          onChange={(option) => setMemberId(option?.value)}
                          placeholder="Members"
                          // isClearable
                          isLoading={loading}
                        />
                      </div>

                      <div className="form-group select-status form-group-select-icon">
                        <Select
                          className={`form-control select2 `}
                          options={statusOptions || []}
                          value={statusOptions.find((option) => option.value === currentStatus)}
                          onChange={(option) => setCurrentStatus(option?.value)}
                          placeholder="Status"
                          // isClearable
                          isLoading={loading}
                        />
                      </div>
                      <div className="form-group select-status form-group-select-icon">
                        <Select
                          className={`form-control select2 `}
                          options={projectFilters || []}
                          value={projectFilters.find((option) => option.value === showOpenProjects)}
                          onChange={(option) => setShowOpenProjects(option?.value)}
                          placeholder="Open"
                          // isClearable
                          isLoading={loading}
                        />
                      </div>
                    </div>

                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab == 'pills-home' ? 'active' : ''}`}
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="false"
                          onClick={() => handleTabChange('pills-home')}>
                          <img
                            src={activeTab == 'pills-home' ? listWhiteIcon : listIcon}
                            className="list-icon"
                            alt={ProjectListStrings.homeTabAlt}
                          />
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab == 'pills-profile' ? 'active' : ''}`}
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="true"
                          onClick={() => handleTabChange('pills-profile')}>
                          <img
                            src={activeTab == 'pills-profile' ? gridWhiteIcon : gridIcon}
                            className="home-icon"
                            alt={ProjectListStrings.profileTabAlt}
                          />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={`tab-pane fade  ${activeTab == 'pills-home' ? 'show active' : ''}`}
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="project-list">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  {pageLoading ? (
                                    <div className="nodata-loader">{selectLoader(70)}</div>
                                  ) : (
                                    projects.map((project) => (
                                      <tr
                                        className="bottom-spacing cursor_pointer"
                                        key={project._id}
                                        onClick={() => handleUpdateProject(project)}>
                                        <td style={{ width: '450px' }}>
                                          <p>{project?.projectName}</p>
                                          {project?.latestUpdate[0]?.updateStatus?.value == 'late' ? (
                                            <p className="red-text">#Late</p>
                                          ) : project?.latestUpdate[0]?.updateStatus?.value == 'early' ? (
                                            <p className="blue-text">#Early</p>
                                          ) : (
                                            <p className="green-text">#On-time</p>
                                          )}
                                        </td>
                                        <td style={{ minWidth: '350px' }}>
                                          <div className="tableList-processBg">
                                            <ProjectProgress project={project} />
                                          </div>
                                        </td>

                                        <td style={{ width: '13%' }}>
                                          {hasPermission(userRole, 'update_projects') && isEditor(project) && (
                                            <button
                                              className="updated-btn"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleUpdateMilestone(project._id);
                                              }}>
                                              Update
                                            </button>
                                          )}
                                          <div className="d-flex align-items-center justify-content-end">
                                            {project.updateCount > 0 && (
                                              <>
                                                <div className="me-2">
                                                  <img src={chatBoxes} style={{ maxWidth: 'none' }} />
                                                </div>
                                                <h4 className="mb-0">{project.updateCount}</h4>{' '}
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {projects.length == 0 && (
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="blankList-box-cover">
                              <div className="blankList-box">
                                <img className="akarFile" src={akarFile2} />

                                <h2>We couldn’t find any data to display.</h2>

                                <p>Try updating your search criteria.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      onScroll={handleScroll}
                      ref={scrollRef}
                      className={`tab-pane fade  ${activeTab == 'pills-profile' ? 'show active' : ''}`}
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab">
                      <div className="row">
                        {pageLoading ? (
                          <div className="nodata-loader">{selectLoader(70)} </div>
                        ) : (
                          projects.map((project) => (
                            <div className="col-lg-4 col-md-6 mb-4" key={project._id}>
                              <div className="card-box cursor_pointer" onClick={() => handleUpdateProject(project)}>
                                <div className="inner-card-box mheight-200">
                                  <div className="title-content">
                                    <h3 className="cursor_pointer">{project.projectName}</h3>
                                    {hasPermission(userRole, 'update_projects') && isEditor(project) && (
                                      <button
                                        className="updated-btn"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleUpdateMilestone(project._id);
                                        }}>
                                        Update
                                      </button>
                                    )}
                                  </div>
                                  <div className="org-text">{isUser1 ? (project?.organizationId?.name || project?.createdBy?.companyId?.name)  : project?.createdBy?.companyId?.name}</div>

                                  <div className="time-details">
                                    {project?.latestUpdate[0]?.updateStatus?.value == 'late' ? (
                                      <h3 className="progress-delaytext">#Late</h3>
                                    ) : project?.latestUpdate[0]?.updateStatus?.value == 'early' ? (
                                      <h3 className="progress-earlytext">#Early</h3>
                                    ) : (
                                      <h3>#On-time</h3>
                                    )}
                                  </div>
                                  <ProjectProgress project={project} />
                                </div>
                                <div className="card-bottom">
                                  <div className="comment-content">
                                    <div className="d-flex">
                                      {project.updateCount > 0 && (
                                        <>
                                          <div className="comment-chat">
                                            <img src={chatBoxes} alt="Chat Icon" />
                                          </div>
                                          <h5>{project.updateCount} Updates</h5>
                                        </>
                                      )}
                                    </div>
                                    {project?.latestUpdate[0]?.createdBy ? (
                                      <h5>
                                        Modified by {project.latestUpdate[0].createdBy.fullName},{' '}
                                        {formatDate(project.createdBy.updatedAt || new Date())}
                                      </h5>
                                    ) : (
                                      <h5>
                                        Created by {project.createdBy.fullName},{' '}
                                        {formatDate(project.startDate || new Date())}
                                      </h5>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                        {loadProjects && <div>{selectLoader(70)} </div>}
                      </div>
                      {projects.length == 0 && (
                        <div className="row align-items-center">
                          <div className="col-lg-12">
                            <div className="blankList-box-cover">
                              <div className="blankList-box">
                                <img className="akarFile" src={akarFile2} />

                                <h2>We couldn’t find any data to display.</h2>

                                <p>Try updating your search criteria.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hasPermission(userRole, 'modify_team') && (
              <ActionMenu
                extraClass="options-list-comman"
                links={[
                  hasPermission(userRole, 'create_projects') && {
                    text: ProjectListStrings.createProjectText,
                    className: 'create_project',
                    linkto: '/create-project'
                  },
                  hasPermission(userRole, 'modify_team') && {
                    text: ProjectListStrings.createTeamText,
                    className: 'create_team',
                    linkto: '/myteam'
                  }
                  // hasPermission(userRole, "create_projects") && {
                  //   text: ProjectListStrings.createTaskText,
                  //   className: "create_task",
                  //   linkto: "/create-project",
                  // },
                ]}
                buttonIcon="fas fa-plus"
                buttonStyles={{ backgroundColor: 'blue', color: 'white' }}
                showItem={!formValues?.isTotorialVisited}
              />
            )}
          </section>
        ) : (
          <section className="blank-sec dashboard-sec">
            <div className="container">
              <div className="row align-items-start">
                <div className="col-lg-12">
                  <div className="blankList-box-cover">
                    <div className="blankList-box">
                      <img className="akarFile" src={akarFile} />
                      <h2>{ProjectListStrings.noProjectsTitle}</h2>
                      {hasPermission(userRole, 'update_projects') && <p>{ProjectListStrings.noProjectsDescription}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {hasPermission(userRole, 'modify_team') && (
              <ActionMenu
                extraClass="options-list-comman"
                links={[
                  hasPermission(userRole, 'create_projects') && {
                    text: ProjectListStrings.createProjectText,
                    className: 'create_project',
                    linkto: '/create-project'
                  },
                  hasPermission(userRole, 'modify_team') && {
                    text: ProjectListStrings.createTeamText,
                    className: 'create_team',
                    linkto: '/myteam'
                  }
                  // hasPermission(userRole, "create_projects") && {
                  //   text: ProjectListStrings.createTaskText,
                  //   className: "create_task",
                  //   linkto: "/create-project",
                  // },
                ]}
                buttonIcon="fas fa-plus"
                buttonStyles={{ backgroundColor: 'blue', color: 'white' }}
                showItem={false}
              />
            )}
          </section>
        )}
        {/* Modal Component */}
        <SidebarModal show={modals.profileModal} onHide={() => toggleModal('profileModal', false)}>
          <ProfileModalContent
            profileUrl={profileUrl}
            formValues={formValues}
            isVerified={isVerified}
            toggleModal={toggleModal}
            userRole={userRole}
            hasPermission={hasPermission}
            logout={logout}
          />
        </SidebarModal>

        {/* Modal Component */}
        <SidebarModal
          extraClass={'edit-profile-modal notification-modal'}
          show={modals.notifications}
          onHide={() => toggleModal('notifications', false)}
          headerTitle={'Happy Ending Project'}>
          <Notifications closeModal={() => toggleModal('notifications', false)} />
        </SidebarModal>

        <SidebarModal
          extraClass={'edit-profile-modal'}
          show={modals.updateProfile}
          onHide={() => toggleModal('updateProfile', false)}
          headerTitle={ProjectListStrings.editProfileHeader}>
          <UpdateProfileModal
            profileUrl={profileUrl}
            formValues={formValues}
            setError={setError}
            isVerified={isVerified}
            toggleModal={toggleModal}
            setFormValues={setFormValues}
            selectLoader={selectLoader}
            setProfileUrl={setProfileUrl}
          />
        </SidebarModal>
        <SidebarModal
          extraClass={'edit-profile-modal'}
          show={modals.updateProject}
          onHide={() => toggleModal('updateProject', false)}
          headerTitle={'Update Project'}>
          <UpdateProjectForm projectId={projectId} closeModal={() => updateCloseModal('updateProject')} />
        </SidebarModal>
        <SidebarModal
          extraClass={'edit-profile-modal'}
          show={modals.signOffModal}
          onHide={() => {
            toggleModal('signOffModal', false);
            setUpdatedMilestoneId('');
          }}
          headerTitle={'Sign-off Request'}>
          <SignOffForm
            updatedMilestoneId={updatedMilestoneId}
            projectId={projectId}
            closeModal={() => {
              toggleModal('signOffModal', false);
              setUpdatedMilestoneId('');
            }}
          />
        </SidebarModal>
      </div>
    </div>
  );
};

export default Home;
