import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import TextEditor from 'shared/form/TextEditor/TextEditor';
import HeadingWithIcon from 'pages/projects/kanban/shared/HeadingWithIcon';
import menuIcon from 'assets/img/menu-03.svg';
import style from './Description.module.css';

export default function Description({ description, handleSubmitDescription, viewMode, handleDescriptionChange }) {
  const [showTextEditor, setShowTextEditor] = useState(false);

  return (
    <div>
      <HeadingWithIcon icon={menuIcon} heading="Description" />
      <div className={style.desciption_text_editor_wrapper}>
        {showTextEditor ? (
          <>
            <TextEditor value={description} onChange={handleDescriptionChange} />
            <div className={style.button_group}>
              <Button
                variant="success"
                onClick={() => {
                  setShowTextEditor(false);
                  handleSubmitDescription(description);
                }}>
                Save
              </Button>
              <Button variant="link" onClick={() => setShowTextEditor(false)}>
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <div onClick={() => (viewMode ? {} : setShowTextEditor(true))}>
            {description ? (
              <div className={style.description_info}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
            ) : (
              <textarea placeholder="Add a more detailed description... " description={description} readOnly={true} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
