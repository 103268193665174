import React from "react";
import { Modal } from "react-bootstrap";

const SidebarModal = ({extraClass = "", show, onHide, headerTitle = "", children }) => {
  return (
    <div className={`right-sidebar-modal`}>
      <Modal show={show} onHide={onHide} backdrop={true} dialogClassName="" className={`right-sidebar-modal right fade ${extraClass}`}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </div>
  );
};

export default SidebarModal;
