import React, { useCallback, useEffect, useState } from 'react';
import '../../assets/css/clients.css';
import ResponsivePagination from 'react-responsive-pagination';
import Header from '../../components/Header';
import leftArrow from '../../assets/img/left-arrow.svg';
import SidebarModal from '../../components/SidebarModal';
import arrowDown from '../../assets/img/arrow-down.svg';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import UserForm from './forms/UserForm';
import APIServices from '../../services/APIServices';
import { getformattedPhoneNumber, PAGE_LIMIT, selectLoader } from '../../utils/Helpers';
import appToast, { TOAST_TYPE } from '../../utils/AppToast';
import { useAuth } from '../../contexts/AuthContext';
import EmptyList from '../../components/EmptyList';
import DeleteModal from '../../components/DeleteModal';
import { fetchRolesList } from '../../utils/fetchData';
import addicon from '../../assets/img/add2.svg';
const MyTeam = () => {
  const navigate = useNavigate();
  const { user, inviterId } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState(null);
  const [teamId, setTeamId] = useState('');
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [totalPages, setTotalPages] = useState(0);
  const [memberToDelete, setMemberToDelete] = useState(null);

  const handlePageChange = useCallback(
    (page) => {
      if (page && page !== currentPage) {
        setCurrentPage(page);
        fetchTeams(page, teamId);
      }
    },
    [currentPage]
  );

  const handleBackClick = () => {
    navigate('/'); // This goes back to the previous page
  };

  const toggleModal = (isOpen) => {
    setModalOpen(isOpen);
    if (isOpen == false) {
      setMember(null);
    }
  };

  const fetchTeams = async (currentPage = 1, teamId) => {
    try {
      const response = await APIServices.get(`/team/${teamId}?page=${currentPage}&limit=${PAGE_LIMIT}`);
      if (response.members) {
        setTeams(response.members || []);
        setCurrentPage(Number(response.currentPage));
        setTotalPages(response.totalPages);
      } else if (response?.data?.message) {
        setTeams([]);
      }
    } catch (error) {
      console.error('Error fetching the teams:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setTeamId(user?.companyId?._id);
      fetchTeams(1, user?.companyId?._id);
      fetchRolesList(setRoles, setLoading, setError);
    }
  }, [user]);

  const closeAndUpdateList = () => {
    fetchTeams(1, teamId);
    toggleModal(false);
  };

  const handleResendInvite = async (member) => {
    let payload = {
      fullName: member.fullName,
      email: member.email,
      phoneNumber: member.phoneNumber,
      userRoleName: member.userType._id,
      countryDialCode: member.countryDialCode,
      countryCode: member.countryCode,
      inviterId: inviterId,
      isResendInvitation: true
    };
    try {
      const response = await APIServices.post('/user/invite-user', payload);
      if (response.message) {
        appToast(`Invitation successfully resent to ${member.fullName} (${member.email})`, TOAST_TYPE.SUCCESS);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (member) => {
    setMember(member);
    toggleModal(true);
  };

  const handleDeleteMember = (id) => {
    setMemberToDelete(id); // Set the member ID to be deleted
    setDeleteOpen(true); // Open the delete confirmation modal
  };

  const confirmDeleteMember = async () => {
    if (memberToDelete) {
      setLoading(true);
      try {
        let response = await APIServices.put(`/team/member/${memberToDelete}`);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
        fetchTeams(1, teamId); // Refresh the team list after deletion
        setDeleteOpen(false); // Close the modal
        setMemberToDelete(null); // Clear the member to delete
      } catch (error) {
        console.error('Error deleting Member:', error);
        appToast('Failed to delete Member', TOAST_TYPE.ERROR);
      }
    }
  };

  const handleToggle = async (userId, newTeamStatus, status) => {
    const data = {
      userId: userId,
      teamStatus: newTeamStatus
    };
    if (status == 'invited') {
      appToast('User needs to accept the invitation from the email first.', TOAST_TYPE.WARNING);
    } else {
      // Update the team status in the state after successful API call
      setTeams((prevTeams) =>
        prevTeams.map((member) => (member._id === userId ? { ...member, teamStatus: newTeamStatus } : member))
      );
      try {
        // Example API call (adjust as per your actual API endpoint)
        const response = await APIServices.put('/team/member/status/update', data); // Adjust endpoint accordingly

        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          fetchTeams(1, teamId);
        } else {
          appToast('Failed to update team status', TOAST_TYPE.ERROR);
        }
      } catch (error) {
        console.error('Error updating team status:', error);
        appToast('Failed to update team status', TOAST_TYPE.ERROR);
      }
    }
  };

  const HeaderContent = (
    <>
      <h2>My Team</h2>
      <div className="head-right">
        <a onClick={handleBackClick}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  const ModalContent = (
    <>
      <UserForm initialData={member} roleOptions={roles} closeModal={closeAndUpdateList} />
    </>
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="wrapper">
      <div className="main-content client_directory">
        <Header content={HeaderContent} />
        {loading ? (
          <div className="nodata-loader">{selectLoader(100)}</div>
        ) : teams?.length == 0 ? (
          <EmptyList buttonText={'Add New Members'} openModal={() => toggleModal(true)} />
        ) : (
          <section className="companies-sec">
            <div className="container">
              <div className="row client-desktop-view">
                <div className="col-lg-12 col-md-12">
                  <div className="companies-top-head">
                    <button onClick={() => toggleModal(true)} type="button" className="add-user">
                      + Add New Members
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="companies-card">
                    <div className="companies-list">
                      <div className="top-headTitle">
                        <h2>Team Member</h2>
                        <button className="team-add-btn" onClick={() => toggleModal(true)}>
                          <img src={addicon} alt="addicon" className="addicon" />
                          Add
                        </button>
                      </div>
                      <table className="grid">
                        <thead>
                          <tr>
                            <th>Team Member</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {teams?.map((member, memberIndex) => (
                            <tr key={`${memberIndex}`}>
                              <td>{member.fullName}</td>
                              <td className="mobile-view-td">
                                <ul>
                                  <li>
                                    <a href="#">{member.email}</a>
                                  </li>
                                  <li>
                                    {member.countryDialCode + getformattedPhoneNumber(member.phoneNumber)}
                                    {/* {member.countryDialCode +
                                      member.phoneNumber} */}
                                  </li>
                                </ul>
                              </td>
                              <td className="mobile-view-td">
                                <ul className=" py-0">
                                  <li>{member.userType.name}</li>
                                  <li className="accept-text"> {member.status}</li>
                                </ul>
                              </td>
                              <td className="desktop-view-td">{member.email}</td>
                              <td className="desktop-view-td">
                                {member.countryDialCode + ' ' + getformattedPhoneNumber(member.phoneNumber)}
                                {/* {member.countryDialCode + member.phoneNumber} */}
                              </td>
                              <td className="desktop-view-td">{member.userType.name}</td>
                              <td className="desktop-view-td">{member.status}</td>
                              <td>
                                <ul className="actions">
                                  <li>
                                    <button className="btn btn-pen" onClick={() => handleEditClick(member)}>
                                      <i className="fas fa-pen"></i>
                                    </button>
                                  </li>
                                  <li>
                                    <button className="btn btn-trash" onClick={() => handleDeleteMember(member._id)}>
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="btn btn-trash"
                                      disabled={member.status != 'invited'}
                                      onClick={() => handleResendInvite(member)}>
                                      <i className="fas fa-envelope"></i>
                                    </button>
                                  </li>
                                  <li className="custom-switch-right">
                                    <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                      <input
                                        checked={member.status == 'invited' ? false : member.teamStatus}
                                        readOnly={member.status == 'invited'}
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`customSwitch-${memberIndex}`} // Make id dynamic
                                        onChange={() => handleToggle(member._id, !member.teamStatus, member.status)}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`customSwitch-${memberIndex}`} // Make for dynamic
                                      >
                                        &nbsp;
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {totalPages > 1 && (
                          <tfoot>
                            <tr colSpan={5} className="tr_empty"></tr>
                            <tr>
                              <td colSpan="6" style={{ padding: 0 }}>
                                <div className="pagination-pages">
                                  <ResponsivePagination
                                    current={currentPage}
                                    total={totalPages}
                                    onPageChange={handlePageChange}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Modal Component */}
        <SidebarModal
          extraClass={'edit-profile-modal client_modal'}
          show={modalOpen}
          onHide={() => toggleModal(false)}
          headerTitle={member ? 'Edit Team Member' : 'Add Team Member'}>
          {ModalContent}
        </SidebarModal>
        <DeleteModal
          show={deleteOpen}
          onHide={() => {
            setDeleteOpen(false);
            setMemberToDelete(null); // Clear the member to delete on cancel
          }}
          handleDelete={confirmDeleteMember} // Pass the deletion logic here
          loading={loading}
          itemName={'member'}
        />
      </div>
    </div>
  );
};

export default MyTeam;
