import Axios from './Axios';

export const fetchProjectBoard = async (projectBoardId) => {
  try {
    return await Axios.get(`/project-board/${projectBoardId}`);
  } catch (error) {
    console.error('Error fetching permissions:', error);
    return null;
  }
};

export const fetchProjectMilestonesByBoard = async (projectBoardId) => {
  try {
    const response = await Axios.get(`/project-milestone?projectBoardId=${projectBoardId}`);
    if (response.length > 0) {
      return response;
    }
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
};

export const createProjectBoard = async (name, projectId) => {
  try {
    return await Axios.post('/project-board', { name, projectId });
  } catch (error) {
    console.error('Error Fetching Projects:', error);
    throw error;
  }
};

export const fetchMilestoneDetail = async (milestoneId) => {
  try {
    return await Axios.get(`/project-milestone/${milestoneId}`);
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
};

export const fetchEnumOptions = async (enumName) => {
  try {
    return await Axios.get(`/enum?item_type=${enumName}`); // Adjust the URL as necessary
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
};
