import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import style from './TextEditor.module.css';
import APIServices from 'services/APIServices';
import { selectLoader } from 'utils/Helpers';
const toolbarOptions = [
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ color: [] }], // dropdown with defaults from theme
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons

  [{ align: [] }],
  [{ list: 'ordered' }, { list: 'bullet' }],

  ['image', 'link']
];

export default function TextEditor({ value, onChange }) {
  const [isLoading, setIsLoading] = useState(false);
  const reactQuillRef = useRef();

  useEffect(() => {
    if (reactQuillRef.current) {
      const editor = reactQuillRef.current.getEditor();
      editor.focus(); // Focus on the editor when it renders
    }
  }, []);

  const imageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        setIsLoading(true);
        try {
          const files = [...input.files];
          const formData = new FormData();
          files.forEach((file) => formData.append('attachments', file));

          const response = await APIServices.post('/project-milestone/upload', formData, {
            'Content-Type': 'multipart/form-data'
          });

          if (response?.data) {
            const quill = reactQuillRef.current;
            if (quill) {
              const range = quill.getEditorSelection();
              range && quill.getEditor().insertEmbed(range.index, 'image', response?.data[0]?.url);
            }
          }
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
      }
    };
  }, []);

  return (
    <div className={style.text_editor_wrapper}>
      {isLoading && <div className={style.text_editor_loader}>{selectLoader(60)} </div>}
      <ReactQuill
        theme="snow"
        className='quill-container'
        ref={reactQuillRef}
        placeholder="Write a comment"
        value={value}
        onChange={onChange}
        modules={{ toolbar: { container: toolbarOptions, handlers: { image: imageHandler } } }}
        // readOnly={true}
      />
    </div>
  );
}
