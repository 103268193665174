import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCookies, getSocketConnected, setCookies } from '../utils/Helpers';
import APIServices from '../services/APIServices';
import { useStore } from '../hooks/useStore';

export const AuthContext = createContext();
const user2 = ['admin', 'user'];
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(getCookies('authToken'));
  const [store, setStore] = useStore();
  const [user, setUser] = useState(false);
  const [isUser1, setUser1] = useState(true);
  const [inviterId, setInviterId] = useState('');
  const [userRole, setUserRole] = useState('');
  const [socket, setSocket] = useState(null);
  const [userCompany, setUserCompany] = useState("");

  const fetchUserInfo = async () => {
    if (!token) return;

    try {
      const userRes = await APIServices.get(`/user/detail?token=${token}`);
      let userRole;
      if (!userRes) return [];

      if (userRes.status === 404) {
        logout();
        return [];
      }

      const userData = userRes.data;
      setUser(userData);
      setInviterId(userData?._id);
      if(userData.companyId){
        setUserCompany(userData.companyId)
      }
      if (userData?.settings?.dateTimeFormat) {
        const { dateFormat, timeFormat, startCalenderWeek } = userData.settings.dateTimeFormat;
        setStore({
          currentDateFormat: dateFormat,
          currentTimeFormat: timeFormat,
          startCalenderWeek
        });
      }
      if (userData?.organizations[0]) {
        userRole = userData?.organizations[0].userType.role;
      } else {
        userRole = userData?.userType?.role;
      }
      console.log(userRole);
      setUserRole(userRole);

      if (user2.includes(userRole)) {
        setUser1(false);
      }

      return userData;
    } catch (error) {
      console.error('Error fetching user info:', error);
      return [];
    }
  };

  useEffect(() => {
    if (user && !socket) {
      let newSocket = getSocketConnected(user._id);
      setSocket(newSocket);

      // Listen for connection status
      newSocket.on('connect', () => {
        console.log('Socket connected');
      });

      newSocket.on('disconnect', () => {
        console.log('Socket disconnected');
      });
      // // Clean up the socket connection when component unmounts
      // return () => {
      //   newSocket.disconnect();
      // };
    }
  }, [user]);

  useEffect(() => {
    if (!token) return;
    fetchUserInfo();
  }, [token]);

  const logout = () => {
    setUser(null);
    setCookies('authToken', null);
    refreshToken('');
    setUser1(true);
  };

  // If user logoff or login, update token from child component
  const refreshToken = (newToken) => {
    setToken(newToken);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        refreshToken,
        logout,
        token,
        fetchUserInfo,
        inviterId,
        isUser1,
        userRole,
        socket,
        userCompany
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
