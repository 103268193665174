import React from 'react';
import style from './style.module.css';

export default function CardWithIcon({ img, title, onClick }) {
  return (
    <div className={style.card_wrapper} onClick={onClick}>
      <div className={style.content_wrapper}>
        <img src={img} alt="card-icon" />
        <div>{title}</div>
      </div>
    </div>
  );
}
