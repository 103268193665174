import React, { useState } from 'react';
import style from './Activity.module.css';
import { AVATAR_IMAGE } from '../../assets/image';
import TextEditor from 'shared/form/TextEditor/TextEditor';
import { Button } from 'react-bootstrap';
import Comment from './Comment';
import { useSocket } from '../../context/SocketContext';
import { useAuth } from 'contexts/AuthContext';

export default function CommentContainer({
  milestoneId,
  createdBy,
  comment,
  createdDate,
  id,
  projectBoardId,
  viewMode
}) {
  const socket = useSocket();
  const { user } = useAuth();
  const userId = user?._id;
  const [showEditOption, setShowEditOption] = useState(false);
  const [editComment, setEditComment] = useState(comment);

  const handleDeleteComment = () => {
    socket.emit('delete-activity', { id, milestoneId }, projectBoardId);
  };

  const handleEditComment = () => {
    socket.emit('update-activity', { id, message: editComment, milestoneId }, projectBoardId);
    setShowEditOption(false);
  };

  return (
    <div>
      {showEditOption ? (
        <div className={style.comment_wrapper}>
          <div className={style.comment_image}>
            <img src={createdBy.profilePic || AVATAR_IMAGE} alt="avatar" />
          </div>
          <div style={{width: "92%"}}>
            <TextEditor value={editComment || ''} onChange={(data) => setEditComment(data)} />

            <div className={style.button_group}>
              <Button variant="success" onClick={handleEditComment}>
                Save
              </Button>
              <Button variant="link" onClick={() => setShowEditOption(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Comment
          userName={createdBy?.fullName}
          createdDate={createdDate}
          comment={comment}
          profilePic={createdBy.profilePic}
          handleDelete={handleDeleteComment}
          handleEdit={() => setShowEditOption(true)}
          canEdit={createdBy?._id === userId && !viewMode}
        />
      )}
    </div>
  );
}
