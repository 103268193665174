import style from './style.module.css';

const HeadingWithIcon = ({ icon, heading, children }) => {
  return (
    <div className={style.heading_with_icon_wrapper}>
      <img src={icon} alt="hello-icon" className={style.heading_icon} />
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between">
          <div className={style.heading}>{heading}</div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default HeadingWithIcon;
