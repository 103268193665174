import React from "react";
import leftArrow from "../assets/img/left-arrow.svg";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import "../assets/css/terms-condition.css";

const TermsConditions = () => {
  const navigate = useNavigate();
  const HeaderContent = (
    <>
      <h2>Privacy Policy</h2>
      <div className="head-right">
        <a onClick={() => navigate("/")}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );
  return (
    <div className="wrapper create_project">
      <div className="main-content">
        <Header content={HeaderContent} />
        <div className="container">
        <div className="terms-condition">
          <p className="head-title">1. Introduction</p>
          <p className="text-content">Welcome to Happy Ending. Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://happyending.ai">(https://happyending.ai)</a> and use our services. By accessing our site, you agree to this Privacy Policy.</p> 
          <p className="head-title">2. Information We Collect</p>
          <p>We may collect personal information in various ways, including when you visit our site, register an account, place an order, or engage with site features. This may include:</p>
           <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Mailing address</li>
            <li>Phone number</li>
           </ul>
           <p className="text-content">Additionally, we may collect non-personal data such as browser type, IP address, and website usage statistics to enhance user experience.</p>
       
           <p className="head-title">3. How We Use Your Information</p>
          <p>We use your information to:</p>
           <ul>
            <li>Operate, maintain, and improve our website and services</li>
            <li>Personalize user experience and develop new features</li>
            <li>Analyze site usage trends and performance</li>
            <li>Communicate important updates, service notifications, and promotions (with your consent) </li>
            <li>Process payments securely</li>
            <li>Prevent fraudulent activities and ensure platform security</li>
           </ul>
           <p className="head-title">4. AI-Driven Data Processing</p>
           <p className="text-content">We leverage AI to analyze user interactions and improve our services. All AI-driven decisions are fair, transparent, and privacy-conscious. We do not use AI to make automated decisions that could negatively impact your rights.</p> 
           
           <p className="head-title">5. SMS Communications & Consent</p>
           <p>By providing your phone number and opting in, you consent to receive SMS messages related to your account, updates, and promotional offers.</p>
            <ul>
              <li>You can opt out anytime by replying STOP to any SMS message.</li>
              <li>Message frequency may vary. Standard carrier rates apply.</li>
              <li>Your SMS opt-in data will not be shared with third parties for marketing purposes.</li>
            </ul>
           <p className="head-title">6. Disclosure of Your Information</p>
           <p>We do not sell or rent your personal data. However, we may share it in the following cases:</p>
           <ul>
            <li>With service providers who assist in website operation, payment processing, or customer support</li>
            <li>To comply with legal obligations or protect rights and security</li>
            <li>With consent for promotional communications (opt-in required)</li>
           </ul>
           <p className="text-content">📌 Carve-out: No mobile information will be shared with third parties for marketing/promotional purposes. Text messaging opt-in data and consent will not be shared with any third parties.</p>
          
           <p className="head-title">7. Security of Your Information</p>
           <p className="text-content">We implement industry-standard security measures to protect your data. However, no system is completely secure. Please use strong passwords and safeguard your login credentials</p>
           <p className="head-title">8. Changes to This Privacy Policy</p>
           <p className="text-content">We may update this Privacy Policy periodically. Any changes will be posted on this page, and we encourage you to review it regularly.</p>
           <p className="head-title">9. Contact Us</p>
           <p className="text-content">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
           <a href="mailto:support@happyending.ai">📧 support@happyending.ai</a>
      

        </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
