import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Stack } from 'react-bootstrap';
import { useSocket } from '../../context/SocketContext';
import { DROPDOWN_ARROW, STAND_ICON } from '../../assets/image';
import Chip from 'shared/UI/Chip';
import Dropdown from 'shared/UI/Dropdown';
import InviteMemberButton from 'pages/projects/kanban/shared/InviteMember/InviteMemberButton';
import EditableText from 'pages/projects/kanban/shared/EditableText';
import style from './MilestoneSetup.module.css';
import boardToast, { TOAST_TYPE } from '../../utils/boardToast';
import AvatarGroup from '../../shared/AvatarGroup';

export default function MileStoneSetup({ milestone, priorityOptions, members, viewMode, projectBoardId }) {
  const socket = useSocket();
  const [dueDate, setDueDate] = useState(milestone?.dueDate && new Date(milestone?.dueDate));
  const [priority, setPriority] = useState(milestone?.priority?._id);
  const [name, setName] = useState(milestone?.name);

  // Update state when milestone changes
  useEffect(() => {
    if (milestone) {
      setDueDate(milestone.dueDate ? new Date(milestone.dueDate) : null);
      setPriority(milestone.priority?._id || null);
      setName(milestone.name || '');
    }
  }, [milestone]);

  const handleChange = (value) => {
    setPriority(value);
    socket.emit('update-milestone', { projectBoardId, id: milestone._id, payload: { priority: value?.value } });
  };
  const handleDueDate = (date) => {
    socket.emit('update-milestone', { projectBoardId, id: milestone._id, payload: { dueDate: date } });
    setDueDate(date);
  };

  const handleUpdateMilestone = (data) => {
    socket.emit('update-milestone', { projectBoardId, id: milestone._id, payload: { name: data } });
  };

  const handleMember = (data) => {
    const ids = milestone.assigneeIds.map((value) => value?._id || value);
    socket.emit('update-milestone', {
      projectBoardId,
      id: milestone._id,
      payload: { assigneeIds: [...ids, data._id] },
      assignMemberType: 'invited',
      memberName: data.fullName
    });
    boardToast('Member assign successfully', TOAST_TYPE.SUCCESS);
  };

  const handleRemove = (userId) => {
    const updatedUserIds = milestone.assigneeIds
      .map((value) => value?._id || value)
      .filter((value) => value !== userId);
    const userName = milestone.assigneeIds.find((value) => value?._id === userId)?.fullName;
    socket.emit('update-milestone', {
      projectBoardId,
      id: milestone._id,
      payload: { assigneeIds: updatedUserIds },
      assignMemberType: 'removed',
      memberName: userName
    });
    boardToast('Member removed successfully', TOAST_TYPE.SUCCESS);
  };
  const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
    <div className={className} onClick={onClick} ref={ref}>
      {value ? value : 'Select Date'}
      <img src={DROPDOWN_ARROW} alt="arrow icon" className="icon-small ml-2" />
    </div>
  ));

  return (
    <div>
      <div className={style.heading_with_icon_wrapper}>
        <img src={STAND_ICON} alt="hello-icon" className={style.heading_icon} />
        <div className="w-100 mr-2">
          <EditableText
            handleSubmitTitle={(data) => {
              handleUpdateMilestone(data);
              setName(data);
            }}
            title={name}
            hideEditButton={viewMode}
          />
          <div className={style.description}>
            <div>In List</div>
            <div>
              <Chip label={milestone?.status?.name} />
            </div>
          </div>
        </div>
      </div>
      <div className={style.milestone_setup_wrapper}>
        <div className={style.milestone_setup_content}>
          <div>
            <div className="subtitle1 mb-2">Member</div>
            <Stack direction="horizontal" gap={2}>
              <div className="d-flex">
                <AvatarGroup members={milestone.assigneeIds} limit={2} size={30} />
              </div>

              {!viewMode && (
                <InviteMemberButton
                  assignees={milestone.assigneeIds}
                  members={members}
                  handleMember={handleMember}
                  handleRemove={handleRemove}
                />
              )}
            </Stack>
          </div>
          <div>
            <div className="subtitle1 mb-2">Priority</div>
            <Dropdown value={priority} options={priorityOptions} onChange={handleChange} disabled={viewMode} />
          </div>
          <div>
            <div className="subtitle1 mb-2">Due Date</div>
            <div>
              <div className={style.dropdown_date_picker_wrapper}>
                <DatePicker
                  selected={dueDate}
                  onChange={handleDueDate}
                  disabled={viewMode}
                  className="dropdown-date-picker"
                  placeholderText="Select Date"
                  showTimeSelect
                  dateFormat="MMM d, h:mm aa"
                  minDate={new Date()}
                  customInput={<ExampleCustomInput className="example-custom-input" />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
