import React, { useEffect, useRef } from 'react';
import style from './Input.module.css';

export default function Input({ label, type = 'text', helperText, placeholder, error, shouldFocus = false, ...rest }) {
  const inputRef = useRef(null);
  // Focus the input field when the component is mounted
  useEffect(() => {
    if (inputRef.current && shouldFocus) {
      inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    if (shouldFocus) {
      console.log('add focus');
    }
  }, []);
  return (
    <div className={style.input_group}>
      {label && <label for={label}>{label}</label>}
      <input
        ref={inputRef}
        type={type}
        className={style.input_control}
        id={label}
        aria-describedby={label}
        placeholder={placeholder}
        {...rest}
        style={error ? { border: '1px solid red' } : {}}
      />
      {helperText && (
        <small id={label} style={error ? { color: 'red' } : {}} className="form-text">
          {helperText}
        </small>
      )}
    </div>
  );
}
