import backIcon from 'assets/img/left-arrow.svg';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from 'shared/UI/Dropdown';

const ProjectBoardHeader = ({ projectOptions }) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [value, setValue] = useState(projectId);
  const handleBackClick = () => {
    navigate(-1); // This goes back to the previous page
  };

  const handleChange = (data) => {
    const pId = data?.value;
    setValue(pId);
    navigate(`/project-board/${pId}`);
  };
  return (
    <>
      {projectOptions?.length > 0 && (
        <Dropdown value={value} options={projectOptions} onChange={handleChange} width="250px" />
      )}
      <div className="head-right">
        <img src={backIcon} alt="back-icon" style={{ cursor: 'pointer' }} onClick={handleBackClick} />
      </div>
    </>
  );
};

export default ProjectBoardHeader;
