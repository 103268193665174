import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { PLUS_ICON } from '../../assets/image';
import InviteMember from './InviteMember';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button className="btn btn-light icon-btn-round" ref={ref} onClick={onClick}>
    <img src={PLUS_ICON} alt="plus-icon" />
    {children}
  </button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(({ style, className, 'aria-labelledby': labeledBy, ...rest }, ref) => {
  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <InviteMember {...rest} />
    </div>
  );
});

const InviteMemberButton = ({ ...rest }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components"></Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        style={{ width: '350px', border: 'none', marginTop: '10px' }}
        {...rest}></Dropdown.Menu>
    </Dropdown>
  );
};

export default InviteMemberButton;
