import React from "react";
import SignUpHeader from "./SignUpHeader";
import video from "../../../assets/img/video.jpg";
import play from "../../../assets/img/play.svg";
const Video = () => {
  return (
    <div className="wrapper welcome-page">
      <div className="main-content">
        <SignUpHeader />
        <div className="feature-sec">
          <div className="container">
            <p>Understanding the Happy Ending Hierarchy</p>
            <div className="row">
              <div className="col-lg-12">
                <div className="video-content">
                  <div class="video-container">
                    <img src={video} alt={video} className="video" />
                    <div className="play-icon">
                    <img src={play} alt={play} className="play" />
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="wetch-later">
            <a href="#" className="choose-later">
                I’ll Watch Later
            </a>
            <a href="#" className="choose-later">
           -
            </a>
            <a href="#" className="choose-later">
            Close
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
