import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/overview-updated.css';
import '../../assets/css/overview-requests.css';
import '../../assets/css/add-updated-modal.css';
import '../../assets/css/sign-off-request-modal.css';
import '../../assets/css/change-order-modal.css';
import '../../assets/css/new-additional-modal.css';
import leftArrow from '../../assets/img/left-arrow.svg';
import settingIcon from '../../assets/img/settings.svg';
import notificationIcon from '../../assets/img/notification.svg';
import bellIcon from '../../assets/img/bell.svg';
import arrowDown from '../../assets/img/arrow-down.svg';
import timeIcon from '../../assets/img/time-icon.svg';
import KanbanIcon from '../../assets/img/kanban.svg';
import whiteArro from '../../assets/img/white-arrow-down.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { Dropdown, ProgressBar } from 'react-bootstrap';
import UpdatedScope from './updatedoverviewtabs/UpdatedScope';
import APIServices from '../../services/APIServices';
import { calculateDateDifference, formatDate, hasPermission, selectLoader } from '../../utils/Helpers';
import ActivityTab from './updatedoverviewtabs/ActivityTab';
import RequestTab from './updatedoverviewtabs/RequestTab';
import appToast, { TOAST_TYPE } from '../../utils/AppToast';
import { useStore } from '../../hooks/useStore';
import { useAuth } from '../../contexts/AuthContext';
import FeedbackTab from './updatedoverviewtabs/FeedbackTab';
import barcode1 from '../../assets/img/barcode1-red.svg';
import barcode2 from '../../assets/img/barcode2-red.svg';
import barcode3 from '../../assets/img/barcode1-blue.svg';
import barcode4 from '../../assets/img/barcode2-blue.svg';
import editeIcon from '../../assets/img/edit-03.svg';
import SidebarModal from '../../components/SidebarModal';
import Notifications from '../../components/Notifications';
import SignOffForm from './forms/SignOffForm';
import ProjectProgress from '../../components/ProjectProgress';
import ConfirmModal from '../../components/ConfirmModal';

const UpdatedOverview = () => {
  const { isUser1, user, userRole, socket } = useAuth();
  const projectIdRef = useRef('');
  const navigate = useNavigate();
  const location = useLocation();
  const [store, setStore] = useStore();
  const { notificationId } = useParams();
  const projectParamsId = location.state?.projectId || '';
  const [projectId, setProjectId] = useState('');
  const [scopeData, setScopeData] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(userRole);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [projectStatus, setProjectStatus] = useState('ontime');
  const [updateDetails, setUpdateDetails] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [memberPermission, setMemberPermission] = useState('Edit');
  const [activeTabView, setActiveView] = useState('');
  const [projectData, setProjectData] = useState(null);
  const [milestones, setMilestones] = useState([]);
  // const [statusLoader, setStatusLoader] = useState(false); // State to track loading
  const [pageLoading, setPageLoading] = useState(true); // State to track loading
  const [loading, setLoading] = useState(true); // State to track loading
  const [projectLoading, setProjectLoading] = useState(false); // State to track loading
  const [milestoneStatusOptions, setMilestoneStatusOptions] = useState([]); // State to track loading
  const [loadingMilestoneId, setLoadingMilestoneId] = useState(null);
  const [changeOrders, setChangeOrders] = useState([]);
  const [activeQuotes, setActiveQuotes] = useState([]);
  const [changeRequests, setChangeRequests] = useState([]);
  const [selectedReqId, setSelectedReqId] = useState('');
  const [isUnread, setUnread] = useState(false);
  const [isClosedProject, setClosedProject] = useState(false);
  const [isInternalProject, setInternalProject] = useState(false);
  const [isClosingProject, setClosingProject] = useState(false);
  const [closedMilestoneId, setClosedMilestoneId] = useState('');
  const [modals, setModals] = useState({
    notifications: false,
    signOffModal: false,
    closeConfirmModal: false
  });

  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen
    }));
  };

  useEffect(() => {
    console.log('component rendered');
    console.log(projectParamsId, 'projectParamsId');

    if (projectParamsId) {
      setProjectId(projectParamsId);
      projectIdRef.current = projectParamsId; // Keep ref updated
    }
  }, [projectParamsId]);

  useEffect(() => {
    if (notificationId) {
      console.log(notificationId);
      fetchNotificationDetails();
    }
  }, [notificationId]);

  // Fetch the scope details from the API
  const fetchNotificationDetails = async () => {
    try {
      const response = await APIServices.get(`/notification/${notificationId}`);
      if (response) {
        console.log(response.data);
        let notificationDetails = response.notificationTypeId;
        if (response.data.notificationType && response.data.notificationType.includes('quote')) {
          setSelectedReqId(response.data?.notificationTypeId?.projectRequestId?._id);
        } else {
          setSelectedReqId(response.data?.notificationTypeId?._id || '');
        }

        if (
          response.data?.notificationType?.includes('requested') ||
          response.data?.notificationType?.includes('given')
        ) {
          setCurrentTab(4);
        } else if (
          !notificationDetails?.requestType?.includes('activity') &&
          !response.data?.notificationType?.includes('update_made') &&
          !response.data?.notificationType?.includes('project_created') &&
          !response.data?.notificationType?.includes('activity') &&
          !response.data?.notificationType?.includes('mentioned_message') &&
          !response.data?.notificationType?.includes('milestone_started')
        ) {
          setCurrentTab(2);
        } else {
          setCurrentTab(1);
        }
        // fetchProjectData(response.projectId);
        console.log(response.data);

        setProjectId(response.data.projectId);
        setNotificationDetails(response.data); // Set the scope data in state
      }
    } catch (error) {
      console.error('Error fetching notification details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the scope details from the API
  const fetchScopeDetails = async (projectId) => {
    try {
      const response = await APIServices.get(`/project-scope?projectId=${projectId}`);
      if (response.scope) {
        setScopeData(response); // Set the scope data in state
      }
    } catch (error) {
      console.error('Error fetching scope details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetching project data from the backend
  const fetchProjectData = async (projectId) => {
    // if(!projectId){
    //   return;
    // }
    setProjectLoading(true);
    try {
      // Using axios or fetch to get data from your API
      const response = await APIServices.get(`/project/${projectId}`); // API call with project ID
      // console.log(response);
      if (response.projectDetail) {
        setProjectData(response.projectDetail); // Set the response data in state
        setMilestones(response.milestones);
        setChangeOrders(response.changeOrderRequests);
        setActiveQuotes(response.activeQuotes);
        setChangeRequests(response.changeRequests);
        if (response?.projectTeam?.myTeam) {
          let userPermission;
          if(isUser1){
            userPermission = response.projectTeam.myTeam.find(
              (member) => member.userId._id === user._id
            );
          }else{
            userPermission = response.projectTeam.organizationTeam.find(
              (member) => member.userId._id === user._id
            );
          }
          if(userPermission){
            if(userPermission?.permission){
              setMemberPermission(userPermission.permission.name);
            }
            setCurrentUserRole(userPermission.role.role)
            if(response?.projectDetail?.type === "internal"){
              setInternalProject(true);
            }
            console.log(userPermission);
          }
        }
        if (response.projectDetail?.latestUpdate[0]?.updateStatus?.value) {
          setProjectStatus(response.projectDetail?.latestUpdate[0]?.updateStatus?.value);
        }
        if (response.projectDetail.projectStatus === 'closed') {
          setClosedProject(true);
        }
      }
      setProjectLoading(false);
    } catch (error) {
      console.error('Error fetching project data:', error);
    } finally {
      setProjectLoading(false);
      setPageLoading(false); // Stop loading even if there is an error
    }
  };

  const fetchmileStoneStatus = async () => {
    try {
      const response = await APIServices.get(`/enum?item_type=milestonstatus`); // Adjust the URL as necessary
      if (response.length > 0) {
        setMilestoneStatusOptions(response);
      }
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };

  const handleBackClick = () => {
    navigate('/'); // This goes back to the previous page
  };

  const handleRequestDetails = (reqId, tabIndex) => {
    setStore({ selectedReqId: reqId });
    setCurrentTab(tabIndex);
    setSelectedReqId(reqId);
  };

  const updateandCloseModal = (modalType) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: false
    }));
    // fetchActivities();
    setUpdateDetails((prev) => !prev);
  };

  useEffect(() => {
    if (projectId && user) {
      fetchProjectData(projectId);
      fetchScopeDetails(projectId);
      fetchmileStoneStatus(projectId);
      setStore({ projectId: projectId });
    }
    // else{
    //   navigate("/");
    // }
  }, [projectId, updateDetails, user]);

  const updateMilestoneStatus = async (milestoneId, updateType = 'inprogress') => {
    setLoadingMilestoneId(milestoneId); // Start loader for the milestone
    let currentStatusId = milestoneStatusOptions?.find((x) => x.value == updateType);
    console.log(currentStatusId._id);
    if (currentStatusId._id) {
      let payload = {
        statusId: currentStatusId?._id
      };
      try {
        const response = await APIServices.put(`/project-milestone/${milestoneId}/status`, payload);

        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          fetchProjectData(projectId);
        } else if (response.data.milestoneId) {
          setClosedMilestoneId(milestoneId);
          appToast(response.data.message, TOAST_TYPE.WARNING);
          toggleModal('signOffModal', true);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (error) {
        console.error('Error updating team status:', error);
        appToast('Failed to update team status', TOAST_TYPE.ERROR);
      } finally {
        setLoadingMilestoneId(null);
      }
    }
  };
  const handleKanbanIconClick = () => navigate(`/project-board/${projectId}`); // This goes back to the previous page
  const handleCloseProject = async () => {
    setClosingProject(true);
    try {
      let payload = {
        projectStatus: 'closed'
      };
      const response = await APIServices.put(`/project/${projectId}/status`, payload);
      if (response.message) {
        setClosedProject(true);
        appToast(response.message, TOAST_TYPE.SUCCESS);
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.SUCCESS);
      }
    } catch (error) {
      // Handle errors
      appToast(error, TOAST_TYPE.ERROR);
    } finally {
      toggleModal('closeConfirmModal', false);
      setClosingProject(false);
    }
  };
  const handleEditProject = (projectId) => {
    navigate(`/update-project/${projectId}`);
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on('Notification', (Notification) => {
        // scrollToBottom();
        setUnread(true);
        console.log('Notification', Notification);
      });
      socket.on('ProjectRequestQuote', (activity) => {
        // if (!projectLoading) {
          fetchProjectData(projectIdRef.current);
        // }
        fetchScopeDetails(projectIdRef.current);
        console.log('ProjectRequestQuote', activity);
      });
      socket.on('ProjectUpdate', (activity) => {
        // if (!projectLoading) {
          fetchProjectData(projectIdRef.current);
        // }
        console.log('ProjectUpdate', activity);
      });
      socket.on('ProjectRequest', (activity) => {
        if (!projectLoading) {
          fetchProjectData(projectIdRef.current);
        }
        console.log('ProjectRequest', activity);
      });
      socket.on('MilestoneUpdated', (activity) => {
        // if (!projectLoading) {
          fetchProjectData(projectIdRef.current);
        // }
        // fetchProjectData(projectId);
        console.log('MilestoneUpdated', activity);
      });
    }
  }, [socket]);

  const closeConfirmContent = (
    <div>
      <h2 className="mb-3">Are you sure?</h2>
      <h3>You want to close this project? </h3>
    </div>
  );

  const HeaderContent = (
    <>
      <h2>Overview</h2>
      <div className="head-right">
        {isUser1 && hasPermission(currentUserRole, 'update_projects') && <div onClick={handleKanbanIconClick}>
          <img src={KanbanIcon} alt="kanban" style={{ cursor: 'pointer' }} />
        </div>}
        {/* <a>
          <img
            src={settingIcon}
            alt="menu"
            className="feather-img menuicon"
            onClick={() => {
              navigate("/settings");
            }} // Sidebar toggle handler
          />
        </a> */}
        <Dropdown className="notify-drp">
          <Dropdown.Toggle
            as="a"
            className="dropdown-toggle"
            onClick={() => {
              toggleModal('notifications', true);
              setUnread(false);
            }}>
            {isUnread ? (
              <img src={notificationIcon} alt="comp1" className="notification-img" />
            ) : (
              <img src={bellIcon} alt="comp1" className="notification-img" />
            )}
          </Dropdown.Toggle>
        </Dropdown>
        <a onClick={handleBackClick}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  if (pageLoading) {
    return <div className="nodata-loader">{selectLoader(70)}</div>;
  }

  return (
    <div className="wrapper">
      <div className="main-content overview_updated">
        <Header content={HeaderContent} />
        <section className="overview-sec">
          <div className="container">
            <div className="row align-items-start">
              <div className="col-lg-4 col-md-12">
                <div className="left-sec-content">
                  <div className="card-box">
                    <div className="inner-card-box">
                      <div className="title-content">
                        <h3>{projectData?.projectName}</h3>
                        {hasPermission(currentUserRole, 'update_projects') &&
                          !isClosedProject &&
                          memberPermission === 'Edit' && (
                            <img
                              src={editeIcon}
                              onClick={() => handleEditProject(projectData._id)}
                              className="editeIcon-img cursor_pointer"
                            />
                          )}
                      </div>
                      <div className="org-text">{isUser1 ? (projectData?.organizationId?.name || projectData?.createdBy?.companyId?.name) : projectData?.createdBy?.companyId?.name}</div>
                      <div className="time-details">
                        {projectStatus == 'late' ? (
                          <h3 className="progress-delaytext">#Late</h3>
                        ) : projectStatus == 'early' ? (
                          <h3>#Early</h3>
                        ) : (
                          <h3>#On-time</h3>
                        )}
                        {hasPermission(currentUserRole, 'update_projects', memberPermission) && !isClosedProject ? (
                          isClosingProject ? (
                            selectLoader(20)
                          ) : (
                            <p
                              className="close-text cursor_pointer"
                              onClick={() => toggleModal('closeConfirmModal', true)}>
                              Close
                            </p>
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="card-bottom">
                      <div className="progress-update">
                        <ProjectProgress project={projectData} />
                      </div>
                      {projectData?.latestUpdate[0]?.createdBy ? (
                        <h5>
                          Modified by {projectData?.latestUpdate[0]?.createdBy?.fullName},{' '}
                          {formatDate(projectData?.createdBy?.updatedAt || new Date())}
                        </h5>
                      ) : (
                        <h5>
                          Created by {projectData?.createdBy?.fullName},{' '}
                          {formatDate(projectData.startDate || new Date())}
                        </h5>
                      )}
                    </div>
                  </div>

                  {/* Running Milestones */}
                  {(milestones?.running?.length > 0 ||
                    changeOrders?.length > 0 ||
                    changeRequests?.length > 0 ||
                    activeQuotes?.length > 0) && (
                    <div className="cardBox-content-item">
                      <div className="milestone-edit">
                        <h4>Running Milestones</h4>
                      </div>
                      {changeRequests?.length > 0 &&
                        // console.log(milestones?.changeOrderRequests)

                        changeRequests.map((req, index) => (
                          <button
                            className={`change-order-btn ${
                              calculateDateDifference(req.expireInDate).includes('Overdue') ? 'overdue' : ''
                            }`}
                            key={index}
                            onClick={() => {
                              handleRequestDetails(req._id, 2);
                            }}>
                            {' '}
                            <span>Request: {req.title}</span>
                            {req.changeRequestDeadlineDate && (
                              <div className="timer-text">
                                <img src={timeIcon} alt="comp1" className="time-icon" />{' '}
                                {calculateDateDifference(req.changeRequestDeadlineDate, true)} {/* days */}
                                <img src={whiteArro} alt="comp1" className="right-arrow" />
                              </div>
                            )}
                            {/* <img
                              src={whiteArro}
                              alt="comp1"
                              className="right-arrow"
                            /> */}
                          </button>
                        ))}
                      {activeQuotes?.length > 0 &&
                        // console.log(milestones?.changeOrderRequests)

                        activeQuotes.map((qoute, index) => (
                          <button
                            className={`change-order-btn ${
                              calculateDateDifference(qoute.expireInDate).includes('Overdue') ? 'overdue' : ''
                            }`}
                            key={index}
                            onClick={() => {
                              handleRequestDetails(qoute.projectRequestId || qoute._id, 2);
                            }}>
                            {' '}
                            <span>Quote Given </span>
                            {qoute.expireInDate && (
                              <div className="timer-text">
                                <img src={timeIcon} alt="comp1" className="time-icon" />{' '}
                                {calculateDateDifference(qoute.expireInDate, true)} {/* days */}
                                <img src={whiteArro} alt="comp1" className="right-arrow" />
                              </div>
                            )}
                            {/* <img
                              src={whiteArro}
                              alt="comp1"
                              className="right-arrow"
                            /> */}
                          </button>
                        ))}
                      {changeOrders?.length > 0 &&
                        // console.log(milestones?.changeOrderRequests)

                        changeOrders.map((order, index) => (
                          <button
                            className="change-order-btn"
                            key={index}
                            onClick={() => {
                              handleRequestDetails(order.parentRequestId || order._id, 2);
                            }}>
                            {' '}
                            <span>Change Order: {order.title}</span>
                            <img src={whiteArro} alt="comp1" className="right-arrow" />
                          </button>
                        ))}
                      {milestones?.running.map((milestone) => (
                        <div key={milestone._id}>
                          <div className="design-item">
                            <p className="running-list-p">{milestone.name}</p>
                            <span>&nbsp;</span>

                            {/* <small>{formatDate(milestone.startDate)}</small> */}
                            <small>
                              {calculateDateDifference(milestone.dueDate)}
                              {/* {milestone.daysToComplete}{" "} */}
                              {/* {milestone.daysToComplete > 1 ? " days" : " day"} */}
                            </small>
                            {/* <span>&nbsp;</span> */}
                            {isUser1 && !isClosedProject &&
                              (loadingMilestoneId === milestone._id ? (
                                selectLoader(20)
                              ) : (
                                <small
                                  className="cursor_pointer ml-3   update-status"
                                  onClick={() => {
                                    updateMilestoneStatus(milestone._id, 'complete');
                                  }}>
                                  End
                                </small>
                              ))}
                          </div>

                          {/* <div className="design-progress-item">
                            <ProgressBar now={milestone.percentage} />
                            <span className="percentage">
                              {milestone.percentage}%
                            </span>
                          </div> */}
                          {milestone.signoffRequest && (
                            <button
                              onClick={() => {
                                handleRequestDetails(milestone.signoffRequest._id, 2);
                              }}
                              className={`signoff-request-btn ${
                                calculateDateDifference(milestone.signoffDeadline).includes('Overdue') &&
                                milestone.signOffTime !== 0
                                  ? 'overdue'
                                  : ''
                              }`}>
                              Sign-off{' '}
                              {hasPermission(currentUserRole, 'send_signoff_requests')
                                ? calculateDateDifference(milestone.signoffDeadline).includes('Overdue') &&
                                  milestone.signOffTime !== 0
                                  ? ' Delayed'
                                  : 'Request Sent'
                                : 'Request Received'}
                              {milestone.signOffTime !== 0 && (
                                <div className="timer-text">
                                  <img src={timeIcon} alt="comp1" className="time-icon" />{' '}
                                  {calculateDateDifference(milestone.signoffDeadline)?.replace('Overdue', '')}{' '}
                                  {/* days */}
                                  <img src={whiteArro} alt="comp1" className="right-arrow" />
                                </div>
                              )}
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Upcoming Milestones */}
                  {milestones?.upcoming?.length > 0 && (
                    <div className="cardBox-content-item">
                      <div className="milestone-edit">
                        <h4>Upcoming Milestones</h4>
                      </div>

                      <div className="skill-rols-list-cover">
                        {milestones?.upcoming.map((milestone) => (
                          <div key={milestone._id} className="skill-rols-list">
                            <p>{milestone.name}</p>
                            {isUser1 && !isClosedProject &&
                              hasPermission(currentUserRole, 'edit_milestones') &&
                              (loadingMilestoneId === milestone._id ? (
                                selectLoader(20)
                              ) : (
                                <small
                                  className="cursor_pointer update-status"
                                  onClick={() => {
                                    updateMilestoneStatus(milestone._id);
                                  }}>
                                  Start
                                </small>
                              ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Completed milestones */}
                  {milestones?.completed?.length > 0 && (
                    <div className="cardBox-content-item">
                      <div className="milestone-edit">
                        <h4>Completed Milestones</h4>
                      </div>
                      <div className="skill-rols-list-cover">
                        {milestones?.completed?.map((milestone) => (
                          <>
                            <div className="skill-rols-list pb-1" key={milestone._id}>
                              <p>{milestone.name}</p>
                              <small className="date-text">
                                Completed {formatDate(milestone.signoffDate || milestone.endDate || new Date(), true)}
                              </small>
                            </div>
                            {milestone?.signoffApprovedDate && (
                              <div className="skill-rols-list-subtext">
                                <p>
                                  {/* Sign-off{" "} */}
                                  Signed{' '}
                                  <small>on {formatDate(milestone.signoffApprovedDate || new Date(), true)}</small>
                                </p>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="right-sec-content">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${currentTab == 1 ? 'active' : ''}`}
                        id="pills-updated-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-updated"
                        type="button"
                        role="tab"
                        aria-controls="pills-updated"
                        aria-selected="false"
                        onClick={() => {
                          setCurrentTab(1);
                        }}>
                        Activity
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${currentTab == 2 ? 'active' : ''}`}
                        id="pills-requests-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-requests"
                        type="button"
                        role="tab"
                        aria-controls="pills-requests"
                        aria-selected="true"
                        onClick={() => {
                          setCurrentTab(2);
                          setActiveView('requestList');
                          setSelectedReqId('');
                        }}>
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22.999"
                          height="19.532"
                          viewBox="0 0 22.999 19.532"
                        >
                          <g
                            id="Group_599"
                            data-name="Group 599"
                            transform="translate(4 2)"
                          >
                            <path
                              id="Icon_awesome-exchange-alt"
                              data-name="Icon awesome-exchange-alt"
                              d="M0,7.874V7.312a.844.844,0,0,1,.844-.844H13.5V4.78a.844.844,0,0,1,1.44-.6L17.753,7a.844.844,0,0,1,0,1.193L14.94,11a.844.844,0,0,1-1.44-.6V8.718H.844A.844.844,0,0,1,0,7.874Zm17.156,5.344H4.5V11.53a.844.844,0,0,0-1.44-.6L.247,13.746a.844.844,0,0,0,0,1.193L3.06,17.752a.844.844,0,0,0,1.44-.6V15.468H17.156A.844.844,0,0,0,18,14.624v-.562A.844.844,0,0,0,17.156,13.218Z"
                              transform="translate(0.999 -0.468)"
                              fill="#8f8f8f"
                            />
                            <g
                              id="Ellipse_84"
                              data-name="Ellipse 84"
                              transform="translate(-4 -2)"
                              fill="red"
                              stroke="#fff"
                              strokeWidth="0.5"
                            >
                              <circle cx="5" cy="5" r="5" stroke="none" />
                              <circle cx="5" cy="5" r="4.75" fill="none" />
                            </g>
                          </g>
                        </svg> */}
                        Request
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${currentTab == 3 ? 'active' : ''}`}
                        id="pills-work-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-work"
                        type="button"
                        role="tab"
                        aria-controls="pills-work"
                        aria-selected="true"
                        onClick={() => {
                          setCurrentTab(3);
                        }}>
                        Scope of Work
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${currentTab == 4 ? 'active' : ''}`}
                        id="pills-feedback-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-feedback"
                        type="button"
                        role="tab"
                        aria-controls="pills-feedback"
                        aria-selected="true"
                        onClick={() => {
                          setCurrentTab(4);
                          setSelectedReqId('');
                        }}>
                        Feedback
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className={`tab-pane fade ${currentTab == 1 ? 'show active' : ''}`}
                      id="pills-updated"
                      role="tabpanel"
                      aria-labelledby="pills-updated-tab">
                      <ActivityTab
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}
                        updateProjectDetails={() => fetchProjectData(projectId)}
                        projectId={projectId}
                        setSelectedReqId={setSelectedReqId}
                        memberPermission={memberPermission}
                        userRole={currentUserRole}
                        isInternalProject={isInternalProject}
                      />
                    </div>
                    <div
                      className={`tab-pane fade ${currentTab == 2 ? 'show active' : ''}`}
                      id="pills-requests"
                      role="tabpanel"
                      aria-labelledby="pills-requests-tab">
                      <RequestTab
                        projectId={projectId}
                        currentTab={currentTab}
                        selectedReqId={selectedReqId}
                        setSelectedReqId={setSelectedReqId}
                        activeTabView={activeTabView}
                        setActiveView={setActiveView}
                        setCurrentTab={setCurrentTab}
                        setUpdateDetails={() => {
                          setUpdateDetails((prev) => !prev);
                        }}
                        memberPermission={memberPermission}
                        userRole={currentUserRole}
                        isInternalProject={isInternalProject}
                      />
                    </div>
                    <div
                      className={`tab-pane fade ${currentTab == 3 ? 'show active' : ''}`}
                      id="pills-work"
                      role="tabpanel"
                      aria-labelledby="pills-work-tab">
                      <div className="card-body-content pills-card-body-content">
                        <UpdatedScope
                          scopeData={scopeData}
                          // projectDetails={projectDetails}
                          loading={loading}
                        />
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${currentTab == 4 ? 'show active' : ''}`}
                      id="pills-feedback"
                      role="tabpanel"
                      aria-labelledby="pills-feedback-tab">
                      {/* <div className="text-center mt-3 milestones-comming-soon">
                        <p>Coming soon...</p>
                      </div> */}
                      <FeedbackTab
                        selectedReqId={selectedReqId}
                        projectId={projectId}
                        currentTab={currentTab}
                        activeTabView={activeTabView}
                        setActiveView={setActiveView}
                        memberPermission={memberPermission}
                        userRole={currentUserRole}
                        isInternalProject={isInternalProject}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Modal Component */}
        <SidebarModal
          extraClass={'edit-profile-modal notification-modal'}
          show={modals.notifications}
          onHide={() => toggleModal('notifications', false)}
          headerTitle={'Happy Ending Project'}>
          <Notifications closeModal={() => toggleModal('notifications', false)} />
        </SidebarModal>

        <SidebarModal
          extraClass={'edit-profile-modal'}
          show={modals.signOffModal}
          onHide={() => {
            toggleModal('signOffModal', false);
            setClosedMilestoneId('');
          }}
          headerTitle={'Sign-off Request'}>
          <SignOffForm
            projectId={projectId}
            updatedMilestoneId={closedMilestoneId}
            closeModal={() => {
              updateandCloseModal('signOffModal');
              setClosedMilestoneId('');
            }}
          />
        </SidebarModal>
        <ConfirmModal
          show={modals.closeConfirmModal}
          onHide={() => {
            toggleModal('closeConfirmModal', false);
          }}
          confirmationText={closeConfirmContent}
          handleAction={handleCloseProject} // Pass the deletion logic here
          loading={isClosingProject}
          closeText="Yes"
        />
      </div>
    </div>
  );
};

export default UpdatedOverview;
