import React, { useEffect, useMemo, useState } from 'react';
import { Button, CloseButton, Overlay, Stack } from 'react-bootstrap';
import { AVATAR_IMAGE, CHEVRON_DOWN_ICON, CHEVRON_RIGHT_ICON, LINK_ICON } from '../../assets/image';
import { debounce } from 'lodash';
import { useAuth } from 'contexts/AuthContext';
import { validateEmail } from 'pages/projects/kanban/utils/general';
import boardToast, { TOAST_TYPE } from 'pages/projects/kanban/utils/boardToast';
import style from './InviteMember.module.css';
import Axios from '../../utils/Axios';

const filters = [
  { value: 'ALL', label: 'All' },
  { value: 'Edit', label: 'Editors' },
  { value: 'View', label: 'Viewers' }
];

const editOptions = [
  { value: 'Edit', label: 'Can Edit' },
  { value: 'View', label: 'Can View' }
];

export default function InviteMemberPopup({
  members,
  title = 'Invite Member',
  subtitle = 'Share your project collaborate with your team',
  inviteTeam,
  target,
  overlayRef,
  show,
  onClose,
  projectBoardId,
  projectBoardCreatedBy,
  viewMode,
  handleRemoveInvitee,
  hideInvitee
}) {
  const { user } = useAuth();
  const userId = user._id;
  const [searchValue, setSearchValue] = useState('');
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [filterValue, setFilterValue] = useState('ALL');
  const [filteredTeam, setFilteredTeam] = useState(members);
  const [noOptions, setNoOptions] = useState(false);
  // Debounced version of fetchUsers
  const fetchUsersDebounced = useMemo(
    () =>
      debounce(async (value) => {
        if (value?.length > 2) {
          try {
            const response = await Axios.get(`/user/autocomplete?query=${value}`);
            const excludeId = new Set();
            members?.forEach((value) => excludeId.add(value._id));
            const filterMembers = response.filter((value) => !excludeId.has(value._id));
            setAutocompleteResults(filterMembers);
            if (!filterMembers || filterMembers?.length === 0) {
              if (validateEmail(value)) {
                setAutocompleteResults([{ email: value }]);
                setNoOptions(false);
              } else {
                setNoOptions(true);
              }
            } else {
              setNoOptions(false);
            }
          } catch {
            setAutocompleteResults([]);
            setNoOptions(true);
          }
        } else {
          setAutocompleteResults([]);
        }
      }, 300), // Adjust debounce delay as needed (300ms here)
    [members]
  );

  useEffect(() => {
    if (members?.length > 0) {
      setFilteredTeam(members);
    }
  }, [members]);

  const handleSearchChange = (search) => {
    setSearchValue(search);
    fetchUsersDebounced(search);
    if (!search) setNoOptions(false);
  };

  const handleInviteTeam = async (data) => {
    setAutocompleteResults([]);
    setSearchValue('');
    inviteTeam(data);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(document.location?.origin + `/#/login?projectBoardId=${projectBoardId}`);
    boardToast('Copy successfully!', TOAST_TYPE.SUCCESS);
  };

  const handleFilter = (value) => {
    if (value === 'ALL') {
      setFilteredTeam(members);
    } else {
      setFilteredTeam(members.filter((member) => member.permission === value));
    }
    setFilterValue(value);
  };

  const handleChangePermission = async (userId, permission) => {
    setFilteredTeam((prevState) =>
      prevState.map((value) => {
        if (value._id === userId) {
          return {
            ...value,
            permission
          };
        } else return value;
      })
    );
    const response = await Axios.put(`/project-board/team-permission/${projectBoardId}`, {
      userId: userId,
      permission
    });
    if (response) {
      boardToast('Permission updated!', TOAST_TYPE.SUCCESS);
    }
  };

  const isOwner = (userData) => userData?._id === projectBoardCreatedBy?._id;

  return (
    <Overlay target={target.current} show={show} placement="bottom-end">
      {({
        placement: _placement,
        arrowProps: _arrowProps,
        show: _show,
        popper: _popper,
        hasDoneInitialMeasure: _hasDoneInitialMeasure,
        ...props
      }) => (
        <div {...props} className={style.overlay_wrapper} style={props.style}>
          <div className={style.invite_member_wrapper} ref={overlayRef}>
            <div className={style.invite_member_header_popup}>
              <div>
                <div className={style.title}>{title}</div>
                <div className={style.subtitle}>{subtitle}</div>
              </div>
              <div>
                <CloseButton onClick={onClose} />
              </div>
            </div>
            <div className={style.invite_member_body}>
              {!hideInvitee && (
                <>
                  <div className={style.alert_wrapper}>
                    <div>
                      <div className={style.title}>Invite members via a sharable link</div>
                      <div className={style.subtitle}>Anyone with the link can view</div>
                    </div>
                    <div>
                      <Button variant="success" onClick={handleCopyLink}>
                        <img src={LINK_ICON} alt="link-1" style={{ marginRight: '8px' }} />
                        Copy Link
                      </Button>
                    </div>
                  </div>
                  <div className={style.autocomplete_user_limits_container}>
                    <div className={style.autocomplete_input_container}>
                      <div className={style.autocomplete_selected}>
                        <input
                          value={searchValue}
                          onChange={(e) => handleSearchChange(e.target.value)}
                          placeholder="Enter email address"
                          disabled={viewMode}
                        />
                      </div>
                      {(autocompleteResults?.length > 0 || noOptions) && (
                        <div className={style.autocomplete_search_results}>
                          <div className={style.autocomplete_search_scroll}>
                            {noOptions && <div className="text-center">No Options</div>}
                            {autocompleteResults.map((value) => (
                              <div className={style.autocomplete_option} key={value._id}>
                                <Stack direction="horizontal" className="justify-content-between">
                                  <Stack direction="horizontal" gap={3}>
                                    <div style={{minWidth: "40px"}}>
                                      <img src={value?.profilePic || AVATAR_IMAGE} alt="user" />
                                    </div>
                                    <div>
                                      <div className={style.name}>{value.fullName || value.email}</div>
                                      <div className={style.email}>{value.email}</div>
                                    </div>
                                  </Stack>
                                  <div style={{minWidth: "90px"}}>
                                    <Button
                                      variant="link"
                                      style={{ color: '#4A68FF' }}
                                      color="info"
                                      onClick={() => handleInviteTeam(value)}>
                                      Invite{' '}
                                      <img
                                        src={CHEVRON_RIGHT_ICON}
                                        alt="icon"
                                        style={{ width: '20px', height: '20px' }}
                                      />
                                    </Button>
                                  </div>
                                </Stack>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <Stack direction="horizontal" className="mb-2" style={{ justifyContent: 'space-between' }}>
                <div>Shared with {members?.length || 0} member</div>
                <div className={style.tabs_nav}>
                  {filters.map((value) => (
                    <div
                      className={filterValue === value.value ? style.tab_active : style.tab}
                      onClick={() => handleFilter(value.value)}
                      key={value.value}>
                      {value.label}
                    </div>
                  ))}
                </div>
              </Stack>

              <div className={style.invite_member_item_wrapper}>
                {filteredTeam?.map((value) => (
                  <div className={style.invite_member_item} key={value._id}>
                    <div className={style.member_info}>
                      <div className={style.member_image}>
                        <img src={value?.profilePic || AVATAR_IMAGE} alt="user" />
                      </div>
                      <div>{value?.fullName || value?.email}</div>
                      {!isOwner(value) && !viewMode && userId !== value?._id && (
                        <CloseButton
                          style={{ width: '14px', height: '14px', padding: 0 }}
                          onClick={() => handleRemoveInvitee(value)}
                        />
                      )}
                    </div>

                    <div className={style.dropdown_wrapper}>
                      <div className="form-group select-project form-group-select-icon">
                        <select
                          className="form-control select-small"
                          style={{ width: '100%' }}
                          value={value?.permission}
                          disabled={viewMode || userId === value?._id}
                          onChange={(e) => handleChangePermission(value._id, e.target.value)}>
                          {editOptions.map((value) => (
                            <option value={value.value} key={value.value}>
                              {value.label}
                            </option>
                          ))}
                        </select>
                        <img src={CHEVRON_DOWN_ICON} alt="arrow icon" className="icon-small" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Overlay>
  );
}
