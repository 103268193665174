import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ARROW_LEFT_ICON, PLUS_ICON } from '../assets/image';
import style from './BoardList.module.css';

export default function BoardList({ boards, projectBoardId, handleProjectClick, handleAddBoard }) {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/project-boards');
  };

  return (
    <div className={style.board_list_wrapper}>
      <div className={style.back_arrow_button_wrapper} onClick={handleBackClick}>
        <img src={ARROW_LEFT_ICON} alt="arrow-left" />
        <div className={style.text_content}>Back to Projects List</div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="title">Your Boards</div>
        <div>
          <button className="btn btn-light icon-btn" onClick={handleAddBoard}>
            <img src={PLUS_ICON} alt="search-icon" className="search-icon-right" />
          </button>
        </div>
      </div>
      <div className={style.board_item_wrapper}>
        {boards.map((value) => (
          <div
            className={`${style.board_item} mb-2 ${projectBoardId === value?._id ? 'active' : ''}`}
            title={value.name}
            onClick={() => handleProjectClick(value?._id)}
            key={value._id || 'NEW'}>
            {value.name}
          </div>
        ))}
      </div>
    </div>
  );
}
