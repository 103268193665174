import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import HeadingWithIcon from 'pages/projects/kanban/shared/HeadingWithIcon';
import { useAuth } from 'contexts/AuthContext';
import { useSocket } from '../../context/SocketContext';
import { CHECK_SQUARE2_ICON } from '../../assets/image';
import Tasks from './Tasks';
import style from './Tasks.module.css';
import Axios from '../../utils/Axios';
import boardToast, { TOAST_TYPE } from '../../utils/boardToast';

export default function TaskWrapper({ milestoneId, viewMode, members, projectBoardId }) {
  const socket = useSocket();
  const { user } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [isMyTaskChecked, setIsMyTaskChecked] = useState(false);

  const fetchTasks = useCallback(async () => {
    try {
      const response = await Axios.get(`/milestone-task/view/${milestoneId}`);
      if (response.length > 0) {
        setTasks(response);
        setFilteredTasks(response);
      }
    } catch (error) {
      boardToast('Something went wrong to fetching tasks!', TOAST_TYPE.ERROR);
    }
  }, [milestoneId]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    socket.on('tasks', (data) => {
      if (data[0]?.milestoneId === milestoneId) {
        setTasks(data);
        setFilteredTasks(data);
      }
    });
  }, [milestoneId, socket]);

  const handleAddTask = (data) => {
    socket.emit('add-task', { ...data, milestoneId }, projectBoardId);
  };

  const handleChecked = (data) => {
    socket.emit(
      'update-task',
      {
        id: data._id,
        milestoneId,
        payload: {
          isCompleted: data.isCompleted ? false : true
        }
      },
      projectBoardId
    );
  };

  const handleMyTask = () => {
    if (!isMyTaskChecked) {
      const filterTasks = tasks.filter((value) => value.assignees.some((assignee) => assignee._id === user._id));
      setFilteredTasks(filterTasks);
    } else {
      setFilteredTasks(tasks);
    }

    setIsMyTaskChecked(!isMyTaskChecked);
  };

  return (
    <div className={style.task_main_wrapper}>
      <div style={{ marginBottom: '10px' }}>
        <HeadingWithIcon icon={CHECK_SQUARE2_ICON} heading="Task">
          <div className={style.my_task_checkbox}>
            <Form>
              <Form.Check // prettier-ignore
                type={'checkbox'}
                label="My Task"
                checked={isMyTaskChecked || false}
                onChange={handleMyTask}
                disabled={viewMode}
              />
            </Form>
          </div>
        </HeadingWithIcon>
      </div>
      <Tasks
        viewMode={viewMode}
        milestoneId={milestoneId}
        tasks={filteredTasks}
        handleAddTask={handleAddTask}
        handleChecked={handleChecked}
        members={members}
      />
    </div>
  );
}
