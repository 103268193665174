import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.css';
import X_CLOSE_ICON from 'assets/img/x-close.svg';
import CHECK_ICON from 'assets/img/check2.svg';
import boardToast, { TOAST_TYPE } from '../utils/boardToast';
import { hasOnlySpecialCharacters } from '../utils/general';

export default function EditBigBox({ onClose, handleSubmit, title, onChange = () => {} }) {
  const [value, setValue] = useState(title);
  const [error, setError] = useState(false);

  const inputRef = useRef(null);

  // Focus the input field when the component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleValidate = (value) => {
    // Check if value is invalid or has insufficient length
    if (!value || value.length < 2) {
      boardToast('Min two characters are required', TOAST_TYPE.ERROR);
      setError(true);
      return;
    }

    // Check if value contains only special characters
    if (hasOnlySpecialCharacters(value)) {
      boardToast('The input contains only special characters. Please enter valid text.', TOAST_TYPE.ERROR);
      setError(true);
      return;
    }

    // Proceed with handleSubmit if all validations pass
    handleSubmit(value);
  };

  return (
    <div className={style.input_wrapper} style={error ? { borderColor: '#D11A1A' } : {}}>
      <input
        value={value}
        onChange={(e) => {
          setError(false);
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleValidate(value);
        }}
        maxLength={32}
        placeholder="Enter a title"
      />
      <div className={style.form_submit_control}>
        <div className={style.input_break_line}>|</div>
        <div>
          <img src={CHECK_ICON} alt="check" onClick={() => handleValidate(value)} />
        </div>
        <div>
          <img src={X_CLOSE_ICON} alt="close" onClick={onClose} />
        </div>
      </div>
    </div>
  );
}
