import Cookies from 'universal-cookie';
import { Oval } from 'react-loader-spinner';
import { countries } from 'country-data';
import APIServices from '../services/APIServices';
import appToast, { TOAST_TYPE } from './AppToast';
import { ProfileCompletionStrings } from '../pages/auth/strings/authStrings';
import moment from 'moment-timezone';
import { io } from 'socket.io-client'; // Import socket.io-client

export const PAGE_LIMIT = 10;

const cookies = new Cookies();

export const userType = 'owner';

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

export const userNameLimit = 40;

export const scopeTitleLimit = 60;

export const orgNameLimit = 30;

export const emailLimit = 80;

export const stateLimit = 40;

export const priceLimit = 12;

export const getCookies = (name) => {
  return cookies.get(name);
};

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  //Application somehow unable to set cookie so storing token in sessionStorage and reading from same
  //sessionStorage.setItem(name,value)
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  try {
    if (name === 'authToken') cookies.set(name, value, { expires: expire, path: '/', secure: true });
    else cookies.set(name, value, { expires: expire, path: '/' });
  } catch (err) {
    console.log(`Error setting cookie ${name} - value is ${value} --> ${err}`);
  }
};

// To fetch the user details from the api.
export const getUserDetails = async (token) => {
  let userRes = await APIServices.get(`/user/detail?token=${token}`);

  if (userRes.data) {
    return userRes.data;
  }

  return {};
};

export const getSocketConnected = (userId = '') => {
  const newSocket = io(process.env.REACT_APP_BASE_URL, {
    query: { userId }
  });
  return newSocket;
};

//signup form default values
export const formFields = {
  isTotorialVisited: true,
  fullName: '',
  email: '',
  phoneNumber: '',
  countryDialCode: '+1',
  companyName: '',
  countryCode: 'US',
  profilePic: null
};

// Validate text input fields
export const validateTextInput = (value) => {
  const trimmedValue = value?.trim();
  const startsWithInvalidChar = /^[^a-zA-Z]/.test(trimmedValue);
  const isValid = trimmedValue && trimmedValue.length >= 2 && /[a-zA-Z]/.test(trimmedValue);
  //  &&
  // !startsWithInvalidChar;

  return {
    isValid,
    cleanedValue: trimmedValue,
    message: isValid
      ? 'Valid input'
      : // : startsWithInvalidChar
      // ? "Field should not start with a number or special character."
      !(trimmedValue?.length >= 2)
      ? 'Please enter at least 2 characters.'
      : 'Please ensure the field contains alphabets.'
  };
};

//function to check and validate the password strength
export const checkPasswordStrength = (password) => {
  let error = '';
  let strengthLevel = 0;

  // Check for minimum length (e.g., at least 8 characters)
  if (password.length < 8) {
    return {
      strengthLevel: 0,
      error: 'Password must be at least 8 characters long.'
    };
  }

  strengthLevel++;

  // Check for at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    return {
      strengthLevel: 1,
      error: 'Password must contain at least one uppercase letter.'
    };
  }

  strengthLevel++;

  // Check for at least one number
  if (!/[0-9]/.test(password)) {
    return {
      strengthLevel: 2,
      error: 'Password must contain at least one number.'
    };
  }

  strengthLevel++;

  // Check for at least one special character
  if (!/[^A-Za-z0-9]/.test(password)) {
    return {
      strengthLevel: 3,
      error: 'Password must contain at least one special character.'
    };
  }

  strengthLevel++; // All criteria met

  // Return the strength level and no error
  return {
    strengthLevel,
    error: ''
  };
};

//function to validate the form for registration page
export const validateForm = (formValues, validateCompany = false) => {
  let tempErrors = {};
  if (!validateTextInput(formValues.fullName).isValid) {
    if (!validateTextInput(formValues.fullName).cleanedValue) {
      tempErrors.fullName = 'Full Name is required.';
    } else if (/^[^a-zA-Z]/.test(validateTextInput(formValues.fullName).cleanedValue)) {
      tempErrors.fullName = 'Field should not start with a number or special character.';
    } else {
      tempErrors.fullName = validateTextInput(formValues.fullName).message;
    }
  }
  if (!validateEmail(formValues.email)) {
    tempErrors.email = 'Email Address is required.';
  }
  if (!validateEmail(formValues.email) && formValues.email?.trim().length > 0) {
    tempErrors.email = 'Please enter a valid email address.';
  }
  if (!validateTextInput(formValues.companyName).isValid && validateCompany) {
    if (!validateTextInput(formValues.companyName).cleanedValue) {
      tempErrors.companyName = 'Company name is required.';
    } else {
      tempErrors.companyName = validateTextInput(formValues.companyName).message;
    }
  }
  if (!getPlainPhoneNumber(formValues.phoneNumber)?.trim()) {
    tempErrors.phoneNumber = 'Phone number is required.';
  } else if (getPlainPhoneNumber(formValues.phoneNumber).length < 10)
    tempErrors.phoneNumber = 'PhoneNumber should be 10-15 digits long.';
  return tempErrors;
};

// Common loader for the button loading.
export const selectLoader = (size = 20) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px'
    }}>
    <Oval height={size} width={size} color="#ffff" ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" />
  </div>
);

// Countries options and dialcode data list for phone number input
export const countryOptions = countries.all
  .filter((country) => country.countryCallingCodes.length > 0)
  .map((country) => ({
    value: country.alpha2,
    label: `${country.name} (${country.countryCallingCodes[0]})`,
    dialCode: country.countryCallingCodes[0],
    flag: `flag-icon flag-icon-${country.alpha2.toLowerCase()}`,
    name: country.name
  }));

//Validate the email
export const validateEmail = (email, stateUpdate) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})*$/;
  const consecutiveDotsRegex = /\.\./;
  const trimmedEmail = email?.trim();

  const isValid = emailRegex.test(trimmedEmail) && !consecutiveDotsRegex.test(trimmedEmail);

  if (!isValid) {
    if (stateUpdate) {
      if (!trimmedEmail.trim()) {
        stateUpdate('Email Address is required.');
      } else {
        stateUpdate('Please enter a valid email address.');
      }
    }
    return false;
  }
  return true;
};

//validate phone number for login page
export const validatePhoneNumber = (phoneNumber, setPhoneErrors) => {
  const isValid = phoneNumber && phoneNumber.length >= 10;
  if (!isValid) {
    if (setPhoneErrors) {
      if (phoneNumber.length == 0) {
        setPhoneErrors('Phone Number is required.');
      } else if (!(phoneNumber.length >= 10 && phoneNumber.length <= 15)) {
        setPhoneErrors('Phone Number should be 10-15 digits long.');
      }
    }
  }
  return isValid;
};

//manages the remember me functionalities on the login page
export const manageRememberMe = (verificationType, email, phoneDetails, rememberDevice, country) => {
  if (verificationType === 'email') {
    cookies.set('userEmail', email, { path: '/', maxAge: 7 * 24 * 60 * 60 });
  } else if (verificationType === 'phone') {
    cookies.set('userPhone', phoneDetails, {
      path: '/',
      maxAge: 7 * 24 * 60 * 60
    });
    cookies.set('country', country, {
      path: '/',
      maxAge: 7 * 24 * 60 * 60
    });
  }
  if (rememberDevice) {
    cookies.set('verificationType', verificationType, {
      path: '/',
      maxAge: 7 * 24 * 60 * 60
    });

    if (verificationType === 'email') {
      cookies.set('email', email, { path: '/', maxAge: 7 * 24 * 60 * 60 });
      cookies.remove('phoneDetails'); // Remove phone details if previously stored
      cookies.remove('country');
    } else if (verificationType === 'phone') {
      cookies.set('phoneDetails', phoneDetails, {
        path: '/',
        maxAge: 7 * 24 * 60 * 60
      });
      cookies.set('country', country, {
        path: '/',
        maxAge: 7 * 24 * 60 * 60
      });
      cookies.remove('email'); // Remove email if previously stored
    }

    cookies.set('rememberDevice', rememberDevice, {
      path: '/',
      maxAge: 7 * 24 * 60 * 60
    });
  } else {
    // Clear all related cookies if "Remember this device" is not selected
    cookies.remove('email');
    cookies.remove('phoneDetails');
    cookies.remove('verificationType');
    cookies.remove('rememberDevice');
    cookies.remove('country');
  }
  cookies.set('userType', verificationType, {
    path: '/',
    maxAge: 7 * 24 * 60 * 60
  });
};

// Function to get the plain numeric phone number for API
export const getPlainPhoneNumber = (phoneNumber) => {
  let plainNumber = '';
  // Remove formatting from the phone number
  plainNumber = phoneNumber?.replace(/[^0-9]/g, '');
  return plainNumber;
};

export const getformattedPhoneNumber = (value) => {
  const numericInput = value?.replace(/[^0-9]/g, '').slice(0, 14); // Limit to 14 digits

  // Format the phone number as (XXX) XXX-XXXX
  let formattedNumber = numericInput;

  if (numericInput?.length > 3 && numericInput?.length <= 6) {
    // Format as (XXX) XXX
    formattedNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3)}`;
  } else if (numericInput?.length > 6) {
    // Format as (XXX) XXX-XXXX
    formattedNumber = `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 14)}`;
  }

  return formattedNumber || '';
};

//Homapege tutorials steps
export const HomeSteps = [
  {
    target: '.search-box',
    title: 'Find What You Need with Search',
    content: 'Quickly locate projects, tasks, and updates using the search bar.',
    disableBeacon: true
  },
  {
    target: '.notify-drp',
    title: 'Stay Informed with Notifications',
    content: 'Keep track of important updates and changes in your projects.',
    disableBeacon: true
  },
  {
    target: '.menuicon',
    title: 'Explore More with the Menu',
    content: 'Access additional features and settings from the menu.',
    disableBeacon: true
  },
  {
    target: '.process-cover',
    title: 'Track Progress with the Graph',
    content: 'Monitor your project’s progress and milestones visually.',
    disableBeacon: true
  },
  {
    target: '.create_project',
    title: 'Get Started with a New Project',
    content: 'Set up a project with all necessary details and team members.',
    disableBeacon: true
  },
  {
    target: '.create_team',
    title: 'Build Your Team',
    content: 'Add and manage team members for efficient collaboration.',
    disableBeacon: true
  },
  {
    target: '.create_task',
    title: 'Streamline Work with Templates',
    content: 'Create templates to standardize tasks and processes.',
    disableBeacon: true
  }
];

//Homapege tutorials steps
export const BlankHomeSteps = [
  {
    target: '.notify-drp',
    title: 'Stay Informed with Notifications',
    content: 'Keep track of important updates and changes in your projects.',
    disableBeacon: true
  },
  {
    target: '.menuicon',
    title: 'Explore More with the Menu',
    content: 'Access additional features and settings from the menu.',
    disableBeacon: true
  },

  {
    target: '.create_project',
    title: 'Get Started with a New Project',
    content: 'Set up a project with all necessary details and team members.',
    disableBeacon: true
  },
  {
    target: '.create_team',
    title: 'Build Your Team',
    content: 'Add and manage team members for efficient collaboration.',
    disableBeacon: true
  },
  {
    target: '.create_task',
    title: 'Streamline Work with Templates',
    content: 'Create templates to standardize tasks and processes.',
    disableBeacon: true
  }
];

// Function to format the timer in MM:SS format
export const formatTime = (timer) => {
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  return `${String(minutes).padStart(2)}:${String(seconds).padStart(2, '0')}`;
};

// Update user profile functionalities:
export const updateUserDetails = async (formValues, setError, setLoading, showToast = true) => {
  setLoading(true);
  try {
    let formData = { ...formValues };
    const form = new FormData();
    Object.entries(formData).forEach(([key, value]) => form.append(key, value));

    formData.phoneNumber = getPlainPhoneNumber(formData.phoneNumber);
    const response = await APIServices.post('/user/update-profile', form, {
      'Content-Type': 'multipart/form-data'
    });

    if (response.message) {
      if (showToast) appToast(response.message, TOAST_TYPE.SUCCESS);
      return true;
    } else {
      if (showToast) appToast(response.data.message || ProfileCompletionStrings.errorOccurred, TOAST_TYPE.ERROR);
      return false;
    }
  } catch (err) {
    setError(ProfileCompletionStrings.failedToRegister);
    console.error('Error while Updating profile:', err);
  } finally {
    setLoading(false);
  }
};

// Helper function to format UTC offsets
export const getUtcOffsetLabel = (timezone) => {
  const offset = moment.tz(timezone).utcOffset();
  const sign = offset >= 0 ? '+' : '-';
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  return `(UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')})`;
};

//function to validate the form of overview/details page
export const validateDetailsTabForm = (formValues, setErrors, isInternalProject = false) => {
  let newErrors = {};

  if (!validateTextInput(formValues.projectName).isValid) {
    if (!validateTextInput(formValues.projectName).cleanedValue) {
      newErrors.projectName = 'Project Name is required.';
    } else {
      newErrors.projectName = validateTextInput(formValues.projectName).message;
    }
  }

  if (!isInternalProject && !formValues.organizationId) {
    newErrors.organizationId = 'Please select an organization.';
  }

  if (!formValues.startDate) {
    newErrors.startDate = 'Project Start Date is required.';
  }

  if (!formValues.endDate) {
    newErrors.endDate = 'Project Due Date is required.';
  } else if (formValues.startDate && formValues.endDate && formValues.endDate < formValues.startDate) {
    newErrors.endDate = 'DueDate must be after the Start Date.';
  }

  // if (!formValues.estimatedPrice) {
  //   newErrors.estimatedPrice = "Estimated Value is required.";
  // } else if (Number(formValues.estimatedPrice) <= 0) {
  //   newErrors.estimatedPrice = "Estimated Value must be a positive number.";
  // }

  setErrors(newErrors);

  // Disable submit button if there are any errors or missing fields
  // setIsSubmitDisabled(Object.keys(newErrors).length > 0);
  return Object.keys(newErrors).length > 0;
};

export const validateMilestoneFields = (milestones, setErrors, saveTemplate, dateDiff) => {
  let hasError = false;
  const newErrors = {};

  milestones.forEach((milestone, index) => {
    newErrors[index] = {};
    if (!validateTextInput(milestone.name).isValid) {
      if (!validateTextInput(milestone.name).cleanedValue) {
        newErrors[index].name = 'Milestone name is required.';
      } else {
        newErrors[index].name = validateTextInput(milestone.name).message;
      }
      hasError = true;
    }
    if (!validateTextInput(milestone.description).isValid) {
      if (!validateTextInput(milestone.description).cleanedValue) {
        newErrors[index].description = 'Description is required.';
      } else {
        newErrors[index].description = validateTextInput(milestone.description).message;
      }
      hasError = true;
    }

    if (milestone.signOffRequired && milestone.signOffTime === '') {
      console.log(milestone.signOffTime);

      newErrors[index].signOffTime = 'SignOff Time is required.';
      hasError = true;
    }
    if (milestone.signOffRequired && milestone.signOffTime > dateDiff) {
      newErrors[index].signOffTime = `SignOff Time cannot exceed the allowed limit of ${dateDiff} days.`;
      hasError = true;
    }
    if (milestone.daysToComplete == null) {
      newErrors[index].daysToComplete = 'Days to Completion is required.';
      hasError = true;
    }

    if (milestone.daysToComplete && milestone.daysToComplete > dateDiff) {
      newErrors[index].daysToComplete = `Days to completion cannot exceed the allowed limit of ${dateDiff} days.`;
      hasError = true;
    }
    // if (milestone.daysToComplete && milestone.daysToComplete == 0) {
    //   newErrors[index].daysToComplete = `Days to completion cannot be 0.`;
    //   hasError = true;
    // }
    if (milestone.paymentDueRequired && !milestone.paymentDue) {
      newErrors[index].paymentDue = 'Payment Due is required.';
      hasError = true;
    }
    if (milestone.paymentDueRequired && milestone.paymentDue == 0) {
      newErrors[index].paymentDue = 'Payment Due cannot be 0.';
      hasError = true;
    }
  });
  console.log(newErrors);

  setErrors(newErrors);
  return !hasError;
};

// function to calculate the diff between two dates.. used in milstone add template.
export const calculateDateDiff = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Get the time difference in milliseconds
  const timeDiff = endDate - startDate;

  // Convert milliseconds to days
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
};

// Function to format date in this format "Oct 1" or "Oct 1, 2025" based on showYear
export const formatDate = (dateString, showYear = false) => {
  // Convert the input string into a Date object
  const date = new Date(dateString);

  // Define the options for formatting the date
  const options = {
    month: 'short',
    day: 'numeric'
  };

  // Add the year to the options if showYear is true
  if (showYear) {
    options.year = 'numeric';
  }

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

  return formattedDate;
};

// make list of the data which is coming fromt he backend
export const formatOptions = (options) => {
  const formattedOptions = options.map((option) => ({
    value: option._id,
    label: option.name
  }));

  return formattedOptions;
};

export const daysOptions = [
  { value: 0, label: '0 Day' },
  { value: 1, label: '1 Day' },
  { value: 2, label: '2 Days' },
  { value: 3, label: '3 Days' },
  { value: 4, label: '4 Days' },
  { value: 5, label: '5 Days' },
  { value: 6, label: '6 Days' },
  { value: 7, label: '7 Days' }
];

export const projectFilters = [
  { value: 'all', label: 'All' },
  { value: 'open', label: 'Open' },
  { value: 'closed', label: 'Closed' }
];

export const calculateDateDifference = (inputDate = new Date()) => {
  // Parse the input date and today's date
  const givenDate = new Date(inputDate);
  const today = new Date();

  // Normalize both dates to midnight (00:00:00) to ensure only date comparison
  // today.setHours(0, 0, 0, 0);
  // givenDate.setHours(0, 0, 0, 0);

  // Calculate the difference in full days
  const diffInMilliseconds = givenDate - today;
  const diffInDays = Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));

  if (diffInDays > 0) {
    return diffInDays === 1 ? '1 day' : `${diffInDays} days`;
  }

  if (diffInDays === 0) {
    // If the date is today, calculate the remaining hours
    const now = new Date();
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    const diffInHours = Math.floor((endOfDay - now) / (1000 * 60 * 60));

    return diffInHours > 0 ? `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'}` : 'Overdue';
  }

  // If the date is in the past
  return 'Overdue';
};

const toEST = (date) => {
  return new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
};

// Format date and time for chat with the same format and time preference
export const formatDateTime = (
  dateString,
  dateFormat = 'MM/DD/YYYY',
  timeFormat = '12hour',
  isMessage = false,
  showYesterday = true
) => {
  const date = new Date(dateString);
  const estDate = toEST(date); // Convert to EST
  const today = new Date();

  // Reset today's date to midnight for easier comparison
  today.setHours(0, 0, 0, 0);

  // Calculate yesterday by subtracting one day from today's midnight time
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  // Helper function to format date based on the provided format
  const formatDate = (date, format) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    switch (
      format.toUpperCase() // Ensure case insensitivity
    ) {
      case 'DD/MM/YYYY':
        return estDate.toLocaleDateString('en-GB', options);
      case 'MM/DD/YYYY':
        return estDate.toLocaleDateString('en-US', options);
      case 'YYYY/MM/DD':
        return estDate.toISOString().split('T')[0];
      default:
        return estDate.toLocaleDateString(); // Fallback to default format
    }
  };

  // Helper function to format time based on the 12-hour or 24-hour preference
  const formatTime = (date, format) => {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: format === '12hour' // Use 12-hour format if specified
    });
  };

  // Determine if the date is today, yesterday, or neither
  if (date >= today) {
    if (isMessage) {
      return `today at ${formatTime(date, timeFormat)} `;
    }
    return `- ${formatTime(date, timeFormat)} Today`;
  } else if (date >= yesterday) {
    if (showYesterday) {
      if (isMessage) {
        return `yesterday at ${formatTime(date, timeFormat)}`;
      }
      return `- ${formatTime(date, timeFormat)} Yesterday`;
    } else {
      return `${formatDate(date, dateFormat)} at ${formatTime(date, timeFormat)} `;
    }
  } else {
    if (isMessage) {
      return `${formatDate(date, dateFormat)} at ${formatTime(date, timeFormat)} `;
    }
    return `- ${formatTime(date, timeFormat)} ${formatDate(date, dateFormat)}`;
  }
};

export const adjustDate = (dateString, days) => {
  // Parse the input date string into a Date object
  const date = new Date(dateString);

  // Add or subtract the days
  date.setDate(date.getDate() + days);

  // Format the date to "Month Day"
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric'
  }).format(date);

  // Return the updated date in ISO 8601 format
  return formattedDate;
};

// function to handle the roles of users:
// rolePermissions.js
export const ROLE_PERMISSIONS = {
  owner: [
    // "all_permissions",
    'update_team_page',
    'create_projects',
    'update_projects',
    'edit_milestones',
    'send_signoff_requests',
    'change_request',
    'feedback_request',
    'change_order',
    'add_qoute',
    'close_req',
    'modify_team'
    // "modify_clients",
  ],
  team_admin: [
    // "all_permissions",
    'update_team_page',
    'create_projects',
    'update_projects',
    'edit_milestones',
    'send_signoff_requests',
    'change_request',
    'feedback_request',
    'change_order',
    'add_qoute',
    'close_req',
    'modify_team'
    // "modify_clients",
  ],
  team_member: ['update_projects', 'send_signoff_requests', 'change_request', 'change_order'],
  contractor: ['update_projects'],
  consultant: ['update_projects'],
  admin: [
    'change_order',
    'change_request',
    'approve_sign_off',
    'approve_qoute',
    'feedback_give'
    // "modify_team",
  ],
  user: ['feedback_give']
};

export const hasPermission = (role, permission, memberPermission = 'update', isInternalProject = false) => {
  if (!ROLE_PERMISSIONS[role]) {
    return false; // Role not found
  }

  // Check if it's an internal project and the role is owner or team_admin
  if (isInternalProject && (role === 'owner' || role === 'team_admin')) {
    return true; // All permissions granted for internal projects
  }

  // Owner has all permissions
  if (ROLE_PERMISSIONS[role].includes('all_permissions')) {
    return true;
  }

  if (memberPermission === 'View' && ROLE_PERMISSIONS[role] !== 'admin' && ROLE_PERMISSIONS[role] !== 'user') {
    return false;
  }
  // Check if the permission exists for the role
  return ROLE_PERMISSIONS[role].includes(permission);
};

export const getPriorityStatus = (name) => {
  if (name?.toUpperCase() === 'HIGH') {
    return 'warning';
  } else if (name?.toUpperCase() === 'URGENT') {
    return 'error';
  } else if (name?.toUpperCase() === 'NONE') {
    return 'primary';
  } else if (name?.toUpperCase() === 'LOW') {
    return 'info';
  }
};
//clears the data of milestones stored from the create
export const clearLocalStorage = () => {
  localStorage.removeItem('formData');
};

// Function to replace user mentions with highlighted names
export const renderHighlightedMessage = (activity) => {
  let formattedMessage = activity.message;

  activity.message_user_tags.forEach((tag) => {
    const mentionRegex = new RegExp(`@${tag.tagname}`, 'g');
    formattedMessage = formattedMessage.replace(mentionRegex, `<span class="lightblue-text">@${tag.tagname}</span>`);
  });

  return formattedMessage;
};

export const formatPricing = (value) => {
  if (!value) return '';
  const [integerPart, decimalPart] = value?.split('.');
  const formattedInteger = parseInt(integerPart || '0', 10).toLocaleString('en-US');
  return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export const cleanFormattedPricing = (formattedValue) => {
  if (!formattedValue) return '';
  const cleanValue = String(formattedValue)?.replace(/,/g, ''); // Remove commas
  return cleanValue?.includes('.') ? parseFloat(cleanValue) : parseInt(cleanValue, 10);
};

export const validPricing = (price) => {
  // Only allow digits and period for decimal values
  const validValue = price
    // Allow only digits and a single period
    .replace(/[^0-9.]/g, '')
    // Prevent multiple periods
    .replace(/(\..*)\./g, '$1')
    // Restrict to two decimal places
    .replace(/^(\d+\.?\d{0,2}).*$/, '$1')
    // Remove leading zeros (except for 0 before the decimal point)
    .replace(/^0+(?!\.|$)/, '');

  return validValue;
};

export const updateUserFormValues = ({ userDetails, setFormValues, setVerified }) => {
  const {
    email,
    countryDialCode,
    phoneNumber,
    fullName,
    countryCode,
    profilePic,
    companyId,
    isTotorialVisited,
    isEmailVerified,
    isPhoneNumberVerified
  } = userDetails;

  // Update form values
  setFormValues((prevValues) => ({
    ...prevValues,
    email,
    countryDialCode,
    phoneNumber: getformattedPhoneNumber(phoneNumber),
    fullName,
    countryCode,
    profilePic,
    companyName: companyId?.name,
    isTotorialVisited
  }));

  // Update verification status
  setVerified({
    isEmailVerified,
    isPhoneNumberVerified
  });
};

export const getOrgUserRole = (user, orgId) => {
  return user?.organizations?.find((x) => x.organizationId._id == orgId);
};
