import React, { useEffect, useState } from "react";
import Select from "react-select";
import "../../../assets/css/sign-off-request-modal.css";
import deleteBtn from "../../../assets/img/delete.svg";
import attechFile from "../../../assets/img/ios-attach.svg";
import filePic from "../../../assets/img/file-picture.svg";
import {
  fetchProjectMilestones,
} from "../../../utils/fetchData";
import APIServices from "../../../services/APIServices";
import {
  daysOptions,
  formatOptions,
  MAX_FILE_SIZE,
  selectLoader,
  validateTextInput,
} from "../../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import { useStore } from "../../../hooks/useStore";

const SignOffForm = ({
  updatedMilestoneId = "",
  projectId = "",
  milestoneId = "",
  requestId = "",
  closeModal,
}) => {
  console.log(milestoneId);

  const [store, setStore] = useStore();
  // const [projectId, setProjectId] = useState("");
  const [mileStonesList, setMileStonesList] = useState([]);
  const [mileStones, setMileStones] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [currentMileStone, setCurrentMileStone] = useState(null);
  const [requestDetails, setRequestDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [filestoDelete, setFilesToDelete] = useState([]);
  const [formData, setFormData] = useState({
    milestoneId: updatedMilestoneId || "",
    signoffStatus: "",
    attachments: [],
    description: "",
    signoffDeadline: "",
    projectId: "",
    // title: "",
    requestType: "sign_off",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (store.projectId) {
      // setProjectId(store.projectId);
      setFormData((prevData) => ({
        ...prevData,
        projectId: store.projectId,
      }));
    }
  }, [store.projectId]);

  const fetchMilestones = async () => {
    setPageLoading(true);
    try {
      let milestones = await fetchProjectMilestones(projectId, "update");
      // if (milestones.length > 0) {
      setMileStones(milestones);
      if (milestoneId) {
        let currentMilestone = milestones.find((x) => x._id == milestoneId);
        setCurrentMileStone(currentMilestone);
        if (requestId) {
          fetchRequestDetails(requestId);
        }
      } else {
        setPageLoading(false);
      }
      // }
      fetchStatusOptions();
    } catch (error) {
      console.error("Error fetching milestones:", error);
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const fetchRequestDetails = async (requestId) => {
    try {
      let response = await APIServices.get(
        `/project-requests/detail/${requestId}`
      );
      if (response.data) {
        setRequestDetails(response.data);
      }
      fetchStatusOptions();
    } catch (error) {
      console.error("Error fetching milestones:", error);
    } finally {
      setPageLoading(false);
    }
  };

  const fetchStatusOptions = async () => {
    setLoading(true);
    try {
      const response = await APIServices.get(
        `/enum?item_type=milestonstatus&filter=inprogress,complete`
      );
      if (response && response.length > 0) {
        const formattedOptions = formatOptions(response);
        setStatusOptions(formattedOptions);
      }
    } catch (error) {
      console.error("Error fetching status options:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMilestones();
    if (milestoneId && requestId) {
      setEditing(true);
    }
  }, []);

  useEffect(() => {
    if (requestDetails) {
      setFormData((prevData) => ({
        ...prevData,
        description: requestDetails?.description,
        attachments: requestDetails?.attachments,
      }));
      setFiles(requestDetails?.attachments);
    }
  }, [requestDetails]);

  useEffect(() => {
    if (currentMileStone) {
      console.log(currentMileStone);
      let latestUpdates = currentMileStone?.latestUpdate[0];
      setFormData((prevData) => ({
        ...prevData,
        milestoneId: currentMileStone._id,
        signoffStatus: currentMileStone?.status?._id,
        signoffDeadline: currentMileStone?.signOffTime,
      }));
      console.log(currentMileStone?.signOffTime);

      // if (latestUpdates?.updateStatus?.value)
      //   setCurrentStatus(latestUpdates?.updateStatus?.value);
    }
  }, [currentMileStone]);

  useEffect(() => {
    if (mileStones?.length > 0) {
      const formattedOptions = formatOptions(mileStones);
      setMileStonesList(formattedOptions);
    }
  }, [mileStones]);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalFiles = files.length + selectedFiles.length;

    // Check if the total number of files exceeds the limit
    if (totalFiles > 5) {
      appToast("You can upload a maximum of 5 files.", TOAST_TYPE.ERROR);
      return;
    }

    // Calculate the total size of all files including the new ones
    const totalSize =
      files.reduce((sum, file) => sum + file.size, 0) +
      selectedFiles.reduce((sum, file) => sum + file.size, 0);

    // Check if the total size exceeds the 40MB limit
    if (totalSize > 40 * 1024 * 1024) {
      appToast(
        "The total size of uploaded files should not exceed 40MB.",
        TOAST_TYPE.ERROR
      );
      return;
    }

    // Add the selected files to the state if they meet the criteria
    setFiles([...files, ...selectedFiles]);
  };

  const handleRemoveFile = (index) => {
    if (isEditing) {
      let fileToDelete = files.filter((_, i) => i == index);
      console.log(fileToDelete[0]._id);
      if (fileToDelete && fileToDelete.length > 0 && fileToDelete[0]._id) {
        setFilesToDelete((prevFiles) => [...prevFiles, fileToDelete[0]._id]);
      }
    }

    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleFieldChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    if (errors[fieldName]) {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    }
  };
  // Handle input changes and remove error
  const handleInputChange = (e, fieldName) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    if (errors[fieldName]) {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    }
  };

  const handleDropdownChange = (selected, fieldName) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selected ? selected.value : null,
    }));
    console.log(selected.value);
    

    if (errors[fieldName]) {
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
    }

    if (fieldName == "milestoneId") {
      const currentMilestone = mileStones.find(
        (x) => x._id === selected?.value
      );
      setCurrentMileStone(currentMilestone);
      console.log(currentMilestone);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.milestoneId) {
      newErrors.milestoneId = "Please select a milestone.";
    }

    if (!validateTextInput(formData.description).isValid) {
      if (!validateTextInput(formData.description).cleanedValue) {
        newErrors.description = "Request Description is required.";
      } else {
        newErrors.description = validateTextInput(formData.description).message;
      }
    }

    if (!formData.signoffStatus) {
      newErrors.signoffStatus = "Please select a status.";
    }

    // if (formData.signoffDeadline === "") {
    //   newErrors.signoffDeadline = "Please select the number of days.";
    // }

    // if (!formData.title.trim()) {
    //   newErrors.title = "Please enter Title.";
    // }
    // if (files.length === 0) {
    //   newErrors.files = "At least one file must be uploaded.";
    // }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    setSubmitLoading(true);
    const formDataToSend = new FormData();

    // Dynamically append form fields
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "signoffDeadline") {
        if (value === "" || value === undefined) {
          // Skip attachments for now
          formDataToSend.append(key, 0);
        } else {
          formDataToSend.append(key, value);
        }
      }
      if (key !== "attachments" && key !== "signoffDeadline") {
        // Skip attachments for now
        formDataToSend.append(key, value);
      }
    });

    // Append attachments separately
    files.forEach((file) => {
      formDataToSend.append("attachments", file);
    });

    if (isEditing && filestoDelete.length > 0) {
      formDataToSend.append("deletedAttachments", filestoDelete.join(","));
    }
    try {
      const response = isEditing
        ? await APIServices.put(
            `/project-requests/${requestId}`,
            formDataToSend,
            {
              "Content-Type": "multipart/form-data",
            }
          )
        : await APIServices.post("/project-requests", formDataToSend, {
            "Content-Type": "multipart/form-data",
          });
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        closeModal();
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.ERROR);
        closeModal();
      }
    } catch (error) {
      console.error("Error submitting sign-off request:", error);
      appToast("An error occurred. Please try again.", TOAST_TYPE.ERROR);
    } finally {
      setSubmitLoading(false);
    }
  };

  if (pageLoading) {
    return <div className="nodata-loader"> {selectLoader(60)}</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className={`form-control select2 ${
                errors.milestoneId ? "form-invalid" : ""
              } ${isEditing || updatedMilestoneId ? "input-desable" : ""}`}
              options={mileStonesList}
              placeholder="Select Milestone"
              value={mileStonesList.find(
                (option) => option.value === formData.milestoneId
              )}
              onChange={(e) => handleDropdownChange(e, "milestoneId")}
              isClearable
              isLoading={loading}
              isDisabled={isEditing || updatedMilestoneId}
            />
            {errors.milestoneId && (
              <small className="text-danger">{errors.milestoneId}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <Select
              className={`form-control select2 ${
                errors.signoffStatus ? "form-invalid" : ""
              }`}
              options={statusOptions}
              value={statusOptions.find(
                (option) => option.value === formData.signoffStatus
              )}
              onChange={(e) => handleDropdownChange(e, "signoffStatus")}
              placeholder="Status"
              isClearable
              isLoading={loading}
            />
            {errors.signoffStatus && (
              <small className="text-danger">{errors.signoffStatus}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              placeholder="Description"
              className={`form-control ${
                errors.description ? "form-invalid" : ""
              }`}
              value={formData.description}
              onChange={(e) => handleFieldChange(e, "description")}
            />
            {errors.description && (
              <small className="text-danger">{errors.description}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.signoffDeadline ? "form-invalid" : ""
              }`}
              name="signoffDeadline"
              placeholder="Number of Days"
              value={formData.signoffDeadline}
              onChange={(e) => {
                console.log(e);
                const validValue = e.target.value.replace(/[^0-9.]/g, "");
                // Allow only digits and decimal points
                const regex = /^[0-9]*\.?[0-9]*%?$/;
                if (regex.test(e.target.value) && validValue <= 100) {
                  handleInputChange(e, "signoffDeadline");
                } else if (validValue > 100) {
                  appToast(
                    "Number of Days must not exceed 100.",
                    TOAST_TYPE.WARNING
                  );
                }
              }}
            />
            {/* <Select
              className={`form-control select2 ${
                errors.signoffDeadline ? "form-invalid" : ""
              }`}
              options={daysOptions}
              value={daysOptions.find(
                (option) => option.value === formData.signoffDeadline
              )}
              onChange={(e) => handleDropdownChange(e, "signoffDeadline")}
              placeholder="Number of Days"
              isClearable
            /> */}
            {errors.signoffDeadline && (
              <small className="text-danger">{errors.signoffDeadline}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <a className="attech-btn">
                <img src={attechFile} alt="comp1" className="attechFile mr-1" />
                Attach Files
              </a>

              <label className="add-btn-text cursor_pointer">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                + Add
              </label>
            </div>
            {files.map((file, index) => (
              <div className="user-box-cover" key={index}>
                <div className="username">
                  <img src={filePic} alt="comp1" className="filePic" />
                  <div className="username-content">
                    <p>{file.name}</p>
                    <small>
                    {(file.size / 1024).toFixed(2) !== "NaN" ? `${(file.size / 1024).toFixed(2)} KB` : file.size}
                    </small>
                  </div>
                </div>
                <img
                  src={deleteBtn}
                  alt="comp1"
                  className="deleteBtn cursor_pointer"
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            ))}
            {errors.files && (
              <small className="text-danger">{errors.files}</small>
            )}
          </div>
        </div>
      </div>

      <button className="btn-black" type="submit" disabled={submitLoading}>
        {submitLoading ? selectLoader(35) : isEditing ? "Update" : "Submit"}
      </button>
    </form>
  );
};

export default SignOffForm;
