import React from 'react';
import userIcon from 'assets/img/user.svg';
import style from './style.module.css';
import { findLetter } from '../utils/general';

export default function AvatarGroup({ members, size, limit = 3, handleClick = () => {} }) {
  return (
    <div>
      <div className={style.avatar_group} onClick={handleClick}>
        {members &&
          members
            .filter((value, index) => index < limit)
            .map((value) => (
              <div
                className={style.avatar}
                key={value._id}
                style={
                  size
                    ? { width: `${size}px`, height: `${size}px`, minWidth: `${size}px`, lineHeight: `${size}px` }
                    : {}
                }>
                <span className={style.avatar_name}>{value?.fullName || value?.email}</span>
                {value?.profilePic ? (
                  <img
                    src={value?.profilePic || userIcon}
                    style={size ? { width: `${size}px`, height: `${size}px`, minWidth: `${size}px` } : {}}
                    alt="user"
                  />
                ) : (
                  findLetter(value?.fullName || value?.email)
                )}
              </div>
            ))}
        {members?.length > limit && (
          <div
            className={style.avatar_empty}
            style={size ? { width: `${size}px`, height: `${size}px`, minWidth: `${size}px` } : {}}>
            +{members?.length - limit}
          </div>
        )}
      </div>
    </div>
  );
}
