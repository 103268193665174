import React, { useEffect, useState } from "react";
import deleteBtn from "../../../assets/img/delete.svg";
import attechFile from "../../../assets/img/ios-attach.svg";
import filePic from "../../../assets/img/file-picture.svg";
import {
  MAX_FILE_SIZE,
  selectLoader,
  userNameLimit,
  validateTextInput,
} from "../../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";

const RequestModal = ({ projectId = "", requestId = "", closeModal }) => {
  const [formData, setFormData] = useState({
    projectId: projectId || "",
    title: "",
    description: "",
    changeRequestDeadline: "",
    attachments: [],
    requestType: "change_request",
  });
  const [files, setFiles] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [requestDetails, setRequestDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (requestId) {
      setEditing(true);
      fetchRequestDetails();
      console.log(requestId);
    }
  }, [requestId]);

  useEffect(() => {
    if (requestDetails) {
      console.log(requestDetails);
      setFormData((prevData) => ({
        ...prevData,
        description: requestDetails?.description,
        title: requestDetails?.title,
        attachments: requestDetails?.attachments,
        changeRequestDeadline: requestDetails?.changeRequestDeadline,
      }));
      setFiles(requestDetails?.attachments);
    }
  }, [requestDetails]);

  //fetch request details while updating/editing request
  const fetchRequestDetails = async () => {
    setPageLoading(true);
    try {
      let response = await APIServices.get(
        `/project-requests/detail/${requestId}`
      );
      if (response.data) {
        setRequestDetails(response.data);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
    } finally {
      setPageLoading(false);
    }
  };

  // Handle file upload
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalFiles = files.length + selectedFiles.length;

    // Check if the total number of files exceeds the limit
    if (totalFiles > 5) {
      appToast("You can upload a maximum of 5 files.", TOAST_TYPE.ERROR);
      return;
    }

    // Calculate the total size of all files including the new ones
    const totalSize =
      files.reduce((sum, file) => sum + file.size, 0) +
      selectedFiles.reduce((sum, file) => sum + file.size, 0);

    // Check if the total size exceeds the 40MB limit
    if (totalSize > 40 * 1024 * 1024) {
      appToast(
        "The total size of uploaded files should not exceed 40MB.",
        TOAST_TYPE.ERROR
      );
      return;
    }

    // Add the selected files to the state if they meet the criteria
    setFiles([...files, ...selectedFiles]);
  };

  // Handle file removal
  const handleRemoveFile = (index) => {
    if (isEditing) {
      let fileToDelete = files.filter((_, i) => i === index);
      if (fileToDelete && fileToDelete.length > 0 && fileToDelete[0]._id) {
        setFilesToDelete((prevFiles) => [...prevFiles, fileToDelete[0]._id]);
      }
    }

    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  // Handle input changes and remove error
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedValue =
      name === "changeRequestDeadline" ? value.replace(/[^0-9.]/g, "") : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  // Form validation
  const validateForm = () => {
    let formErrors = {};

    if (!validateTextInput(formData.title).isValid) {
      if (!validateTextInput(formData.title).cleanedValue) {
        formErrors.title = "Please enter request title.";
      } else {
        formErrors.title = validateTextInput(formData.title).message;
      }
    }

    // if (!validateTextInput(formData.description).isValid) {
    //   if (!validateTextInput(formData.description).cleanedValue) {
    //     formErrors.description = "Please enter description.";
    //   } else {
    //     formErrors.description = validateTextInput(
    //       formData.description
    //     ).message;
    //   }
    // }

    // if (formData.changeRequestDeadline === "") {
    //   formErrors.changeRequestDeadline =
    //     "Days needed for response is required.";
    // }
    // if (files.length === 0) {
    //   formErrors.files = "At least one attachment is required.";
    // }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    const formDataToSend = new FormData();

    // Dynamically append form fields
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "attachments") {
        // Skip attachments for now
        formDataToSend.append(key, value);
      }
    });

    // Append attachments separately
    files.forEach((file) => {
      formDataToSend.append("attachments", file);
    });

    if (isEditing && filesToDelete.length > 0) {
      formDataToSend.append("deletedAttachments", filesToDelete.join(","));
    }
    try {
      const response = isEditing
        ? await APIServices.put(
            `/project-requests/${requestId}`,
            formDataToSend,
            {
              "Content-Type": "multipart/form-data",
            }
          )
        : await APIServices.post("/project-requests", formDataToSend, {
            "Content-Type": "multipart/form-data",
          });
      console.log(response);

      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        closeModal();
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.ERROR);
        closeModal();
      }
    } catch (error) {
      console.error("Error submitting change order request:", error);
      // appToast("An error occurred. Please try again.", TOAST_TYPE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  if (pageLoading) {
    return <div className="nodata-loader"> {selectLoader(60)}</div>;
  }

  return (
    <div>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              maxLength={userNameLimit}
              type="text"
              className={`form-control ${errors.title ? "form-invalid" : ""}`}
              name="title"
              placeholder="Title"
              value={formData.title}
              onChange={handleInputChange}
            />
            {errors.title && (
              <small className="text-danger">{errors.title}</small>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
            style={{ maxHeight: "320px" }}
              placeholder="Description"
              className={`form-control ${
                errors.description ? "form-invalid" : ""
              }`}
              name="description"
              value={formData.description}
              onChange={(e) => {
                handleInputChange(e);
                e.target.style.height = "auto"; // Reset the height
                e.target.style.height = `${e.target.scrollHeight + 2}px`; // Set the height to fit content
              }}
            ></textarea>
            {/* {errors.description && (
              <small className="text-danger">{errors.description}</small>
            )} */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${
                errors.changeRequestDeadline ? "form-invalid" : ""
              }`}
              name="changeRequestDeadline"
              placeholder="Days needed for response"
              value={formData.changeRequestDeadline}
              onChange={(e) => {
                console.log(e);
                const validValue = e.target.value.replace(/[^0-9.]/g, "");

                if (validValue <= 100) {
                  handleInputChange(e);
                } else if (validValue > 100) {
                  appToast(
                    "The Days needed for response must not exceed 100.",
                    TOAST_TYPE.WARNING
                  );
                }
              }}
            />
            {errors.changeRequestDeadline && (
              <small className="text-danger">
                {errors.changeRequestDeadline}
              </small>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <a className="attech-btn">
                <img
                  src={attechFile}
                  alt="Attach"
                  className="attechFile mr-1"
                />
                Attach Files
              </a>

              <label className="add-btn-text cursor_pointer">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                + Add
              </label>
            </div>
            {files.map((file, index) => (
              <div className="user-box-cover" key={index}>
                <div className="username">
                  <img src={filePic} alt="File" className="filePic" />
                  <div className="username-content">
                    <p>{file.name}</p>
                    <small>
                    {(file.size / 1024).toFixed(2) !== "NaN" ? `${(file.size / 1024).toFixed(2)} KB` : file.size}
                    </small>
                  </div>
                </div>
                <img
                  src={deleteBtn}
                  alt="Delete"
                  className="deleteBtn cursor_pointer"
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            ))}
            {errors.files && (
              <small className="text-danger">{errors.files}</small>
            )}
          </div>
        </div>
      </div>

      <button className="btn-black" onClick={handleSubmit}>
        {loading ? selectLoader(35) : isEditing ? "Update" : "Submit"}
      </button>
    </div>
  );
};

export default RequestModal;
