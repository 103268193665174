import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import APIServices from '../../../../services/APIServices';
import { hasPermission, PAGE_LIMIT, selectLoader } from '../../../../utils/Helpers';
import SignOffForm from '../../forms/SignOffForm';
import SidebarModal from '../../../../components/SidebarModal';
import { useStore } from '../../../../hooks/useStore';
import ChangeOrderModal from '../../updatedOverviewModals/ChangeOrderModal';
import AddQuoteModal from '../../updatedOverviewModals/AddQuoteModal';
import RequestModal from '../../updatedOverviewModals/RequestModal';

const RequestListTab = ({ projectId, currentTab, onRequestClick, memberPermission, userRole }) => {
  const activeTabRef = useRef('open'); // Create a ref to store activeTab
  const scrollRef = useRef(null);
  const [store, setStore] = useStore();
  const { user, isUser1, socket } = useAuth();
  const [isQouteRequired, setQouteRequired] = useState(false);
  const [changeReqId, setChangeReqId] = useState('');
  const [cache, setCache] = useState({ open: null, close: null });
  // const [projectId, setProjectId] = useState("");
  const [activeTab, setActiveTab] = useState('open');
  const [requests, setRequests] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [hasMore, setHasMore] = useState(true); // To track if more projects exists
  const [modals, setModals] = useState({
    signOffModal: false,
    changeOrderModal: false,
    requestModal: false
  });
  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen
    }));
  };
  // Fetch requests from the backend
  const fetchRequests = async (status = 'open', isloading = true, pageLoading = false, page = 1) => {
    try {
      // if (cache[status] && isloading) {
      //   setRequests(cache[status]);
      //   setLoading(false);
      //   return;
      // }
      setPageLoading(pageLoading);
      setLoading(isloading);
      console.log(projectId);

      const response = await APIServices.get(
        `/project-requests/${projectId}?requestStatus=${status}&page=${page}&limit=${PAGE_LIMIT}`
      ); // Replace with your actual API endpoint
      const data = response.data || [];
      setCache((prev) => ({ ...prev, [status]: data }));
      setRequests((prev) => (page === 1 ? data : [...prev, ...data]));
      setHasMore(data.length == 10);
      setCurrentPage(page);
    } catch (err) {
      console.error(err);
      setError('Failed to load requests');
    } finally {
      setLoading(false);
      setPageLoading(false);
    }
  };
  // Adjust scroll position after new data is loaded
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer && currentPage > 1) {
      const newScrollHeight = scrollContainer.scrollHeight;
      const previousScrollHeight = scrollContainer.dataset.previousScrollHeight;
      if (previousScrollHeight) {
        scrollContainer.scrollTop = scrollContainer.scrollTop + (newScrollHeight - previousScrollHeight);
      }
      scrollContainer.dataset.previousScrollHeight = newScrollHeight;
    }
  }, [requests]);

  useEffect(() => {
    if (projectId && currentTab == 2) {
      fetchRequests();
    }
  }, [projectId, currentTab]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        // Check if the scrollTop is at the top of the div
        if (
          Math.abs(scrollRef.current.scrollHeight - scrollRef.current.scrollTop - scrollRef.current.clientHeight) <=
            2 &&
          projectId &&
          !loading &&
          hasMore
        ) {
          fetchRequests(activeTab, true, false, currentPage + 1); // Fetch the next page
        }
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, loading]);

  useEffect(() => {
    if (activeTab && projectId) {
      fetchRequests(activeTab, false, true, 1);
    }
  }, [activeTab]);

  const getRequestHeading = (requestType = '') => {
    switch (requestType) {
      case 'sign_off':
        return 'Milestone Signoff:';
      case 'change_order':
        return 'Change Order Request';
      case 'change_request':
        return 'Request';
      default:
        return '';
    }
  };

  const getRequestBadge = (requestType = '') => {
    switch (requestType) {
      case 'sign_off':
        return 'Sign-Off';
      case 'change_order':
        return 'Change Order';
      case 'change_request':
        return 'Request';
      default:
        return '';
    }
  };

  const getCardColor = (requestType = '') => {
    switch (requestType) {
      case 'sign_off':
        return 'lightbluebg-card';
      case 'change_order':
        return 'purplebg-card';
      case 'change_request':
        return 'bluebg-card';
      default:
        return '';
    }
  };

  const updateandCloseModal = (modalType) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: false
    }));
    fetchRequests();
  };

  useEffect(() => {
    activeTabRef.current = activeTab; // Keep the ref updated with the latest activeTab
  }, [activeTab]);

  const handleRequestEvent = (eventName, activity) => {
    if (activeTabRef.current && projectId) {
      fetchRequests(activeTabRef.current, false);
    }
    console.log(eventName, activity);
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on('ProjectRequestCreated', (activity) => handleRequestEvent('ProjectRequestCreated', activity));
      socket.on('ProjectRequestUpdated', (activity) => handleRequestEvent('ProjectRequestUpdated', activity));
      socket.on('ProjectRequestClosed', (activity) => handleRequestEvent('ProjectRequestClosed', activity));
    }
  }, [socket]);

  return (
    <div className="card-body-content request-content" ref={scrollRef}>
      {/* Tab Navigation */}
      <div className="bg-white btn-tab-group">
        <button
          className={`btn btn-custom ${activeTab === 'open' ? 'btn-dark' : 'btn-light'}`}
          onClick={() => setActiveTab('open')}>
          Open
        </button>
        <button
          className={`btn btn-custom  ${activeTab === 'close' ? 'btn-dark' : 'btn-light'}`}
          onClick={() => setActiveTab('close')}>
          Closed
        </button>
      </div>

      {/* Display loading, error or requests */}

      {error && <p className="error-message">{error}</p>}
      {!pageLoading && !requests.length && <p>No requests found.</p>}
      {!pageLoading ? (
        <div>
          {requests?.map((request) => (
            <div
              key={request._id}
              className={`add_landing_card ${request.unreadMessage > 0 && 'unread-card'} ${getCardColor(
                request.requestType
              )}`}
              onClick={() => onRequestClick(request._id, request.parentRequestId)}>
              <div className="card_header">
                {request.requestType == 'sign_off' ? (
                  <h4>
                    {getRequestHeading(request.requestType)} {request.milestoneId.name}
                  </h4>
                ) : (
                  <h4>{request.title}</h4>
                )}
                <div className="head_right_btn">
                  <a className="card_btn">
                    {getRequestBadge(request.requestType)}
                    {/* // request.requestType == "change_request" ? "request" : request.requestType.replace("_", " ")} */}
                  </a>
                  {(request.status == 'close' || request.status == 'closed') && (
                    <a className={`card_btn button-signed`}>
                      {request.requestType == 'change_request' || request.requestType == 'change_order'
                        ? request.isCanceled
                          ? 'Cancelled'
                          : 'Closed'
                        : 'Signed'}
                    </a>
                  )}
                  {request.unreadMessage > 0 && <a className={`card_btn tag-unread`}>{request.unreadMessage}</a>}
                </div>
              </div>
              <div className="card_cantent">
                <p>{request.description}</p>
                <span className="w-100">
                  Requested by {request?.createdBy?.fullName}, {new Date(request.createdDate).toLocaleDateString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>{selectLoader(50)}</div>
      )}
      {loading && <div>{selectLoader(50)}</div>}
      {/* Bottom Actions */}
      <div className="user-chatBox-cover">
        <ul className="chat-btn-list">
          {hasPermission(userRole, 'send_signoff_requests', memberPermission) && (
            <li>
              <a className="cursor_pointer" onClick={() => toggleModal('signOffModal', true)}>
                + Sign-Off
              </a>
            </li>
          )}
          {hasPermission(userRole, 'change_order', memberPermission) && (
            <li>
              <a className="cursor_pointer" onClick={() => toggleModal('changeOrderModal', true)}>
                + Change Order
              </a>
            </li>
          )}
          {hasPermission(userRole, 'change_request', memberPermission) && (
            <li>
              <a className="cursor_pointer" onClick={() => toggleModal('requestModal', true)}>
                + Request
              </a>
            </li>
          )}
        </ul>
      </div>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.addQuoteModal}
        onHide={() => toggleModal('addQuoteModal', false)}
        headerTitle={'Quote'}>
        <AddQuoteModal
          projectId={projectId}
          changeReqId={changeReqId}
          closeModal={() => {
            updateandCloseModal('addQuoteModal');
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.changeOrderModal}
        onHide={() => toggleModal('changeOrderModal', false)}
        headerTitle={'Change Order'}>
        <ChangeOrderModal
          projectId={projectId}
          setChangeReqId={setChangeReqId}
          setQouteRequired={setQouteRequired}
          closeModal={() => {
            if (isQouteRequired == true) {
              setModals((prevState) => ({
                ...prevState,
                addQuoteModal: true,
                changeOrderModal: false
              }));
            } else {
              updateandCloseModal('changeOrderModal');
            }
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.requestModal}
        onHide={() => toggleModal('requestModal', false)}
        headerTitle={'New Request'}>
        <RequestModal
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal('requestModal');
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.signOffModal}
        onHide={() => toggleModal('signOffModal', false)}
        headerTitle={'Sign-off Request'}>
        <SignOffForm
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal('signOffModal');
          }}
        />
      </SidebarModal>
    </div>
  );
};

export default RequestListTab;
