import React from "react";
import "../../../assets/css/welcome.css";
import loginLogo from "../../../assets/img/login-logo2.svg";
import logoHover from "../../../assets/img/login-logohover.svg";
const SignUpHeader = ({userName}) => {
  return (
    <div className="welcome-page">
      <div className="header-top-sec">
      <div className="container">
      <div className="header-top">
        <div className="login-logo">
          <a className="happyLogo-cover" href="index.html">
            <img
              src={loginLogo}
              alt={loginLogo}
              className="happy-logo"
            />
            <img
              src={logoHover}
              alt={logoHover}
              className="happy-logo2"
            />
             
          </a>
          <p class="login-box-msg">Happy Ending</p>
        </div>
        <p className="user-name">Hi {userName}, Welcome</p>
      </div>
      </div>
      </div>
    
    
    </div>
  );
};

export default SignUpHeader;
