import React, { useEffect, useState } from "react";
import feedbackReview from "../../../assets/img/starbg.svg";
import { useStore } from "../../../hooks/useStore";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import { selectLoader } from "../../../utils/Helpers";

const FeedbackModal = ({ requestId, milestoneId, closeModal }) => {
  const [store, setStore] = useStore();
  const [projectId, setProjectId] = useState("");
  const [feedback, setFeedback] = useState(""); // For feedback text
  const [rating, setRating] = useState(0); // For star rating
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (store.projectId) {
      setProjectId(store.projectId);
    }
  }, [store.projectId]);

  const handleStarClick = (index) => {
    setRating(index + 1); // Stars are 1-indexed
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (rating === 0) {
      appToast(
        "Please provide a rating before submitting.",
        TOAST_TYPE.WARNING
      );
      return;
    }
    setLoading(true);
    // Construct the payload object
    const payload = {
      milestoneId: milestoneId,
      projectId: projectId,
      requestId: requestId,
      rating: rating.toString(), // Convert rating to a string as per the example
      description: feedback,
    };

    // Simulate submission
    console.log("Payload submitted:", payload);
    try {
      const response = await APIServices.post(
        "/project-milestone-feedback/give-feedback",
        payload
      );
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        closeModal();
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
      // Reset form (if necessary)
      setFeedback("");
      setRating(0);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="feedback-body">
      <p>Share Your Insights: Help Us Improve!</p>
      <textarea
        className="form-control"
        placeholder="Write your feedback here"
        value={feedback}
        onChange={handleFeedbackChange}
      ></textarea>
      <ul className="feedback-review">
        {[...Array(5)].map((_, index) => (
          <li key={index} onClick={() => handleStarClick(index)}>
            <img
              src={feedbackReview}
              alt={`Star ${index + 1}`}
              className={`feedbackReview-img ${
                index < rating ? "" : "opacity50"
              }`}
            />
          </li>
        ))}
      </ul>
      <button className="btn-black" type="submit" onClick={handleSubmit}>
        {loading ? selectLoader(35) : "Submit"}
      </button>
    </div>
  );
};

export default FeedbackModal;
