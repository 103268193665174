import React from 'react';
import { Modal } from 'react-bootstrap';
import { selectLoader } from '../../../../utils/Helpers';

const KbAddProjectModal = ({ show, onHide, handleAction, modalContent, loading }) => {
  return (
    <div>
      <Modal show={show} onHide={onHide} dialogClassName="" centered className="deletModal ">
        <Modal.Header closeButton={true}>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <button onClick={handleAction} className="delet-btn" style={{ padding: !loading ? '' : '0' }}>
            {!loading ? "Create" : selectLoader(35)}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default KbAddProjectModal;
