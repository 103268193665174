import React, { Suspense } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import { useAuth } from "./hooks/useAuth";
import Login from "./pages/auth/Login";
import AuthLayout from "./layouts/AuthLayout";
import ComingSoon from "./pages/ComingSoon";
import VerificationStatus from "./pages/auth/VerificationStatus";
import Verification from "./pages/auth/Verification";
import { hasPermission, selectLoader } from "./utils/Helpers";
import ProfileCompletion from "./pages/auth/ProfileCompletion";
import Home from "./pages/projects/Home";
import ClientDirectory from "./pages/projects/ClientDirectory";
import MyTeam from "./pages/projects/MyTeam";
import ProjectOverview from "./pages/projects/ProjectOverview";
import AcceptInvite from "./pages/auth/AcceptInvite";
import OrganizationUsers from "./pages/projects/OrganizationUsers";
import UpdatedOverview from "./pages/projects/UpdatedOverview";
import Settings from "./pages/projects/settings/Settings";
import TemplatesLibrary from "./pages/projects/TemplatesLibrary";
import Analytics from "./pages/projects/Analytics";
import Support from "./pages/projects/Support";
import TermsConditions from "./components/TermsConditions";
import Services from "./components/Services";
import Features from "./pages/auth/welcome/Features";
import Video from "./pages/auth/welcome/Video";
import Questions from "./pages/auth/welcome/Questions";
import SubPlan from "./pages/auth/welcome/SubPlan";
import ProjectBoard from "pages/projects/kanban/ProjectBoard";
import ProjectBoards from "pages/projects/projectBoards/ProjectBoards";

const RequireAuth = ({ children }) => {
  const { token } = useAuth(AuthContext);
  if (!token) {
    return <Navigate to={'/login'} />;
  }
  return (
    <>
      <Suspense fallback={selectLoader(50)}>
        <Outlet />
      </Suspense>
    </>
  );
};

// New protected route for isUser1
const RequireUser1 = ({ children }) => {
  const { isUser1 } = useAuth(AuthContext);

  if (!isUser1) {
    return <Navigate to={'/projects'} />;
  }
  return (
    <Suspense fallback={selectLoader(50)}>
      <Outlet />
    </Suspense>
  );
};

const RoutesComponent = () => {
  const { token, userRole , isUser1} = useAuth(AuthContext);
  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
        <Route
            path="/"
            element={
              token ? <Navigate to="/projects" /> : <Navigate to="/login" />
            }
          />
          {/* <Route path="/" element={<Navigate to="/project-boards" />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/complete-profile" element={<ProfileCompletion />} />
          <Route path="/accept-invitation/:token" element={<AcceptInvite />} />
          <Route path="/verification-status" element={<VerificationStatus />} />
        </Route>
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/project-boards" element={<ProjectBoards />} />
        <Route path="*" element={<Navigate to={token ? "/projects" : "/login"} />} />
        <Route path="/" element={<RequireAuth />} />
        <Route path="*" element={<Navigate to={token ? "/projects" : "/login"} />} />
        <Route path="/privacy-policy" element={<TermsConditions />} />
        <Route path="/features" element={<Features />} />
        <Route path="/video" element={<Video />} />
        <Route path="/Questions" element={<Questions />} />
        <Route path="/plans" element={<SubPlan />} />

        <Route path="/services" element={<Services />} />
        {/* <Route path="/services" element={<RequireAuth />} /> */}
        <Route path="/" element={<RequireAuth />}>
          <Route path="/projects" element={<Home />} />

          <Route path="/project-boards" element={<ProjectBoards />} />
          <Route path="/project-board/:projectId" element={<ProjectBoard />} />
          <Route path="/project-board" element={<ProjectBoard />} />
          <Route path="/myteam" element={<MyTeam />} />
          <Route path="/overview" element={<UpdatedOverview />} />
          <Route path="/overview/:notificationId" element={<UpdatedOverview />} />
          <Route path="/templates" element={<TemplatesLibrary />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/support" element={<Support />} />
          <Route path="/settings" element={<Settings />} />
          <Route element={<RequireUser1 />}>
          {hasPermission(userRole, "create_projects") && <Route path="/create-project" element={<ProjectOverview />} />}
          {isUser1 && <Route path="/update-project/:projectId" element={<ProjectOverview />} />}
            <Route path="/client-directory" element={<ClientDirectory />} />
            <Route path="/client-directory/Client" element={<OrganizationUsers />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
