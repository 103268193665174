import React from 'react';

const sizes = {
  small: '32px'
};
export default function Avatar({ children, size = 'small', style }) {
  return (
    <div
      className="rounded-circle d-flex align-items-center justify-content-center"
      style={{ width: sizes[size], height: sizes[size], minWidth: sizes[size], ...style }}>
      {children}
    </div>
  );
}
