import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginLogo from '../../assets/img/login-logo2.svg';
import leftArrow from '../../assets/img/left-arrow.svg';
import logoHover from '../../assets/img/login-logohover.svg';
import {
  emailLimit,
  formFields,
  getCookies,
  getformattedPhoneNumber,
  getPlainPhoneNumber,
  orgNameLimit,
  selectLoader,
  updateUserDetails,
  userNameLimit,
  validateForm
} from '../../utils/Helpers';
import '../../assets/css/signup.css';
import PhoneNumberInput from '../../components/PhoneInput';
import { ProfileCompletionStrings } from './strings/authStrings';
import { useAuth } from '../../contexts/AuthContext';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ProfileCompletion = () => {
  const navigate = useNavigate();
  const { user, fetchUserInfo, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({ ...formFields });
  const [errors, setErrors] = useState({});
  const [verificationType, setVerificationType] = useState('');

  useEffect(() => {
    const savedVerificationType = cookies.get('userType');
    console.log(user);

    if (savedVerificationType) {
      setVerificationType(savedVerificationType);
      if (savedVerificationType === 'email') {
        const savedEmail = cookies.get('userEmail');

        if (savedEmail) {
          setFormValues({
            ...formValues,
            email: savedEmail,
            isTotorialVisited: false
          });
        }
      } else if (savedVerificationType === 'phone') {
        const savedPhoneDetails = cookies.get('userPhone');
        if (savedPhoneDetails) {
          setFormValues({
            ...formValues,
            countryDialCode: savedPhoneDetails.countryDialCode,
            countryCode: savedPhoneDetails.countryCode,
            phoneNumber: savedPhoneDetails.phoneNumber,
            isTotorialVisited: false
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (user) {
      setFormValues({
        ...formValues,
        fullName: user?.fullName,
        email: user?.email,
        phoneNumber: getformattedPhoneNumber(user?.phoneNumber),
        companyName: user?.companyId?.name,
        countryDialCode: user?.countryDialCode || '+1',
        countryCode: user?.countryCode
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormValues({
      ...formValues,
      [name]: type === 'checkbox' ? checked : value
    });

    // Clear the error message for the current field being changed
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const handlePhoneChange = (val, countryDialCode, countryCode) => {
    setFormValues({
      ...formValues,
      phoneNumber: val,
      countryDialCode: `${countryDialCode}`,
      countryCode: countryCode || 'US'
    });

    // Clear the error message for the current field being changed
    setErrors({
      ...errors,
      phoneNumber: ''
    });
  };

  const validate = () => {
    let tempErrors = validateForm(formValues, true);
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      let plainNumber = getPlainPhoneNumber(formValues.phoneNumber);
      const updatedFormValues = {
        ...formValues,
        phoneNumber: plainNumber
      };
      console.log(updatedFormValues);

      let response = await updateUserDetails(updatedFormValues, setError, setLoading);
      if (response) {
        fetchUserInfo();
        navigate('/projects');
      }
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="form_right signup_form">
      <a
        onClick={() => {
          logout();
          navigate('/');
        }}
        className="cursor_pointer">
        <img src={leftArrow} alt="comp1" className="left-arrow logout_button" />
      </a>
      <div className="login-box">
        <div className="login-logo">
          <a className="happyLogo-cover">
            <img src={loginLogo} alt={ProfileCompletionStrings.happyEnding} className="happy-logo" />
            <img src={logoHover} alt={ProfileCompletionStrings.happyEnding} className="happy-logo2" />
          </a>
        </div>
        <div className="card">
          <div className="card-body login-card-body login2-card-body">
            <p className="login-box-msg">{ProfileCompletionStrings.happyEnding}</p>
            <p className="login-box-smalltext">{ProfileCompletionStrings.completeYourProfile}</p>

            <form onSubmit={handleSubmit} id="registration-form">
              <div className="registration-form-cover">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group mb-3">
                      <input
                        maxLength={userNameLimit}
                        type="text"
                        className={`form-control  ${errors.fullName ? 'form-invalid' : ''}`}
                        placeholder={ProfileCompletionStrings.fullNamePlaceholder}
                        name="fullName"
                        value={formValues.fullName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      {errors.fullName && <span className="error-message">{errors.fullName}</span>}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group mb-3">
                      <input
                        maxLength={emailLimit}
                        disabled={verificationType == 'email' || false}
                        type="text"
                        className={`form-control ${verificationType == 'email' ? 'input-desable' : ''}  ${
                          errors.email ? 'form-invalid' : ''
                        }`}
                        name="email"
                        placeholder={ProfileCompletionStrings.emailPlaceholder}
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group mb-3">
                      <PhoneNumberInput
                        isDisable={verificationType == 'phone' || false}
                        value={formValues.phoneNumber}
                        onPhoneChange={(val) => handlePhoneChange(val, formValues.countryDialCode)}
                        countryDialCode={formValues.countryDialCode}
                        countryName={formValues.countryCode}
                        onCountryChange={(country) => {
                          setFormValues({
                            ...formValues,
                            countryDialCode: country.dialCode,
                            countryCode: country.value
                          });
                          handlePhoneChange(formValues.phoneNumber, country.dialCode, country.value);
                        }}
                        errors={errors.phoneNumber}
                      />
                      {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group mb-3">
                      <input
                        maxLength={orgNameLimit}
                        type="text"
                        className={`form-control ${errors.companyName ? 'form-invalid' : ''}`}
                        placeholder={ProfileCompletionStrings.companyNamePlaceholder}
                        name="companyName"
                        value={formValues.companyName}
                        onChange={handleChange}
                      />
                      {errors.companyName && <span className="error-message">{errors.companyName}</span>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-black btn-block">
                      {!loading ? ProfileCompletionStrings.goToHome : selectLoader(35)}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompletion;
