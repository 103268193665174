import React from 'react';
import Select from 'react-select';
import './style.module.css';

export default function Dropdown({ options, value, onChange, disabled, width }) {
  return (
    <Select
      isClearable={false}
      isSearchable={false}
      options={options}
      value={typeof value === 'string' ? options.find((v) => v.value === value) : value}
      onChange={onChange}
      isDisabled={disabled}
      placeholder="Select"
      className="board-select-container"
      classNamePrefix="board-select"
      styles={{
        container: (base) => ({
          ...base,
          minWidth: width
        })
      }}
    />
  );
}
