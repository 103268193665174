import React, { useEffect, useRef } from 'react';
import DraggableCard from './DraggableCard';

export default function DropTarget({ milestones, handleMilestoneClick, isOver, viewMode , shouldScroll}) {
  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }, 100);
    }
  };

  // Scroll only when a milestone is added
  useEffect(() => {
    if (milestones.length > 0) {
      scrollToBottom();
    }
  }, [milestones.length]); // Trigger scroll when the number of milestones changes
  return (
    <div
      ref={scrollRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 115px - 175px)',
        ...(isOver ? { backgroundColor: '#e0f7fa', padding: '4px', minHeight: '80px', marginTop: '10px' } : {})
      }}>
      {milestones.map((milestone) => (
        <DraggableCard
          milestone={milestone}
          key={milestone._id}
          handleMilestoneClick={() => handleMilestoneClick(milestone)}
          viewMode={viewMode}
        />
      ))}
    </div>
  );
}
