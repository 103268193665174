import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import deleteBtn from '../../../assets/img/delete.svg';
import plusBtn from '../../../assets/img/plus.svg';
import APIServices from '../../../services/APIServices';
import { fetchPermissions, fetchRolesList, fetchTeamMembers } from '../../../utils/fetchData';
import { useAuth } from '../../../contexts/AuthContext';
import UserForm from '../forms/UserForm';
import SidebarModal from '../../../components/SidebarModal';
import appToast, { TOAST_TYPE } from '../../../utils/AppToast';
import { getformattedPhoneNumber, getOrgUserRole, selectLoader } from '../../../utils/Helpers';
import { useParams } from 'react-router-dom';

const TeamTab = ({
  currentTab,
  projectDetails,
  setCurrentTab,
  setCompletedTabs,
  organizationsList,
  isInternalProject
}) => {
  const { projectId: projectIdFromParams } = useParams();
  const { user, inviterId } = useAuth();
  const [permissions, setPermissions] = useState([]);
  const [authorised, setAuthotised] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [teamId, setTeamId] = useState('');
  const [addedUserId, setAddedUserId] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [teamRoles, setTeamRoles] = useState([]);
  const [members, setMembers] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizationId, setOrganizationId] = useState('');
  const [inviteeOptions, setInviteeOptions] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [users, setUsers] = useState([{ userId: '', role: '', permission: '' }]);
  const [client, setClient] = useState('Select');
  const [isTeamUser, setTeamUser] = useState(false);
  const [isResending, setResending] = useState(false);
  const [inviteeDetails, setInviteeDetails] = useState([]);
  const [invitees, setInvitees] = useState([
    {
      name: '',
      email: '',
      phone: '',
      role: '',
      status: ''
    }
  ]);
  const [newInvitee, setNewInvitee] = useState({
    name: '',
    email: '',
    phone: '',
    role: ''
  });

  useEffect(() => {
    if (projectIdFromParams && currentTab == 2) {
      setEditing(true);
      setProjectId(projectIdFromParams);
      fetchTeamDetails(projectIdFromParams);
    }
  }, [projectIdFromParams, currentTab]);

  const fetchTeamDetails = async (projectId) => {
    setPageLoading(true);
    try {
      let response = await APIServices.get(`/project-team/${projectId}?type=team`);
      if (response.projectTeam) {
        let teamMembersList = response?.projectTeam?.myTeam.map((member) => ({
          userId: member?.userId?._id,
          role: member?.role?._id,
          permission: member?.permission?._id
        }));
        let inviteesList = response?.projectTeam?.organizationTeam.map((member) => {
          const currentOrgMember = getOrgUserRole(member?.userId, response?.projectTeam?.organizationId?._id);
          return {
            name: member?.userId?.fullName,
            email: member?.userId?.email,
            phone: member?.userId?.phoneNumber,
            role: member?.role?._id,
            status: currentOrgMember?.status,
            userId: member?.userId?._id,
            countryDialCode: member?.userId?.countryDialCode
          };
        });
        let inviteeDetailsList = response?.projectTeam?.organizationTeam.map((member) => {
          const currentOrgMember = getOrgUserRole(member?.userId, response?.projectTeam?.organizationId?._id);
          console.log(currentOrgMember?.userType?._id);

          return { userId: member?.userId?._id, role: member?.role?._id };
        });
        setUsers(teamMembersList);
        if (inviteesList.length > 0) {
          setInvitees(inviteesList || invitees);
        } else {
          setInvitees([
            {
              name: '',
              email: '',
              phone: '',
              role: '',
              status: ''
            }
          ]);
        }
        // if (inviteeDetailsList.length > 0) {
        setInviteeDetails(inviteeDetailsList || []);
        // }
        setOrganizationId(response?.projectTeam?.organizationId?._id);
        setTeamId(response?.projectTeam?._id);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
      // setDetails(response.projectDetail);
    } catch (error) {
      console.error('Error fetching milestones:', error);
    } finally {
      setPageLoading(false);
    }
  };

  const updateUserList = () => {
    setModalOpen(false);
    if (isTeamUser) {
      fetchTeamMembers(setMembers, setCurrentUser, setLoading, user, true, '', true);
    } else {
      if (!isInternalProject) {
        fetchOrgUsers(organizationId);
      }
    }
  };

  const [userErrors, setUserErrors] = useState([]); // Track user input errors
  const [inviteeErrors, setInviteeErrors] = useState([]); // Track invitee input errors

  const updateInviteeList = () => {
    if (projectIdFromParams) {
      organizationUsers.forEach((user, index) => {
        let selectOption = inviteeOptions.find((option) => option.userId === user.userId._id);
        handleInviteeChange(selectOption, index);
      });
    } else if (addedUserId) {
      let selectOption = inviteeOptions.find((option) => option.userId === addedUserId);
      console.log(selectOption);
    }
  };

  useEffect(() => {
    if (addedUserId !== '' && inviteeOptions) {
      let selectOption = inviteeOptions.find((option) => option.userId === addedUserId);
      if (selectOption) {
        // handleAddUser(selectOption);
        if (!invitees[invitees.length - 1]?.name) {
          handleInviteeChange(selectOption, invitees.length - 1);
        } else {
          handleInviteeChange(selectOption, invitees.length);
        }
        setAddedUserId('');
      }
      console.log(selectOption);
    }
  }, [addedUserId, inviteeOptions]);

  const fetchOrgUsers = async (orgId = '') => {
    try {
      const response = await APIServices.get(`/organizations/${orgId}/users`);

      if (response.users) {
        const usersFromApi = response.users;
        // console.log(usersFromApi);

        // Transform the users to match the format expected by the Select component
        const options = usersFromApi.map((user) => {
          const currentOrgMember = getOrgUserRole(user, orgId);
          // console.log(currentOrgMember);

          return {
            label: user.fullName,
            value: user.fullName,
            email: user.email,
            phone: user.phoneNumber,
            countryDialCode: user.countryDialCode,
            countryCode: user.countryCode,
            role: currentOrgMember.userType.role,
            roleId: currentOrgMember.userType._id,
            userId: user._id,
            status: currentOrgMember.status
          };
        });
        // console.log(options);

        setInviteeOptions(options || []);
        setTimeout(() => {
          updateInviteeList();
        }, 0);
      } else if (response?.data?.message) {
      }
    } catch (error) {
      console.error('Error fetching the teams:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inviteeOptions?.length == 1 && !isEditing) {
      console.log(inviteeOptions[0]);
      handleInviteeChange(inviteeOptions[0], 0, true);
    }
  }, [inviteeOptions]);

  useEffect(() => {
    fetchPermissions(setPermissions);
    fetchRolesList(setRoles, setLoading, setError, true);
    fetchRolesList(setTeamRoles, setLoading, setError);
  }, []);

  useEffect(() => {
    if (user) {
      fetchTeamMembers(setMembers, setCurrentUser, setLoading, user, true, '');
    }
  }, [user]);

  useEffect(() => {
    let isCurrentUserAvailable = users.find((x) => x.userId == currentUser._id);
    let isAddedUserAvailable = members.find((x) => x.value == addedUserId);
    // console.log(isAddedUserAvailable);

    if (currentUser && permissions.length > 0 && !isCurrentUserAvailable) {
      let currentPermission = permissions?.find((x) => x.label == 'Edit');
      setUsers([
        {
          userId: currentUser._id,
          role: currentUser?.userType?._id,
          permission: currentPermission?.value
        }
      ]);
    } else if (isAddedUserAvailable && !users.find((x) => x.userId == addedUserId)) {
      if (!users[users.length - 1].userId || !users[users.length - 1].permission) {
        const updatedUsers = [...users];
        updatedUsers[users.length - 1].userId = isAddedUserAvailable.value;
        updatedUsers[users.length - 1].role = isAddedUserAvailable.role;
        setUsers(updatedUsers);
      } else {
        setUsers([
          ...users,
          {
            userId: isAddedUserAvailable.value,
            role: isAddedUserAvailable.role,
            permission: ''
          }
        ]);
      }
    }
  }, [currentUser, permissions, addedUserId]);

  useEffect(() => {
    if (projectDetails) {
      setOrganizationId(projectDetails.organizationId);
      setProjectId(projectDetails._id);
      if (!isInternalProject) {
        fetchOrgUsers(projectDetails.organizationId);
      }
    }
  }, [projectDetails]);

  useEffect(() => {
    if (organizationId) {
      if (!isInternalProject) {
        fetchOrgUsers(organizationId);
      }
    }
  }, [organizationId]);

  useEffect(() => {
    if (organizationsList) {
      setOrganizations(organizationsList);
    }
  }, [organizationsList]);

  // Filter out already selected members
  const getAvailableMembers = () => {
    const selectedUserIds = users.map((user) => user.userId);
    return members.filter((member) => !selectedUserIds.includes(member.value));
  };

  // Handle user field changes
  const handleUserChange = (value, index, field) => {
    const isEditableUsers = ['66f6d1e2650176d750f9012e', '66f6d1e2650176d750f9012d'];
    const updatedErrors = [...userErrors];
    const updatedUsers = [...users];
    if (isEditableUsers.includes(value)) {
      setAuthotised(false);
      console.log(updatedUsers[index]['permission']);
      if (updatedUsers[index]['permission'] === '66f6d203650176d750f90130') {
        updatedUsers[index]['permission'] = '';
      }
    } else if (field === 'role') {
      setAuthotised(true);
    }

    if (field == 'userId') {
      updatedUsers[index]['role'] = value.role;
      updatedErrors[index] = { ...updatedErrors[index], ['role']: false };
      value = value.value;
    }
    updatedUsers[index][field] = value;
    setUsers(updatedUsers);
    updatedErrors[index] = { ...updatedErrors[index], [field]: false };
    setUserErrors(updatedErrors);
  };

  // Add a new user with validation
  const handleAddUser = (addField = true, checkPermission = false) => {
    let hasError = false;
    if (users.length > 1) {
      const updatedErrors = users.map((user) => {
        let userError = {
          userId: !user.userId,
          role: !user.role
          // permission: !user.permission,
        };
        if (checkPermission) {
          userError.permission = !user.permission;
        }
        if (userError.userId || userError.role || (checkPermission && userError.permission)) {
          hasError = true;
        }
        return userError;
      });
      setUserErrors(updatedErrors);
    } else {
      const updatedErrors = users.map((user) => {
        const userError = {
          userId: false,
          role: false,
          permission: false
        };
        if (userError.userId || userError.role || (checkPermission && userError.permission)) {
          hasError = true;
        }
        return userError;
      });
      setUserErrors(updatedErrors);
    }

    if (!hasError && addField) {
      setUsers([...users, { userId: '', role: '', permission: '' }]);
    }
    return hasError;
  };

  const handleRemoveUser = (index) => {
    let updatedUsers = [];
    let updatedErrors = [];

    if (index === 0) {
      setUsers([{ userId: '', role: '', permission: '' }]);
    } else {
      // Remove the user at the specified index
      updatedUsers = users.filter((_, i) => i !== index);
      updatedErrors = userErrors.filter((_, i) => i !== index);
      setUsers(updatedUsers);
    }

    setUserErrors(updatedErrors);
  };

  // Filter out already selected members
  const getAvailableInvitees = () => {
    const selectedInviteeMails = invitees.map((invitee) => invitee.email);
    return inviteeOptions.filter((member) => !selectedInviteeMails.includes(member.email));
  };

  const handleInviteeChange = (selectedOption, index, isAdmin = false) => {
    const updatedInvitees = [...invitees];

    let adminRole = roles.find((x) => x.value == 'admin');
    console.log(adminRole);

    updatedInvitees[index] = {
      name: selectedOption.value,
      email: selectedOption.email,
      phone: selectedOption.phone,
      role: isAdmin ? adminRole.id : selectedOption.roleId,
      // roleId: selectedOption.roleId,
      status: selectedOption.status,
      countryDialCode: selectedOption.countryDialCode,
      countryCode: selectedOption.countryCode,
      userId: selectedOption.userId
    };
    // Create a shallow copy of inviteeDetails
    const updatedInviteeDetails = [...inviteeDetails];

    // Check if the invitee already exists in inviteeDetails (by index or userId)
    let existingInviteeIndex = updatedInviteeDetails.findIndex((invitee) => invitee.userId === selectedOption.userId);

    if (existingInviteeIndex > -1) {
      // Invitee already exists, so update the role and other properties in inviteeDetails
      updatedInviteeDetails[existingInviteeIndex] = {
        ...updatedInviteeDetails[existingInviteeIndex],
        userId: selectedOption.userId,
        role: selectedOption.roleId
      };
    } else {
      // Add the new invitee since it doesn't exist
      updatedInviteeDetails.push({
        userId: selectedOption.userId,
        role: selectedOption.roleId
      });
    }

    // If the invitee already existed at the same index but now is a different invitee,
    // we should remove the previous invitee from inviteeDetails (if different).
    const prevInvitee = invitees[index];
    if (prevInvitee && prevInvitee.userId !== selectedOption.userId) {
      const prevInviteeIndex = updatedInviteeDetails.findIndex((invitee) => invitee.userId === prevInvitee.userId);
      if (prevInviteeIndex > -1) {
        // Remove the old invitee from inviteeDetails
        updatedInviteeDetails.splice(prevInviteeIndex, 1);
      }
    }

    // Update the inviteeDetails state
    setInviteeDetails(updatedInviteeDetails);
    setInvitees(updatedInvitees);

    // Clear the error for that invitee
    const updatedErrors = [...inviteeErrors];
    updatedErrors[index] = { ...updatedErrors[index], name: false };
    setInviteeErrors(updatedErrors);
  };

  const handleInviteeRoleChange = (roleId, index) => {
    console.log('role', roleId);
    console.log(index);

    const updatedInvitees = [...invitees];
    console.log('invitees', invitees);

    // console.log("selectedOption", selectedOption);

    updatedInvitees[index] = {
      ...updatedInvitees[index],
      role: roleId
    };
    const updatedInviteeDetails = [...inviteeDetails];

    // Check if the invitee already exists in inviteeDetails (by index or userId)
    let existingInviteeIndex = updatedInviteeDetails.findIndex(
      (invitee) => invitee.userId === updatedInvitees[index].userId
    );
    console.log(existingInviteeIndex);
    if (existingInviteeIndex > -1) {
      // Invitee already exists, so update the role and other properties in inviteeDetails
      updatedInviteeDetails[existingInviteeIndex] = {
        ...updatedInviteeDetails[existingInviteeIndex],
        userId: updatedInvitees[index].userId,
        role: roleId
      };
    } else {
      // Add the new invitee since it doesn't exist
      updatedInviteeDetails.push({
        userId: updatedInvitees[index].userId,
        role: roleId
      });
    }
    // Update the inviteeDetails state
    setInviteeDetails(updatedInviteeDetails);
    setInvitees(updatedInvitees);
  };

  const handleAddInvitee = (addField = true) => {
    let hasError = false;
    const updatedErrors = invitees.map((user) => {
      const userError = {
        name: !user.name
        // role: !user.role,
        // permission: !user.permission,
      };
      if (userError.name) {
        hasError = true;
      }
      return userError;
    });
    setInviteeErrors(updatedErrors);

    if (!hasError && addField) {
      setInvitees([...invitees, newInvitee]);
      setNewInvitee({ name: '', email: '', phone: '', role: '' }); // Reset invitee form
    }
    if (isInternalProject) {
      return false;
    }
    return hasError;
  };

  // Remove an invitee
  const handleRemoveInvitee = (index, invitee) => {
    const updatedErrors = inviteeErrors.filter((_, i) => i !== index);
    console.log(inviteeDetails);
    console.log(invitee);

    if (index === 0) {
      setInvitees([
        {
          name: '',
          email: '',
          phone: '',
          role: ''
        }
      ]);
      setInviteeDetails([]);
    } else {
      const updatedInvitees = invitees.filter((_, i) => i !== index);
      const updatedInviteeDetails = inviteeDetails.filter((x) => x.userId != invitee.userId);
      setInviteeDetails(updatedInviteeDetails);
      setInvitees(updatedInvitees);
    }
    setInviteeErrors(updatedErrors);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    const endpoint = isEditing ? `/project-team/${teamId}` : '/project-team';
    e.preventDefault();
    if (!handleAddUser(false, true) && !handleAddInvitee(false)) {
      setLoading(true);
      let payload = {
        projectId,
        organizationId,
        myTeam: users,
        organizationTeam: inviteeDetails,
        projectName: projectDetails.projectName
      };
      try {
        const response = isEditing
          ? await APIServices.put(endpoint, payload)
          : await APIServices.post(endpoint, payload);
        // console.log("response:", response);
        // console.log("payload:", payload);
        if (response.projectId) {
          setTeamId(response._id);
          setCurrentTab(3);
          setCompletedTabs((prev) => ({
            ...prev,
            [3]: true // Enable the next tab
          }));
          setEditing(true);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
      // Add your form submission logic here
    }
  };

  const handleResendInvite = async (member) => {
    setResending(true);
    let payload = {
      fullName: member.name,
      email: member.email,
      phoneNumber: member.phone,
      userRoleName: member.role,
      countryDialCode: member.countryDialCode,
      // countryCode: member.countryCode,
      inviterId: inviterId,
      organizationId,
      isResendInvitation: true
    };
    try {
      const response = await APIServices.post('/user/invite-user', payload);
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setResending(false);
      setLoading(false);
    }
  };

  // Custom MenuList to include the button inside the dropdown
  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {/* {orgloading ? (
            <div className="d-flex justify-content-center py-2">
              {selectLoader(35)}
            </div>
          ) : (
            <> */}
        {props.children}
        {/* </>
          // )} */}

        <div className="p-2">
          <button
            type="button"
            className="btn btn-outline-secondary w-100"
            onClick={(e) => {
              e.stopPropagation();
              setModalOpen(true); // Trigger modal when button is clicked
            }}>
            + Add New User
          </button>
        </div>
      </components.MenuList>
    );
  };

  const ModalContent = (
    <>
      <UserForm
        // initialData={member}
        roleOptions={isTeamUser ? teamRoles : roles}
        closeModal={updateUserList}
        orgId={isTeamUser ? '' : organizationId}
        setAddedUserId={setAddedUserId}
      />
    </>
  );

  return (
    <div className="project-setting-body setting-users">
      {pageLoading ? (
        <div className="nodata-loader">{selectLoader(70)}</div>
      ) : (
        <>
          <h4>My Team</h4>
          <form onSubmit={handleSubmit}>
            {users.map((user, index) => (
              <div key={index} className="form-row row gx-3 align-items-center">
                <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <Select
                      isDisabled={index == 0}
                      className={`form-control select2 ${userErrors[index]?.userId && 'border-danger'} ${
                        index == 0 ? 'input-desable' : ''
                      }`}
                      value={
                        index == 0
                          ? members?.find((x) => x.value === currentUser._id)
                          : members?.find((x) => x.value === user.userId) || null
                      }
                      onChange={(selectedOption) => handleUserChange(selectedOption, index, 'userId')}
                      onMenuOpen={() => setTeamUser(true)}
                      // onMenuClose={() => setTeamUser(false)}
                      components={{ MenuList: CustomMenuList }}
                      options={getAvailableMembers()} // Filtered members
                      placeholder="Select User"
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <Select
                      isDisabled={index == 0}
                      className={`form-control select2 ${userErrors[index]?.role ? 'border-danger' : ''} ${
                        index == 0 ? 'input-desable' : ''
                      }`}
                      value={
                        index == 0
                          ? {
                              value: currentUser?.userType?._id, // Use 'role' as the value
                              label: currentUser?.userType?.name // Use 'name' as the label
                            }
                          : teamRoles.find((x) => x.id === user.role) || null
                      }
                      onChange={(selectedOption) => handleUserChange(selectedOption.id, index, 'role')}
                      options={
                        index == 0
                          ? [
                              {
                                value: currentUser?.userType?.role, // Use 'role' as the value
                                label: currentUser?.userType?.name // Use 'name' as the label
                              }
                            ]
                          : teamRoles
                      }
                      placeholder="Role"
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group">
                    <Select
                      isDisabled={index == 0}
                      className={`form-control select2 ${userErrors[index]?.permission ? 'border-danger' : ''}${
                        index == 0 ? 'input-desable' : ''
                      }`}
                      value={permissions.find((x) => x.value === user.permission) || null}
                      onChange={(selectedOption) => handleUserChange(selectedOption.value, index, 'permission')}
                      options={(authorised && user.role !== "66f6d1e2650176d750f9012e") ? permissions : permissions.filter((x) => x.label !== 'Edit')}
                      placeholder="Permission"
                    />
                  </div>
                </div>
                <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                  <div className="form-group btn-group">
                    {index != 0 && (
                      <button type="button" onClick={() => handleRemoveUser(index)} className="delete-btn">
                        <img src={deleteBtn} alt="comp1" className="deleteBtn" />
                      </button>
                    )}
                    {users.length - 1 == index && (
                      <button type="button" onClick={handleAddUser} className="plus-btn">
                        <img src={plusBtn} alt="comp1" className="plusBtn" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {/* <button type="button" onClick={handleAddUser} className="plus-btn">
          <img src={plusBtn} alt="comp1" className="plusBtn" />
        </button> */}
            {!isInternalProject && (
              <>
                <div className="bodr">&nbsp;</div>
                <h4>Client</h4>
                <div className="form-row row gx-3 mb-4 align-items-center">
                  <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                    <div className="form-group client-group">
                      <Select
                        isDisabled={true}
                        inputProps={{ readOnly: true }}
                        className="form-control"
                        value={organizations.find((option) => option.value == organizationId)}
                        onChange={(selectedOption) => setClient(selectedOption.value)} // Sets the client based on selected value
                        options={organizations} // Passes the client options
                        placeholder="Select Client"
                      />
                    </div>
                  </div>
                </div>
                <h4>Invite User</h4>
                {invitees.map((invitee, index) => (
                  <div key={index} className="form-row invite-user row gx-3 align-items-center">
                    <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <Select
                          className={`project-manager ${inviteeErrors[index]?.name ? 'form-invalid' : ''}`}
                          value={inviteeOptions.find((option) => option.value === invitee.name) || null}
                          onChange={(selectedOption) => handleInviteeChange(selectedOption, index)}
                          onMenuOpen={() => setTeamUser(false)}
                          components={{ MenuList: CustomMenuList }}
                          options={getAvailableInvitees()}
                          placeholder="Select Name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <input
                          type="email"
                          placeholder="Email"
                          value={invitee.email || ''}
                          disabled
                          className={`form-control input-desable ${inviteeErrors[index]?.email ? 'border-danger' : ''}`}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Phone"
                          value={`${invitee?.countryDialCode || ''}${getformattedPhoneNumber(invitee?.phone)}` || ''}
                          disabled
                          className={`form-control input-desable ${inviteeErrors[index]?.phone ? 'border-danger' : ''}`}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <Select
                          className={`project-manager ${inviteeErrors[index]?.role ? 'form-invalid' : ''}`}
                          value={roles.find((option) => option.id === invitee.role) || ''}
                          options={roles}
                          placeholder="Select Role"
                          onChange={(selectedOption) => {
                            handleInviteeRoleChange(selectedOption.id, index);
                          }}
                          // isDisabled={true} // Read-only role
                        />
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group btn-group">
                        {invitee.status == 'invited' ? (
                          <button
                            type="button"
                            onClick={() => {
                              handleResendInvite(invitee);
                            }}
                            className={`resend-btn ${isResending ? 'py-0' : ''}`}
                            id="resendBtn1">
                            {isResending ? selectLoader(15) : 'Resend'}
                          </button>
                        ) : (
                          ''
                        )}
                        <button
                          type="button"
                          onClick={() => handleRemoveInvitee(index, invitee)}
                          className="delete-btn">
                          <img src={deleteBtn} alt="comp1" className="deleteBtn" />
                        </button>

                        {invitees.length - 1 == index && (
                          <button type="button" onClick={handleAddInvitee} className="plus-btn">
                            <img src={plusBtn} alt="comp1" className="plusBtn" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {/* <button type="button" onClick={handleAddInvitee} className="plus-btn">
          <img src={plusBtn} alt="comp1" className="plusBtn" />
        </button> */}
            <div className="form-row row align-items-center">
              <div className="col-md-6 col-lg-6">
                <button type="submit" className="btn-black">
                  {!loading ? (isEditing ? 'Update' : 'Next') : selectLoader(35)}
                </button>
              </div>
            </div>
          </form>
        </>
      )}
      {/* Modal Component */}
      <SidebarModal
        extraClass={'edit-profile-modal client_modal'}
        show={isModalOpen}
        onHide={() => setModalOpen(false)}
        headerTitle={'Add Team Member'}>
        {ModalContent}
      </SidebarModal>
    </div>
  );
};

export default TeamTab;
