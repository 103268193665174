import React, { useEffect, useState } from 'react';
import leftArrow from '../../assets/img/left-arrow.svg';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/analytics.css';
import { useAuth } from 'contexts/AuthContext';
import APIServices from 'services/APIServices';
import { selectLoader } from 'utils/Helpers';

const Analytics = () => {
  const navigate = useNavigate();
  const { user, isUser1 } = useAuth();
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      // console.log(user._id);
      fetchDetails(user._id);
      setUserId(user._id);
    }
  }, [user]);

  const [projectStatus, setProjectStatus] = useState({
    totalProjects: 0,
    statusCounts: { ontime: 0, late: 0, early: 0 }
  });

  const [pendingItems, setPendingItems] = useState({
    signOff: 0,
    changeOrder: 0,
    changeRequest: 0
  });

  const [completions, setCompletions] = useState({
    totalProjects: 0,
    projectCounts: { tillToday: 0 },
    milestoneCounts: { tillToday: 0 }
  });

  const [selectedTimeFrame, setSelectedTimeFrame] = useState('Day');

  const fetchDetails = async (userId) => {
    setLoading(true); // Start loading

    try {
      const [projectStatus, pendingItemsData, projectCompletions] = await Promise.all([
        APIServices.get(`/project/user/status/count/${userId}`),
        APIServices.get(`/project-requests/user/${userId}/project-requests-count`),
        APIServices.get(`/project/user/${userId}/completed-projects-count`)
      ]);

      setProjectStatus(projectStatus || {});
      setPendingItems(pendingItemsData?.counts || {});
      setCompletions(projectCompletions || {});
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Map selected timeframe to API response keys
  const timeFrameMapping = {
    Day: 'tillToday',
    Week: 'tillLastWeek',
    Quarter: 'tillLastQuarter',
    Year: 'tillLastYear'
  };
  const HeaderContent = (
    <>
      <h2>Analytics</h2>
      <div className="head-right">
        <a onClick={() => navigate('/')}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  return (
    <div className="wrapper create_project">
      <div className="main-content">
        <Header content={HeaderContent} />
        {loading ? (
          <div className="nodata-loader">{selectLoader(70)}</div>
        ) : (
          <div className="analytics-sec">
            <div className="container">
              <div className="row-cover row">
                {/* Project Status */}
                <div className="col-lg-6 col-md-6">
                  <div className="cardbg-white">
                    <div className="card-top-header">
                      <label>Project Status</label>
                    </div>
                    <div className="row project-status-row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg total-projects">
                          <p>Total Projects</p>
                          <span>
                            {projectStatus.totalProjects} <sub>(100%)</sub>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg on-time">
                          <p>On-time</p>
                          <span>
                            {projectStatus.statusCounts.ontime}{' '}
                            <sub>
                              (
                              {projectStatus.totalProjects
                                ? ((projectStatus.statusCounts.ontime / projectStatus.totalProjects) * 100).toFixed(1)
                                : 0}
                              %)
                            </sub>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg late">
                          <p>Late</p>
                          <span>
                            {projectStatus.statusCounts.late}{' '}
                            <sub>
                              (
                              {projectStatus.totalProjects
                                ? ((projectStatus.statusCounts.late / projectStatus.totalProjects) * 100).toFixed(1)
                                : 0}
                              %)
                            </sub>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg early">
                          <p>Early</p>
                          <span>
                            {projectStatus.statusCounts.early}{' '}
                            <sub>
                              (
                              {projectStatus.totalProjects
                                ? ((projectStatus.statusCounts.early / projectStatus.totalProjects) * 100).toFixed(1)
                                : 0}
                              %)
                            </sub>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Pending Items */}
                <div className="col-lg-6 col-md-6">
                  <div className="cardbg-white">
                    <div className="card-top-header">
                      <label>Pending Items</label>
                    </div>
                    <div className="row project-status-row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg card-box-bodr">
                          <p>Change Orders</p>
                          <span>{pendingItems.changeOrder || 0}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg card-box-bodr">
                          <p>Requests</p>
                          <span>{pendingItems.changeRequest || 0}</span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="card-boxBg card-box-bodr">
                          <p>Client Sign-off</p>
                          <span>{pendingItems.signOff || 0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Completions */}
                <div className="col-lg-6 col-md-6">
                  <div className="cardbg-white">
                    <div className="card-top-header">
                      <label>Completions</label>
                      <div className="month-cover">
                        {['Day', 'Week', 'Quarter', 'Year'].map((timeframe) => (
                          <button
                            key={timeframe}
                            className={`month-btn ${selectedTimeFrame === timeframe ? 'active' : ''}`}
                            onClick={() => setSelectedTimeFrame(timeframe)}>
                            {timeframe}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="row project-status-row">
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg card-box-bodr">
                          <p>Total Projects Completed</p>
                          <span>{completions.projectCounts[timeFrameMapping[selectedTimeFrame]]}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="card-boxBg card-box-bodr">
                          <p>Total Milestones Completed</p>
                          <span>{completions.milestoneCounts[timeFrameMapping[selectedTimeFrame]]}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
