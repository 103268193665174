import React from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { CLOCK_ICON } from '../../assets/image';
import style from './Tasks.module.css';
import AvatarGroup from '../../shared/AvatarGroup';
import Avatar from '../../shared/Avatar';

export default function Task({ title, isCompleted, handleChecked, dueDate, disabled, assignees }) {
  return (
    <div className={style.task_wrapper}>
      <Form>
        <Form.Check // prettier-ignore
          type={'checkbox'}
          label={title}
          checked={isCompleted || false}
          onChange={handleChecked}
          className={isCompleted ? style.active : ''}
          disabled={disabled}
        />
      </Form>

      <div className="d-flex gap-2">
        {dueDate && (
          <div className={'d-flex align-items-center gap-2'}>
            <Avatar style={{ background: '#E6ECF4' }}>
              <img src={CLOCK_ICON} alt="plus-button-icon" />
            </Avatar>
            <div>{moment(dueDate).format('MMM D')}</div>
          </div>
        )}
        {assignees?.length > 0 ? (
          <AvatarGroup members={assignees} size={34} />
        ) : (
          <>{/*  <img src={plusButtonIcon} alt="plus-button-icon" /> */}</>
        )}
      </div>
    </div>
  );
}
