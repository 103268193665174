import React, { useEffect, useRef, useState } from 'react';
import uploadSvg from '../../../../assets/img/upload.svg';
import sendSvg from '../../../../assets/img/send.svg';
import SidebarModal from '../../../../components/SidebarModal';
import addIcon from '../../../../assets/img/add.svg';
import arrowRightIcon from '../../../../assets/img/bi-arrow-right.svg';
import deleteBtn from '../../../../assets/img/delete.svg';
import feedbackReview from '../../../../assets/img/starbg.svg';
import filePic from '../../../../assets/img/file-picture.svg';
import APIServices from '../../../../services/APIServices';
import SignOffForm from '../../forms/SignOffForm';
import { MentionsInput, Mention } from 'react-mentions';
import {
  calculateDateDifference,
  formatDate,
  formatDateTime,
  hasPermission,
  renderHighlightedMessage,
  selectLoader
} from '../../../../utils/Helpers';
import { useAuth } from '../../../../contexts/AuthContext';
import { useStore } from '../../../../hooks/useStore';
import SignatureModal from '../../updatedOverviewModals/SignatureModal';
import ModalCenter from '../../../../components/ModalCenter';
import FeedbackModal from '../../updatedOverviewModals/FeedbackModal';
import appToast, { TOAST_TYPE } from '../../../../utils/AppToast';
import timerIcon from '../../../../assets/img/timer.svg';
import nickUser2 from '../../../../assets/img/nick-user2.png';
import timerRedIcon from '../../../../assets/img/timerred.svg';
import infoIcon from '../../../../assets/img/info.svg';
import AddQuoteModal from '../../updatedOverviewModals/AddQuoteModal';
import ChangeOrderModal from '../../updatedOverviewModals/ChangeOrderModal';
import ConfirmModal from '../../../../components/ConfirmModal';
import closeIcon from '../../../../assets/img/close.svg';
import fileImg from '../../../../assets/img/file.svg';
import fileIcon from '../../../../assets/img/file-picture.svg';
import RequestModal from '../../updatedOverviewModals/RequestModal';
import { fetchProjectTeamMembers } from '../../../../utils/fetchData';

const RequestDetails = ({
  currentTab,
  selectedReqId,
  requestId,
  parentId,
  setActiveTab,
  setUpdateDetails,
  memberPermission,
  userRole,
  setCurrentTab,
  setSelectedReqId,
  isInternalProject
}) => {
  // console.log(parentId);

  const scrollRef = useRef(null);
  const [store, setStore] = useStore();
  const [userId, setUserId] = useState('');
  const [orderQouteId, setOrderQouteId] = useState(null);
  const [isQouted, setQouted] = useState(false);
  const [isQouteApproval, setQouteApproval] = useState(false);
  const [isQouteRequired, setQouteRequired] = useState(false);
  const [optionId, setOptionId] = useState('');
  const [changeReqId, setChangeReqId] = useState('');
  const [qouteId, setQouteId] = useState('');
  const [isEditable, setIsEditable] = useState(true);
  const [editableRequest, setEditableRequest] = useState(null);
  const [isFeedbackReq, setIsFeedbackReq] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [requestData, setRequestData] = useState(null);
  const [milestoneId, setMilestoneId] = useState('');
  const [requestMsgId, setRequestMsgId] = useState('');
  const [loading, setLoading] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [message, setMessage] = useState('');
  const [messageLoader, setMessageLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const { isUser1, user, socket } = useAuth();
  const [mentionUsers, setMentionUsers] = useState([]);

  const [modals, setModals] = useState({
    convertConfirmModal: false,
    closeConfirmModal: false,
    cancelConfirmModal: false,
    addQuoteModal: false,
    signature: false,
    feedbackModal: false,
    changeOrderModal: false,
    requestModal: false
  });
  // console.log(selectedReqId);

  useEffect(() => {
    if (store.projectId) {
      setProjectId(store.projectId);
    }
  }, [store.projectId]);

  useEffect(() => {
    if (selectedReqId && currentTab == 2) {
      fetchRequestDetails();
    }
  }, [selectedReqId]);

  useEffect(() => {
    if (user) {
      setUserId(user._id);
    }
  }, [user]);

  useEffect(() => {
    if (projectId) {
      fetchTeamMembers();
    }
  }, [projectId]);

  //this functiion will fetch the list of users for the mentions in chat
  const fetchTeamMembers = async () => {
    let members = await fetchProjectTeamMembers(projectId, undefined, '');
    let membersWithoutUser = members?.filter((x) => x.value != user?._id);
    const formattedMembers = membersWithoutUser.map((member) => ({
      id: member.value,
      display: member.label,
      avatar: member.profilePic || nickUser2
    }));
    setMentionUsers(formattedMembers);
    // console.log(formattedMembers);
  };

  // Fetch details of the specific request
  const fetchRequestDetails = async () => {
    try {
      if (!selectedReqId && !parentId && !requestId) {
        appToast('Something went wrong!!');
      }
      setLoading(true);
      const response = await APIServices.get(
        `/project-requests/message/${selectedReqId || parentId || requestId}?page=1&limit=10`
      );
      if (response.data) {
        setRequestData(response.data);
        if (response?.data[0]?.change_order_id) {
          setOrderQouteId(response.data[0].change_order_id);
        }
        let editableRequest = response.data.find((x) => !x.isEdited);
        let approvedQoute = response.data.find((x) => x?.message_type_id?.status == 'approved');
        if (editableRequest) {
          setEditableRequest(editableRequest);
        }
        if (approvedQoute) {
          setQouteId(approvedQoute.message_type_id._id);
        }

        setQouted(response.data[0]?.isQuoted);
      }
    } catch (err) {
      console.error(err);
      // setError("Failed to load request details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editableRequest) {
      console.log(editableRequest);

      // setEditableRequest(editableRequest);
      console.log(editableRequest?.milestoneId?._id);

      setMilestoneId(editableRequest?.milestoneId?._id);
      setRequestMsgId(editableRequest?._id);
      if (editableRequest.approverSign) {
        setIsEditable(false);
      } else {
        setIsEditable(true);
      }
      setIsFeedbackReq(editableRequest?.milestoneId?.feedbackRequest);
    }
  }, [editableRequest]);

  useEffect(() => {
    if (!requestId) return;
    fetchRequestDetails();
  }, [requestId]);

  useEffect(() => {
    return () => {
      setIsFeedbackReq(false);
    };
  }, []);

  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen
    }));
  };

  const updateCloseModal = (modalName) => {
    toggleModal(modalName, false);
    setQouteId('');
    setQouteApproval(false);
    fetchRequestDetails();
    setUpdateDetails();
    if (modalName == 'feedbackModal') {
      setSelectedReqId('');
      setStore({ feedbackTab: 'given' });
      setCurrentTab(4);
    }
    //this is to change the tab of overview tabs
    // setCurrentTab(1);
  };

  // Function to handle file uploads
  const handleFileUpload = (event) => {
    console.log(event, 'clickeddd');

    const files = Array.from(event.target.files);
    setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  };

  // Function to remove an attachment
  const removeAttachment = (index) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  // Function to handle sending the message
  const handleSendMessage = async () => {
    if (!message) {
      return;
    }
    setMessageLoader(true);
    const formData = new FormData();
    // Extract tagged users and replace mentions in message
    const messageUserTags = [];
    const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    let processedMessage = message;
    let match;
    while ((match = regex.exec(message)) !== null) {
      const tagname = match[1];
      const userId = match[2];

      // Add to message_user_tags
      messageUserTags.push({ tagname, userId });

      // Replace the mention in the message with "@tagname"
      processedMessage = processedMessage.replace(match[0], `@${tagname} `);
    }

    attachments.forEach((file) => formData.append('attachments', file));
    formData.append('message', processedMessage);
    formData.append('projectId', projectId);
    // console.log(message, attachments);
    formData.append('message_user_tags', JSON.stringify(messageUserTags));
    if (milestoneId) formData.append('milestoneId', milestoneId);
    if (selectedReqId) {
      formData.append('requestId', selectedReqId);
    } else if (parentId) {
      formData.append('requestId', parentId);
    } else {
      formData.append('requestId', requestId);
    }
    formData.append('message_type', 'text');

    // console.log(message, attachments);

    try {
      const response = await APIServices.post('/project-requests/message', formData, {
        'Content-Type': 'multipart/form-data'
      });
      // console.log(response);
      // Clear state on successful send
      setMessage('');
      setAttachments([]);
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setMessageLoader(false);
    }
  };

  const editQoute = (id) => {
    setQouteId(id);
    toggleModal('addQuoteModal', true);
    //this is to change the tab of overview tabs
    // setCurrentTab(1);
  };

  const handleApproveOpen = (qouteId) => {
    setQouteApproval(true);
    setQouteId(qouteId);
    if (optionId == '' || !optionId) {
      appToast('Please select an option from Qoute first.', TOAST_TYPE.ERROR);
      return;
    }
    toggleModal('signature', true);
  };

  const handleDownloadPdf = async () => {
    try {
      setDownloadLoader(true);
      const response = await APIServices.get(`/project-request-quote/download/${qouteId}`);
      if (response.data.downloadUrl) {
        const { downloadUrl } = response.data;

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank'; // Optional: Open in a new tab
        link.download = 'quote.pdf'; // Optional: Suggest a filename for the download

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up the DOM
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDownloadLoader(false);
      setQouteId('');
    }
  };

  const handleFeedbackModal = () => {
    if (isFeedbackReq) {
      toggleModal('signature', false);
      toggleModal('feedbackModal', true);
    } else {
      updateCloseModal('signature');
    }
  };

  const handleCloseReq = async (isCanceled = true) => {
    try {
      const response = await APIServices.put(`/project-requests/${requestMsgId}/close`, { isCanceled: isCanceled });
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);

        setUpdateDetails();
        fetchRequestDetails();
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      // Handle errors
      appToast(error, TOAST_TYPE.ERROR);
    } finally {
      toggleModal('cancelConfirmModal', false);
      toggleModal('closeConfirmModal', false);
      setLoading(false); // Reset the submitting state
    }
  };

  const handleCovertReq = async () => {
    try {
      const response = await APIServices.put(`/project-requests/convert/${requestMsgId}/changeorder`);
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        toggleModal('convertConfirmModal', false);
        fetchRequestDetails();
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      // Handle errors
      appToast(error, TOAST_TYPE.ERROR);
    } finally {
      setLoading(false); // Reset the submitting state
    }
  };

  const closeConfirmContent = (
    <div>
      <h2 className="mb-3">Are you sure?</h2>
      <h3>You want to close this request? </h3>
    </div>
  );

  const cancelConfirmContent = (
    <div>
      <h2 className="mb-3">Are you sure?</h2>
      <h3>You want to cancel this request? </h3>
    </div>
  );

  const convertConfirmContent = (
    <div>
      <h2 className="mb-3">Are you sure?</h2>
      <h3>Do you want to convert this request? </h3>
    </div>
  );

  // Scroll to the bottom whenever requests change
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [requestData]);

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on('RequestMessage', (activity) => {
        setRequestData((prevActivities) => [...prevActivities, activity]);
        if (activity?.message_type !== 'text') {
          fetchRequestDetails();
        }
        console.log('RequestMessage', activity);
      });
    }
  }, [socket]);

  return (
    <div className="card-body-content" ref={scrollRef}>
      <div className="user-chatBox-cover">
        <ul className="chat-btn-list">
          {isEditable &&
            editableRequest?.requestType == 'sign_off' &&
            hasPermission(userRole, 'send_signoff_requests', memberPermission) && (
              <li>
                <a
                  className="cursor_pointer"
                  style={{ width: 'fitContent', minWidth: '10px' }}
                  onClick={() => toggleModal('signOffModal', true)}>
                  Edit
                </a>
              </li>
            )}

          {isEditable &&
            editableRequest?.requestType == 'sign_off' &&
            hasPermission(userRole, 'approve_sign_off', memberPermission, isInternalProject) && (
              <li>
                <a
                  className="cursor_pointer"
                  style={{ width: 'fitContent', minWidth: '10px' }}
                  onClick={() => toggleModal('signature', true)}>
                  Approve and Sign
                </a>
              </li>
            )}
          {/* have to add the conditional renderings for this part */}
          {editableRequest?.requestType == 'change_order' &&
            hasPermission(userRole, 'change_order', memberPermission) &&
            userId == editableRequest.createdBy?._id &&
            editableRequest.status != 'close' && (
              <>
                <li>
                  <a
                    className="cursor_pointer"
                    style={{ width: 'fitContent', minWidth: '10px' }}
                    onClick={() => toggleModal('changeOrderModal', true)}>
                    Edit
                  </a>
                </li>

                <li>
                  <a
                    className="cursor_pointer"
                    style={{ width: 'fitContent', minWidth: '10px' }}
                    onClick={() => toggleModal('closeConfirmModal', true)}>
                    Close
                  </a>
                </li>
              </>
            )}
          {(editableRequest?.requestType == 'change_order' || editableRequest?.requestType == 'change_request') &&
            userId == editableRequest.createdBy?._id &&
            editableRequest.status != 'close' && (
              <li>
                <a
                  className="cursor_pointer"
                  style={{ width: 'fitContent', minWidth: '10px' }}
                  onClick={() => toggleModal('cancelConfirmModal', true)}>
                  Cancel
                </a>
              </li>
            )}
          {editableRequest?.requestType == 'change_order' &&
          hasPermission(userRole, 'change_order', memberPermission) ? (
            editableRequest.status == 'close' && !editableRequest.isCanceled && editableRequest?.isQuoted ? (
              <li>
                <a
                  className="cursor_pointer"
                  style={{ width: 'fitContent', minWidth: '10px' }}
                  onClick={() => handleDownloadPdf()}>
                  {downloadLoader ? selectLoader(25) : 'Download PDF'}
                </a>
              </li>
            ) : (
              editableRequest.status != 'close' &&
              !isQouted &&
              hasPermission(userRole, 'add_qoute', memberPermission) && (
                <li>
                  <a
                    className="cursor_pointer"
                    style={{ width: 'fitContent', minWidth: '10px' }}
                    onClick={() => toggleModal('addQuoteModal', true)}>
                    + Add Qoute
                  </a>
                </li>
              )
            )
          ) : (
            ''
          )}
          {editableRequest?.requestType == 'change_request' &&
            hasPermission(userRole, 'change_request', memberPermission) &&
            editableRequest.status != 'close' && (
              <>
                {userId == editableRequest.createdBy?._id && (
                  <li>
                    <a
                      className="cursor_pointer"
                      style={{ width: 'fitContent', minWidth: '10px' }}
                      onClick={() => toggleModal('requestModal', true)}>
                      Edit
                    </a>
                  </li>
                )}
                {hasPermission(userRole, 'close_req', memberPermission) && (
                  <li>
                    <a
                      className="cursor_pointer"
                      style={{ width: 'fitContent', minWidth: '10px' }}
                      onClick={() => toggleModal('convertConfirmModal', true)}>
                      Convert to Change Order
                    </a>
                  </li>
                )}
                {(hasPermission(userRole, 'close_req', memberPermission) ||
                  userId == editableRequest.createdBy?._id) && (
                  <>
                    {/* <li>
                      <a
                        className="cursor_pointer"
                        style={{ width: "fitContent", minWidth: "10px" }}
                        onClick={() => toggleModal("cancelConfirmModal", true)}
                      >
                        Cancel
                      </a>
                    </li> */}
                    <li>
                      <a
                        className="cursor_pointer"
                        style={{ width: 'fitContent', minWidth: '10px' }}
                        onClick={() => toggleModal('closeConfirmModal', true)}>
                        Close
                      </a>
                    </li>
                  </>
                )}
              </>
            )}
        </ul>
        {editableRequest?.status == 'open' && (
          <div className="chat-msg-cover">
            <div className="upload-cover">
              {attachments.map((file, index) => (
                <div className="upload-box" key={index}>
                  <img
                    src={closeIcon}
                    className="closeicon"
                    onClick={() => removeAttachment(index)}
                    alt="Remove attachment"
                  />
                  <img
                    src={file.type.startsWith('image/') ? URL.createObjectURL(file) : fileImg}
                    className="fileimg"
                    alt={file.name}
                  />
                  <div className="upload-text">
                    <p>{file.name}</p>
                    <span>{(file.size / 1024).toFixed(2)} KB</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="send-msg-text">
              <label htmlFor="fileUpload">
                <img src={uploadSvg} className="upload-icon" alt="Upload" />
              </label>
              <input
                id="fileUpload"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                multiple
                onChange={handleFileUpload}
              />
              {/* Mentions Input */}
              <div style={{ position: 'relative', flex: 1 }}>
                <MentionsInput
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="formControl"
                  placeholder="Type your message..."
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevent default Enter key behavior
                      handleSendMessage();
                    }
                  }}>
                  <Mention
                    trigger="@"
                    data={mentionUsers}
                    // style={{
                    //   backgroundColor: "#d8f3dc",
                    // }}
                    displayTransform={(id, display) => `@${display} `}
                    renderSuggestion={(suggestion) => (
                      <>
                        <img src={suggestion.avatar} alt="nickUser2" className="nickUser" />
                        <p>{suggestion.display}</p>
                      </>
                    )}
                  />
                </MentionsInput>
              </div>
              <div className="send-msg-cover" onClick={handleSendMessage}>
                {messageLoader ? selectLoader(15) : <img src={sendSvg} alt="Send" />}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="request_details_page request_details_page_main">
        {loading ? (
          <div>{selectLoader(50)}</div>
        ) : (
          requestData &&
          requestData.length > 0 && (
            <>
              {requestData.map((request) => (
                <>
                  <div>
                    {(request?.message_type == 'text' || request?.message_type == 'close') && (
                      <div
                        className={`wire-frame_design   ${
                          userId == request.createdBy?._id ? 'text-bubble-me' : 'text-bubble-you'
                        }`}
                        key={request._id}>
                        <div className="request_header_inner ">
                          <p
                            className={`${request?.message_type == 'close' ? 'lightblue-text' : ''}`}
                            dangerouslySetInnerHTML={{
                              __html: renderHighlightedMessage(request)
                            }}>
                            {/* Hey <b className="lightblue-text">@Ravi,</b> */}
                            {/* {request.message} */}
                          </p>
                        </div>
                        <div className="file_uploaded_inner">
                          {request.attachments.length > 0 &&
                            request.attachments.map((file, index) => (
                              <div className="file_item" key={index}>
                                <a href={file.url} target="_blank" className="attachment-tag">
                                  <img src={fileIcon} />
                                  <div className="file_name">
                                    <h6>{file.name}</h6>
                                    <span className="file_size">{file?.size}</span>
                                  </div>
                                </a>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                    {request.requestType == 'sign_off' && (
                      <div className={`wire-frame_design  ${request.isEdited ? 'opacity50' : ''}`} key={request._id}>
                        <div className="request_header_inner">
                          <label>
                            <b className="darkblue-text">Sign-off Request!</b> Milestone: {request?.milestoneId?.name}
                          </label>
                          <ul className="">
                            <li>
                              {' '}
                              <p>
                                Milestone status is: <b className="text-green">{request?.signoffStatus?.name} </b>
                              </p>
                            </li>
                            {request?.description?.length > 0 && (
                              <li>
                                {' '}
                                <p>{request.description}</p>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div className="file_uploaded_inner">
                          {request.attachments
                            ? request.attachments.map((attachment) => {
                                return (
                                  <div className="file_item" key={attachment._id}>
                                    <a href={attachment.url} target="_blank" className="attachment-tag">
                                      <img src={filePic} />
                                      <div className="file_name">
                                        <h6>{attachment.name}</h6>
                                        <span className="file_size">{attachment.size}</span>
                                      </div>
                                    </a>
                                  </div>
                                );
                              })
                            : ''}
                        </div>
                        <div className="request_footer_inner">
                          {request.approverSign && (
                            <div className="signed-div">
                              <button className={`edit-btn button-signed`}>Signed</button>
                              <img src={request.approverSign.url} />
                            </div>
                          )}
                        </div>
                        {!request.approverSign &&
                        !request.isEdited &&
                        request.requestType == 'sign_off' &&
                        request.signoffDeadline !== 0 ? (
                          <div className="request_time_content">
                            <div
                              className={`timer ${(() => {
                                const signoffDateDifference = calculateDateDifference(request.signoffDeadlineDate);
                                return signoffDateDifference.includes('Overdue') ? 'text-danger' : '';
                              })()}`}>
                              {(() => {
                                const signoffDateDifference = calculateDateDifference(request.signoffDeadlineDate);
                                const isOverdue = signoffDateDifference.includes('Overdue');

                                return (
                                  <>
                                    <img src={timerIcon} className="tiner_icon" />
                                    Due by {formatDate(request.signoffDeadlineDate || new Date(), true)} |{' '}
                                    {signoffDateDifference} {isOverdue ? '' : 'left'}
                                    <img src={infoIcon} className="info_icon" />
                                  </>
                                );
                              })()}
                            </div>
                            <p>
                              To ensure the project stays on track, any sign-off requests that remain unaddressed by the
                              specified deadline will result in an adjustment to the timeline, with the deadline
                              extended by the corresponding number of days.
                            </p>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    {request.requestType == 'change_request' && (
                      <div className={`wire-frame_design  ${request.isEdited ? 'opacity50' : ''}`} key={request._id}>
                        <div className="request_header_inner">
                          <label>
                            <b className="lightbluebg-text">Request!:</b> {request?.title}
                          </label>
                          {request?.description?.length > 0 && (
                            <ul className="">
                              <li>
                                {' '}
                                <p>{request.description}</p>
                              </li>
                            </ul>
                          )}
                        </div>
                        <div className="file_uploaded_inner">
                          {request.attachments
                            ? request.attachments.map((attachment) => {
                                return (
                                  <div className="file_item" key={attachment._id}>
                                    <a href={attachment.url} target="_blank" className="attachment-tag">
                                      <img src={filePic} />
                                      <div className="file_name">
                                        <h6>{attachment.name}</h6>
                                        <span className="file_size">{attachment.size}</span>
                                      </div>
                                    </a>
                                  </div>
                                );
                              })
                            : ''}
                        </div>
                        <div className="request_footer_inner">
                          {request.approverSign && (
                            <div className="signed-div">
                              <button className={`edit-btn button-signed`}>Signed</button>
                              <img src={request.approverSign.url} />
                            </div>
                          )}
                        </div>
                        {!request.approverSign &&
                          !request.isEdited &&
                          request.status != 'close' &&
                          request?.changeRequestDeadline > 0 && (
                            <div className="request_time_content">
                              <div className="timer">
                                {(() => {
                                  const signoffDateDifference = calculateDateDifference(
                                    request.changeRequestDeadlineDate
                                  );
                                  const isOverdue = signoffDateDifference.includes('Overdue');
                                  const changeRequestDateDifference = calculateDateDifference(
                                    request.changeRequestDeadlineDate
                                  );

                                  return (
                                    <>
                                      <img src={isOverdue ? timerRedIcon : timerIcon} className="tiner_icon" />
                                      <span className={`${isOverdue ? 'text-danger' : ''}`}>
                                        Due by {formatDate(request.changeRequestDeadlineDate || new Date(), true)} |{' '}
                                        {changeRequestDateDifference} {isOverdue ? '' : 'left'}
                                      </span>
                                      {/* <img
                                        src={infoIcon}
                                        className="info_icon"
                                      /> */}
                                    </>
                                  );
                                })()}
                              </div>
                              <p>
                                Fulfilling these requests on time helps keep your project moving smoothly and on
                                schedule!
                              </p>
                            </div>
                          )}
                      </div>
                    )}

                    {request.requestType == 'change_order' && (
                      <div className={`wire-frame_design  ${request.isEdited ? 'opacity50' : ''}`} key={request._id}>
                        <div className="request_header_inner">
                          <label>
                            <b className="darkblue-text">Change Order!</b> {request?.title}
                          </label>
                          <p>{request.description}</p>
                        </div>
                        <div className="file_uploaded_inner">
                          {request.attachments
                            ? request.attachments.map((attachment) => {
                                return (
                                  <div className="file_item" key={attachment._id}>
                                    <a href={attachment.url} target="_blank" className="attachment-tag">
                                      <img src={filePic} />
                                      <div className="file_name">
                                        <h6>{attachment.name}</h6>
                                        <span className="file_size">{attachment.size}</span>
                                      </div>
                                    </a>
                                  </div>
                                );
                              })
                            : ''}
                        </div>
                        <div className="request_footer_inner">
                          {request.approverSign && (
                            <div className="signed-div">
                              <button className={`edit-btn button-signed`}>Signed</button>
                              <img src={request.approverSign.url} />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {request.message_type == 'ProjectRequestQuote' && (
                      <div
                        key={request._id}
                        className={`request_details_page request_details_page_main  ${
                          request?.message_type_id?.isEdited ||
                          (request?.message_type_id?.isAccepted && request?.message_type_id?.status === 'quote')
                            ? 'opacity50'
                            : ''
                        }`}>
                        <div className="request_content_cover additional_work_cls">
                          <div className="radio_btn_section">
                            <span className="quote-text">
                              {request.message_type_id?.approverSign && 'Approved'} Quote!
                            </span>
                            {request.message_type_id?.options?.map((option, index) => (
                              <div className="radio_inner_sec" key={option._id}>
                                {!(
                                  request?.message_type_id?.isAccepted && request?.message_type_id?.status === 'quote'
                                ) &&
                                  !request.message_type_id?.approverSign &&
                                  !request?.message_type_id?.isEdited &&
                                  hasPermission(userRole, 'approve_qoute', memberPermission, isInternalProject) && (
                                    <div className="radio_btn">
                                      <input
                                        type="radio"
                                        id={`option${index}`}
                                        name="radio-group"
                                        onClick={() => {
                                          setOptionId(option._id);
                                        }}
                                      />
                                      <label htmlFor={`option${index}`}></label>
                                    </div>
                                  )}
                                <div className="radio_btn_content">
                                  <div className="radio_head">
                                    <h4>{option.title}</h4>
                                    {/* <span>{option.numberOfDays} days</span> */}
                                    <img src={arrowRightIcon} alt="Arrow Icon" />
                                    <small>${option.additionalCost}</small>
                                  </div>
                                  <div className="radio_bottom_cont">
                                    <p>{option.description}</p>
                                    <ul className="line-items-quote">
                                      {option.lineItems.map((item) => (
                                        <li key={item.id}>{item.text}</li>
                                      ))}
                                    </ul>
                                    <p> Additional days: {option.numberOfDays}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="qoute-footer">
                              <div className="request_time_content" style={{ maxWidth: '80%' }}>
                                <span
                                  className={`timer text-left ${
                                    calculateDateDifference(request?.message_type_id?.expireInDate).includes('Overdue')
                                      ? 'text-danger'
                                      : ''
                                  }`}>
                                  {!(
                                    request?.message_type_id?.isAccepted && request?.message_type_id?.status === 'quote'
                                  ) &&
                                  request?.message_type_id?.expireInDate &&
                                  !request.message_type_id?.approverSign
                                    ? calculateDateDifference(request?.message_type_id?.expireInDate).includes(
                                        'Overdue'
                                      )
                                      ? 'This quote has expired, please request a new one.'
                                      : // : `This quotation is valid for ${calculateDateDifference(
                                        //     request?.message_type_id?.expireInDate
                                        //   )}`
                                        `Expires on ${formatDate(
                                          request?.message_type_id?.expireInDate || new Date(),
                                          true
                                        )} | ${calculateDateDifference(request?.message_type_id?.expireInDate)} left`
                                    : ' '}
                                </span>
                                <p>
                                  Reviewing and approving quotes on time keeps your project on track and ensures any
                                  changes you need are accounted for as soon as possible.
                                </p>
                              </div>
                              <div className='qoute-buttons'>
                                {!request?.message_type_id?.isEdited ? (
                                  request.message_type_id.approverSign ? (
                                    <div className="signed-div">
                                      <button className={`edit-btn button-signed`}>Signed</button>
                                      <img src={request.message_type_id.approverSign.url} />
                                    </div>
                                  ) : (
                                    <>
                                      {hasPermission(userRole, 'add_qoute', memberPermission) &&
                                        editableRequest?.status != 'close' && (
                                          <button
                                            className="edit-btn"
                                            onClick={() => editQoute(request?.message_type_id?._id)}>
                                            Edit Quote
                                          </button>
                                        )}
                                      {editableRequest?.status != 'close' &&
                                        hasPermission(
                                          userRole,
                                          'approve_qoute',
                                          memberPermission,
                                          isInternalProject
                                        ) && (
                                          <button
                                            className="edit-btn"
                                            style={{
                                              width: 'fitContent',
                                              minWidth: '10px'
                                            }}
                                            onClick={() => handleApproveOpen(request?.message_type_id?._id)}>
                                            Approve and Sign
                                          </button>
                                        )}
                                    </>
                                  )
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className={`chat-time ${userId == request.createdBy?._id ? 'me' : 'you'}`}>
                      {request?.createdBy?.fullName} {/* {activity.createdBy.email.split("@")[0]} -{" "} */}
                      {formatDateTime(request.createdDate)}
                    </div>
                  </div>
                </>
              ))}
            </>
          )
        )}
      </div>

      {/* //forms for request */}
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.requestModal}
        onHide={() => toggleModal('requestModal', false)}
        headerTitle={'Update Request'}>
        <RequestModal
          projectId={projectId}
          requestId={requestMsgId}
          closeModal={() => {
            updateCloseModal('requestModal');
          }}
        />
      </SidebarModal>

      {/* //forms for change request */}
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.addQuoteModal}
        onHide={() => toggleModal('addQuoteModal', false)}
        headerTitle={'Quote'}>
        <AddQuoteModal
          projectId={projectId}
          changeReqId={orderQouteId || selectedReqId || parentId || requestId}
          qouteId={qouteId}
          closeModal={() => {
            updateCloseModal('addQuoteModal', false);
            setQouteRequired(false);
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.changeOrderModal}
        onHide={() => toggleModal('changeOrderModal', false)}
        headerTitle={'Change Order'}>
        <ChangeOrderModal
          projectId={projectId}
          requestId={requestMsgId}
          setChangeReqId={setChangeReqId}
          setQouteRequired={setQouteRequired}
          closeModal={() => {
            if (isQouteRequired == true) {
              setQouteRequired(false);
              setModals((prevState) => ({
                ...prevState,
                addQuoteModal: true,
                changeOrderModal: false
              }));
            } else {
              updateCloseModal('changeOrderModal', false);
            }
          }}
        />
      </SidebarModal>

      {/* // forms for sign-off flow */}

      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.signOffModal}
        onHide={() => toggleModal('signOffModal', false)}
        headerTitle={'Sign-off Request'}>
        <SignOffForm
          projectId={projectId}
          milestoneId={milestoneId}
          requestId={requestMsgId}
          closeModal={() => {
            updateCloseModal('signOffModal', false);
          }}
        />
      </SidebarModal>

      <ModalCenter
        extraClass={'signature-modal'}
        show={modals.signature}
        headerTitle={'Signature'}
        closeModal={() => {
          toggleModal('signature', false);
        }}>
        <SignatureModal
          requestId={qouteId || requestMsgId || requestId}
          setMilestoneId={setMilestoneId}
          openFeedback={handleFeedbackModal}
          isQouteApproval={isQouteApproval}
          optionId={optionId}
          closeModal={() => {
            updateCloseModal('signature', false);
          }}
        />
      </ModalCenter>

      <ModalCenter
        extraClass={'feedback-modal'}
        show={modals.feedbackModal}
        closeModal={() => {
          toggleModal('feedbackModal', false);
        }}
        headerTitle={'Feedback'}>
        <FeedbackModal
          requestId={selectedReqId || requestId}
          milestoneId={milestoneId}
          closeModal={() => {
            updateCloseModal('feedbackModal', false);
            setActiveTab('requestList');
          }}
        />
      </ModalCenter>
      <ConfirmModal
        show={modals.closeConfirmModal}
        onHide={() => {
          toggleModal('closeConfirmModal', false);
        }}
        confirmationText={closeConfirmContent}
        handleAction={() => handleCloseReq(false)} // Pass the deletion logic here
        loading={loading}
        closeText="Yes"
      />
      <ConfirmModal
        show={modals.cancelConfirmModal}
        onHide={() => {
          toggleModal('cancelConfirmModal', false);
        }}
        confirmationText={cancelConfirmContent}
        handleAction={() => handleCloseReq(true)} // Pass the deletion logic here
        loading={loading}
        closeText="Yes"
      />

      <ConfirmModal
        show={modals.convertConfirmModal}
        onHide={() => {
          toggleModal('convertConfirmModal', false);
        }}
        confirmationText={convertConfirmContent}
        handleAction={handleCovertReq} // Pass the deletion logic here
        loading={loading}
        closeText={'Convert'}
      />
    </div>
  );
};

export default RequestDetails;
