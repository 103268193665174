import React from 'react';
import { Button } from 'react-bootstrap';
import style from './Activity.module.css';
import { AVATAR_IMAGE } from '../../assets/image';
import moment from 'moment';

export default function Comment({ comment, createdDate, userName, handleDelete, handleEdit, profilePic, canEdit }) {
  return (
    <div className={`${style.comment_wrapper} w-100`}  >
      <div className={style.comment_image}>
        <img src={profilePic || AVATAR_IMAGE} alt="avatar" />
      </div>
      <div style={{ flex: 1 , width: "92%"}}>
        <div className={style.commented_name_wrapper}>
          <div className={style.name}>{userName}</div>
          {createdDate && <div className="time-stamp">{moment(createdDate).fromNow()}</div>}
        </div>
        <div className={style.view_comment_wrapper}>
          <div dangerouslySetInnerHTML={{ __html: comment }} />
        </div>
        {canEdit && (
          <div className={style.action_button}>
            <Button variant="link" onClick={handleEdit}>
              Edit
            </Button>
            <span>•</span>
            <Button variant="link" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
