import { colors } from './base';
import style from './style.module.css';

const Chip = ({ label, size, variant = 'default' }) => {
  return (
    <div className={style.chip_wrapper} style={{ background: colors[variant] }}>
      {label}
    </div>
  );
};

export default Chip;
