import { useDrag } from 'react-dnd';
import style from '../Milestone.module.css';
import clockIcon from 'assets/img/clock.svg';
import messageTextIcon from 'assets/img/message-text-square.svg';
import checkIcon from 'assets/img/check-square.svg';
import Chip from 'shared/UI/Chip';
import moment from 'moment-timezone';
import { getPriorityStatus } from 'utils/Helpers';

const DraggableCard = ({ handleMilestoneClick, milestone, viewMode }) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'MILESTONE_CARD',
      item: { _id: milestone?._id },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1
      }),
      canDrag: !viewMode
    }),
    []
  );

  const totalNumberOfTasks = milestone?.tasks?.length;
  const totalNumberOfTasksCompleted = milestone.tasks.filter((value) => value.isCompleted).length;
  const dueOn = milestone.dueDate && moment(milestone.dueDate).format('MMM DD');
  const totalNumberOfComments =
    milestone.activities?.filter((value) => value.activityType === 'milestone_comment')?.length || null;

  return (
    <div className={style.card_wrapper} onClick={handleMilestoneClick} ref={dragRef} style={{ opacity }}>
      <div className={style.title}>{milestone?.name}</div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex" style={{ gap: '10px' }}>
          {milestone?.tasks?.length > 0 && (
            <div className={style.task}>
              <img src={checkIcon} alt="clock-icon" /> {totalNumberOfTasksCompleted}/{totalNumberOfTasks}
            </div>
          )}

          {totalNumberOfComments && (
            <div className={style.task}>
              <img src={messageTextIcon} alt="clock-icon" /> {totalNumberOfComments}
            </div>
          )}

          {dueOn && (
            <div className={style.task}>
              <img src={clockIcon} alt="clock-icon" />
              {dueOn}
            </div>
          )}
        </div>
        <Chip label={milestone?.priority?.name} variant={getPriorityStatus(milestone?.priority?.name)} />
      </div>
    </div>
  );
};

export default DraggableCard;
