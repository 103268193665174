import { useEffect, useState } from 'react';
import moment from 'moment';
import { AVATAR_IMAGE } from '../../assets/image';
import messageTextIcon from 'assets/img/message-text-square2.svg';
import HeadingWithIcon from 'pages/projects/kanban/shared/HeadingWithIcon';
import UserActivity from './UserActivity';
import AddComment from './AddComment';
import style from './Activity.module.css';
import CommentContainer from './CommentContainer';
import { useSocket } from '../../context/SocketContext';
import { useAuth } from 'contexts/AuthContext';

const Activity = ({ milestoneId, defaultActivities, viewMode, projectBoardId }) => {
  const { user } = useAuth();

  const socket = useSocket();
  const [activities, setActivities] = useState(defaultActivities);

  useEffect(() => {
    socket.on('activities', (data) => {
      if (data.milestoneId === milestoneId) {
        setActivities(() => data.activities);
      }
    });
  }, [milestoneId, socket]);

  const handleAddComment = (value) => {
    socket.emit(
      'add-activity',
      {
        message: value,
        activityType: 'milestone_comment',
        milestoneId,
        createdBy: user?._id,
        activityTypeRef: 'ProjectActivity'
      },
      projectBoardId
    );
  };

  return (
    <div className={style.activity_wrapper}>
      <div style={{ marginBottom: '10px' }}>
        <HeadingWithIcon icon={messageTextIcon} heading="Activity" />
      </div>
      <div className={style.message_wrapper}>
        {!viewMode && <AddComment handleAddComment={handleAddComment} profilePic={user?.profilePic} />}
        <div className={style.user_activities_wrapper}>
          {activities?.length > 0 &&
            activities.map((value) => {
              if (value.activityType === 'milestone_activity') {
                return (
                  <div className={style.comment_wrapper} key={value._id}>
                    <div className={style.comment_image}>
                      <img src={value.createdBy?.profilePic || AVATAR_IMAGE} alt="avatar" />
                    </div>
                    <UserActivity
                      name={value.createdBy?.fullName}
                      time={moment(value.createdDate).format('MMMD, YYYY h:mmA')}
                      activity={value.message}
                    />
                  </div>
                );
              } else if (value.activityType === 'milestone_comment') {
                return (
                  <CommentContainer
                    createdBy={value.createdBy}
                    createdDate={value.createdDate}
                    comment={value.message}
                    key={value._id}
                    milestoneId={milestoneId}
                    id={value._id}
                    projectBoardId={projectBoardId}
                    viewMode={viewMode}
                  />
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default Activity;
