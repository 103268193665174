import searchIcon from 'assets/img/search.svg';
import shareIcon from 'assets/img/share-1.svg';
import EditableText from '../shared/EditableText';
import InviteMemberPopup from 'pages/projects/kanban/shared/InviteMember/InviteMemberPopup';
import React, { useEffect, useRef, useState } from 'react';
import style from './ProjectBoardHeading.module.css';
import AvatarGroup from '../shared/AvatarGroup';

const ProjectBoardHeading = ({
  title,
  handleSubmitTitle,
  teams,
  handleSearchChange,
  inviteTeam,
  handleRemoveInvitee,
  searchValue,
  projectBoardId,
  hideEditButton,
  projectBoardCreatedBy,
  viewMode
}) => {
  const [show, setShow] = useState(false);
  const [showMembersPopup, setShowMemberPopup] = useState(false);
  const targetRef = useRef(null);
  const overlayRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (
      overlayRef.current &&
      !overlayRef.current.contains(event.target) &&
      targetRef.current &&
      !targetRef.current.contains(event.target)
    ) {
      setShow(false); // Close the overlay
      setShowMemberPopup(false);
    }
  };

  // Attach event listener for clicks outside
  useEffect(() => {
    if (show || showMembersPopup) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [show, showMembersPopup]);

  return (
    <div className={style.heading_wrapper}>
      <EditableText
        title={title}
        handleSubmitTitle={handleSubmitTitle}
        hideEditButton={hideEditButton}
        defaultEditMode={projectBoardId === 'NEW'}
      />
      <div className={style.search_and_invite_wrapper}>
        <div>
          <div className={style.search_box_wrapper}>
            <input
              type="search"
              id="search-box-tour"
              placeholder="Search tasks..."
              value={searchValue}
              onChange={handleSearchChange}
            />
            {!searchValue && <img src={searchIcon} alt="search-icon" className="search-icon-right" />}
          </div>
        </div>
        <div className="d-flex gap-2" ref={targetRef}>
          <div>
            <AvatarGroup members={teams} handleClick={() => setShowMemberPopup(!showMembersPopup)} />
          </div>

          <div>
            <button className="share-btn" onClick={() => setShow(!show)}>
              <img src={shareIcon} alt="share-1" style={{ marginRight: '8px' }} />
              Share
            </button>
          </div>
        </div>
      </div>
      {(show || showMembersPopup) && (
        <InviteMemberPopup
          target={targetRef}
          overlayRef={overlayRef}
          onClose={() => {
            setShow(false);
            setShowMemberPopup(false);
          }}
          show={show || showMembersPopup}
          members={teams}
          projectBoardId={projectBoardId}
          inviteTeam={inviteTeam}
          handleRemoveInvitee={handleRemoveInvitee}
          projectBoardCreatedBy={projectBoardCreatedBy}
          viewMode={viewMode}
          hideInvitee={showMembersPopup}
          title={showMembersPopup ? 'Manage Project Members' : 'Invite Member'}
          subtitle={
            showMembersPopup ? 'Set permissions for each team member' : 'Share your project collaborate with your team'
          }
        />
      )}
    </div>
  );
};

export default ProjectBoardHeading;
