// SocketContext.js
import { useAuth } from 'contexts/AuthContext';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import boardToast, { TOAST_TYPE } from '../utils/boardToast';

const SocketContext = createContext(null);

export const SocketProvider = ({ children, projectBoardId }) => {
  const { user } = useAuth();
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    let newSocket;
    // Initialize the socket connection
    if (user?._id && projectBoardId) {
      newSocket = io(`${process.env.REACT_APP_BASE_URL}/project-board`, {
        query: { userId: user._id, projectBoardId }
      }); // Replace with your server's URL
      if (newSocket) {
        setSocket(newSocket);
        // Listen for connection status
        newSocket.on('connect', () => {
          console.log('Kanban Socket connected');
        });

        newSocket?.on('error', (data) => {
          console.log('ERROR', data.error);
          boardToast(data.message, TOAST_TYPE.ERROR);
        });
        newSocket.on('connect_error', (err) => {
          console.error('Socket connection failed:', err.message);
          boardToast('Failed to connect to the server. Please try again later.', TOAST_TYPE.ERROR);
        });
      }
    }
    // Cleanup on component unmount
    return () => {
      newSocket?.disconnect();
    };
  }, [user?._id, projectBoardId]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

// Custom hook for easier access to the context
export const useSocket = () => {
  return useContext(SocketContext);
};
