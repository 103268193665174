import React, { useCallback, useEffect, useState } from 'react';
import Header from 'components/Header';
import backIcon from 'assets/img/left-arrow.svg';
import { useNavigate } from 'react-router-dom';
import FOLDER_ICON from 'assets/img/folder.svg';
import CardWithIcon from 'shared/UI/CardWithIcon';
import APIServices from 'services/APIServices';
import appToast, { TOAST_TYPE } from 'utils/AppToast';
import { selectLoader } from 'utils/Helpers';
import { useAuth } from 'contexts/AuthContext';
import './ProjectBoards.css';
import KbAddProjectModal from '../kanban/modals/KbAddProjectModal';

const ProjectBoards = () => {
  const { user } = useAuth();

  const [projects, setProjects] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [modals, setModals] = useState({
    addProject: false
  });
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/'); // This goes back to the previous page
  };

  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen
    }));
  };

  const fetchProjects = useCallback(async () => {
    try {
      const response = await APIServices.get(`/project/user/${user._id}?page=1&limit=50`);
      if (response.projects) {
        const formatData = response.projects.map((value) => ({ name: value.projectName, id: value._id }));
        setProjects(formatData);
      } else if (response?.data?.message) {
        appToast(response?.data?.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setPageLoading(false);
    }
  }, [user._id]);

  useEffect(() => {
    if (user._id) {
      fetchProjects();
    }
  }, [fetchProjects, user._id]);

  const headerContent = () => {
    return (
      <>
        <h2>Projects</h2>
        <div className="head-right">
          {/* <img src={backIcon} alt="back-icon" style={{ cursor: 'pointer' }} onClick={handleBackClick} /> */}
        </div>
      </>
    );
  };

  const handleProjectClick = (id) => {
    navigate(`/project-board/${id}`);
  };

  const addProjectModalContent = <div></div>;

  return (
    <div className="wrapper kanban-board">
      <div className="main-content project-boards" style={{ height: 'unset' }}>
        <Header content={headerContent()} />
      </div>
      <section className="dashboard-sec">
        <div className="container">
          {pageLoading && <div className="nodata-loader">{selectLoader(70)}</div>}
          <div className="d-flex gap-3 flex-wrap">
            {projects.map((value) => (
              <div key={value.id}>
                <CardWithIcon img={FOLDER_ICON} title={value.name} onClick={() => handleProjectClick(value.id)} />
              </div>
            ))}
          </div>
        </div>
        {/* <button
          icon={'fas fa-plus'}
          styles={{ backgroundColor: 'blue', color: 'white' }}
          onClick={() => {
            toggleModal('addProject', true);
          }}
          className={`add-btn `}>
          <i className="fas fa-plus"></i>
        </button> */}
      </section>
      <KbAddProjectModal
        show={modals.addProject}
        onHide={() => {
          toggleModal('addProject', false);
        }}
        modalContent={addProjectModalContent}
        modalHeader={""}
        // handleAction={handleCloseReq} // Pass the deletion logic here
        // loading={loading}
        closeText="Yes"
      />
    </div>
  );
};

export default ProjectBoards;
