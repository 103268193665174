import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { selectLoader } from 'utils/Helpers';
import { useAuth } from 'contexts/AuthContext';
import boardToast, { TOAST_TYPE } from './utils/boardToast';
import { createProjectBoard } from './utils/fetchData';
import Header from 'components/Header';
import ProjectBoardHeader from './ProjectBoardHeader';
import BoardList from './BoardList/BoardList';
import ProjectBoardWrapperPage from './Board';
import EditableText from './shared/EditableText';
import Axios from './utils/Axios';
import './ProjectBoardPage.css';

const ProjectBoard = () => {
  const { projectId } = useParams();
  const { user } = useAuth();
  // State management
  const [boards, setBoards] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [projectDetail, setProjectDetail] = useState();
  const [projects, setProjects] = useState([]);

  // Fetch project boards
  const fetchProjectBoards = useCallback(async () => {
    try {
      setIsLoading(true);
      let response;
      if (projectId) response = await Axios.get(`/project-board/project/${projectId}`);
      else response = await Axios.get(`/project-board`);
      if (response && response.projectBoards?.length > 0) {
        setBoards(response?.projectBoards);
        setSelectedBoardId(response?.projectBoards[0]?._id); // Select the first board by default
        // setProjectDetail({ name: response.projectName });
      } else {
        throw new Error('No project boards found.');
      }
    } catch (error) {
      console.error('Error fetching project boards:', error.message || error);
      boardToast(error.message || 'Failed to fetch project boards.', TOAST_TYPE.ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [projectId]);

  const fetchProjects = useCallback(async () => {
    try {
      const response = await Axios.get(`/project/user/${user._id}?page=1&limit=50`);
      if (response.projects) {
        const formatData = response.projects.map((value) => ({ label: value.projectName, value: value._id }));

        setProjects(formatData);
      } else if (response?.data?.message) {
        boardToast(response?.data?.message, TOAST_TYPE.ERROR);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      boardToast('Error fetching projects:', TOAST_TYPE.ERROR);
    }
  }, [user._id]);

  useEffect(() => {
    if (user._id) {
      fetchProjects();
    }
  }, [fetchProjects, user._id]);
  // Fetch boards on component mount
  useEffect(() => {
    fetchProjectBoards();
  }, [fetchProjectBoards]);

  // Handle board selection
  const handleProjectClick = (boardId) => {
    setSelectedBoardId(boardId);
  };

  // Handle adding a new board
  const handleAddBoard = () => {
    if (boards.some((value) => value._id === 'NEW')) {
      // Do nothing if a new board is already present
    } else {
      setBoards((prevState) => [...prevState, { name: '', _id: 'NEW' }]);
      setSelectedBoardId('NEW');
    }
  };

  const handleChangeTitle = (value) => {
    const update = boards.map((v) => {
      if (v._id === 'NEW') {
        return {
          ...v,
          name: value
        };
      } else return v;
    });
    setBoards(update);
  };

  const handleCloseTitle = () => {
    const update = boards.filter((v) => v._id !== 'NEW');
    setBoards(update);
    setSelectedBoardId(update[update?.length - 1]?._id);
  };

  const handleSubmit = async (title) => {
    try {
      const response = await createProjectBoard(title, projectId);
      if (response) {
        boardToast('Board created Successfully', TOAST_TYPE.SUCCESS);
        const filterBoard = boards.filter((board) => board._id !== 'NEW');
        setBoards((prevState) => [...filterBoard, response]);
        setSelectedBoardId(response._id);
      }
    } catch (error) {
      console.error('Error creating project board:', error);
      boardToast(error.response.data.message || error.message || 'Failed to create project board.', TOAST_TYPE.ERROR);
    }
  };

  // Render
  return (
    <div className="wrapper">
      <div className="main-content project-board" style={{ height: 'unset' }}>
        <Header content={<ProjectBoardHeader projectOptions={projects} />} />
      </div>
      <section className="dashboard-sec">
        {isLoading ? (
          <div className="nodata-loader">{selectLoader(70)}</div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-2">
                <BoardList
                  boards={boards}
                  projectBoardId={selectedBoardId}
                  handleProjectClick={handleProjectClick}
                  handleAddBoard={handleAddBoard}
                />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-10">
                {selectedBoardId === 'NEW' ? (
                  <div className="d-flex justify-content-between align-items-center">
                    <EditableText
                      title={''}
                      handleSubmitTitle={handleSubmit}
                      hideEditButton={false}
                      defaultEditMode={selectedBoardId === 'NEW'}
                      handleChangeTitle={handleChangeTitle}
                      handleCloseTitle={handleCloseTitle}
                    />
                    <div></div>
                  </div>
                ) : (
                  <ProjectBoardWrapperPage projectBoardId={selectedBoardId} setBoards={setBoards} />
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default ProjectBoard;
