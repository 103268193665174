import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSocket } from '../context/SocketContext';
import boardToast, { TOAST_TYPE } from '../utils/boardToast';
import { selectLoader } from 'utils/Helpers';
import { PLUS_ICON } from '../assets/image';
import ChannelWrapper from '../Milestone/ChannelWrapper';
import { fetchProjectBoard, fetchProjectMilestonesByBoard } from '../utils/fetchData';
import ProjectBoardHeading from '../ProjectBoardHeading/ProjectBoardHeading';
import MilestoneModal from '../Milestone/MilestoneModal';
import style from './BoardWrapper.module.css';
import Axios from '../utils/Axios';

const formatTeamData = (teams) => {
  return teams.map((value) => ({
    ...value.userId,
    permission: value.permission?.name || 'View'
  }));
};

export default function BoardWrapper({ projectBoardId, setBoards }) {
  const socket = useSocket();

  const { projectId } = useParams();
  const [milestones, setMilestones] = useState([]);
  const [title, setTitle] = useState('');
  const [teams, setTeams] = useState([]);
  const [filteredMilestones, setFilteredMilestones] = useState([]);
  const [progressBarList, setProgressBarList] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMilestoneSidebar, setShowMilestoneSidebar] = useState(null);
  const [userPermission, setUserPermission] = useState('View');
  const [projectBoardCreatedBy, setProjectBoardCreatedBy] = useState('');
  const [isProjectRelatedBoard, setIsProjectRelatedBoard] = useState(false);

  const fetchProjectBoardData = useCallback(async () => {
    if (projectBoardId) {
      setIsLoading(true);
      const data = await Promise.all([
        fetchProjectBoard(projectBoardId),
        fetchProjectMilestonesByBoard(projectBoardId)
      ]);
      const projectBoard = data[0];
      const milestoneResponse = data[1];
      if (milestoneResponse?.length > 0) {
        setMilestones(milestoneResponse);
        setFilteredMilestones(milestoneResponse);
      } else {
        setMilestones([]);
      }
      if (projectBoard) {
        setUserPermission(projectBoard.permission);
        setTitle(projectBoard.name);
        setProjectBoardCreatedBy(projectBoard.createdBy);
        setTeams(formatTeamData(projectBoard.teams));
        setProgressBarList(projectBoard?.channels);
        setIsProjectRelatedBoard(projectBoard?.isProjectRelatedBoard);
      }
      setIsLoading(false);
    }
  }, [projectBoardId, projectId]);

  useEffect(() => {
    fetchProjectBoardData();
  }, [fetchProjectBoardData]);

  useEffect(() => {
    socket?.on('milestones', (data) => {
      if (data.projectBoardId === projectBoardId) {
        setMilestones(data.data);
        setFilteredMilestones(data.data);
      }
    });

    socket?.on('channels', (data) => {
      if (data.projectBoardId === projectBoardId) {
        setProgressBarList(data.data);
      }
    });
  }, [projectBoardId, socket]);

  const handlePlusList = () => {
    setProgressBarList((prevState) => [...prevState, { name: '' }]);
  };

  const cancelEditChannelName = () => {
    const filteredValue = progressBarList.filter((value) => value.name);
    setProgressBarList(filteredValue);
  };

  const handleDrop = useCallback(
    (mileStoneData, dropItem) => {
      socket.emit('milestoneDragged', {
        projectBoardId,
        source: mileStoneData?._id,
        destination: dropItem?._id,
        destinationName: dropItem?.name
      });
    },
    [projectBoardId, socket]
  );

  const handleSubmitChannel = async (id, value) => {
    try {
      if (id) {
        socket.emit('update-channel', { id, payload: { name: value }, projectBoardId });
      } else {
        socket.emit('add-channel', { name: value, projectBoardId });
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const handleDeleteChannel = async (id, channelName) => {
    socket.emit('delete-channel', { id, projectBoardId, channelName });
  };

  // Save or update board title
  const handleSubmitTitle = async (value) => {
    socket.emit('update-project-board', {
      id: projectBoardId,
      payload: { name: value }
    });
    setBoards((prevState) =>
      prevState.map((state) => {
        if (state._id === projectBoardId) {
          return {
            ...state,
            name: value
          };
        } else return state;
      })
    );
    setTitle(value);
  };

  const handleSearchChange = (event) => {
    const search = event.target.value;
    if (search) {
      const filteredData = milestones.filter((value) => {
        // Check if `name` or `description` contains the search value
        return (
          (value.name && value.name.toLowerCase().includes(search.toLowerCase())) ||
          (value.description && value.description.toLowerCase().includes(search.toLowerCase()))
        );
      });
      setFilteredMilestones(filteredData);
    } else {
      setFilteredMilestones(milestones);
    }
    setSearchValue(event.target.value);
  };

  const inviteTeam = async (data) => {
    setIsLoading(true);
    if (data._id) {
      const response = await Axios.put(`/project-board/add-team/${projectBoardId}`, {
        userId: data._id,
        projectId: projectId
      });
      if (response?.status && response?.status !== 200) {
        boardToast('Something went wrong', TOAST_TYPE.ERROR);
      } else {
        setTeams((prevState) => [...prevState, { ...data, permission: 'View' }]);
        boardToast('User invited successfully.', TOAST_TYPE.SUCCESS);
      }
    } else {
      const response = await Axios.put(`/project-board/invite-team/${projectBoardId}`, {
        email: data.email,
        projectId: projectId
      });
      if (response?.status && response?.status !== 200) {
        boardToast('Something went wrong', TOAST_TYPE.ERROR);
      } else {
        setTeams((prevState) => [...prevState, { ...data, permission: 'View' }]);
        boardToast('User invited successfully.', TOAST_TYPE.SUCCESS);
      }
    }
    setIsLoading(false);
  };

  const handleRemoveInvitee = async (data) => {
    const response = await Axios.put(`/project-board/remove-team/${projectBoardId}`, {
      userId: data._id,
      projectId: projectId
    });
    if (response) {
      const filterTeam = teams.filter((value) => value?._id !== data?._id);
      setTeams(filterTeam);
      boardToast('User removed successfully.', TOAST_TYPE.SUCCESS);
    }
  };

  const handleMilestoneClick = (value) => {
    setShowMilestoneSidebar(value._id);
  };

  if (isLoading) {
    return <div className="nodata-loader">{selectLoader(75)} </div>;
  }

  const hideAddList = userPermission === 'View' || progressBarList?.some((value) => !value?._id);
  return (
    <>
      <div className="mb-3">
        {isLoading && <div className={style.text_editor_loader}>{selectLoader(60)} </div>}
        <ProjectBoardHeading
          title={title}
          handleSubmitTitle={handleSubmitTitle}
          teams={teams}
          searchValue={searchValue}
          handleSearchChange={handleSearchChange}
          projectBoardId={projectBoardId}
          inviteTeam={inviteTeam}
          handleRemoveInvitee={handleRemoveInvitee}
          hideEditButton={userPermission === 'View'}
          projectBoardCreatedBy={projectBoardCreatedBy}
          viewMode={userPermission === 'View'}
        />
      </div>
      <div className={style.kanban_draggable_wrapper}>
        {progressBarList?.map((value, index) => (
          <ChannelWrapper
            name={value.name}
            key={value._id || index}
            id={value?._id}
            handleMilestoneClick={handleMilestoneClick}
            handleDrop={handleDrop}
            milestones={filteredMilestones}
            handleSubmitChannel={(title) => handleSubmitChannel(value._id, title)}
            handleDeleteChannel={() => handleDeleteChannel(value._id, value.name)}
            projectBoardId={projectBoardId}
            cancelEditChannelName={cancelEditChannelName}
            viewMode={userPermission === 'View'}
            progressBarList={progressBarList}
            isProjectRelatedBoard={isProjectRelatedBoard}
          />
        ))}
        {!hideAddList && (
          <div className={style.task_wrapper_heading_new} onClick={handlePlusList}>
            <button className="btn btn-light icon-btn">
              <img src={PLUS_ICON} alt="search-icon" className="search-icon-right" />
            </button>
            <div>Add another list</div>
          </div>
        )}
      </div>
      {showMilestoneSidebar && (
        <MilestoneModal
          milestoneId={showMilestoneSidebar}
          show={!!showMilestoneSidebar}
          onHide={() => setShowMilestoneSidebar(null)}
          members={teams}
          viewMode={userPermission === 'View'}
          projectBoardId={projectBoardId}
        />
      )}
    </>
  );
}
