import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SignUpHeader from "./SignUpHeader";
import APIServices from "../../../services/APIServices";
import { orgNameLimit, selectLoader } from "../../../utils/Helpers";
import { useAuth } from "../../../contexts/AuthContext";

const Questions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const userResponse = location.state?.userResponse || []; // Get user responses from previous step
  const [questions, setQuestions] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [otherInput, setOtherInput] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [responses, setResponses] = useState(userResponse);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (user) {
      // console.log(user._id);

      setUserId(user._id);
    }
  }, [user]);
  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await APIServices.get(`/onboarding/questions`);
      // Filter out the "Features" question (since it's already answered)
      const filteredQuestions = response.filter(
        (item) => item.text !== "What features are important to you?"
      );

      setQuestions(filteredQuestions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleOptionClick = (option) => {
    console.log(option);

    setSelectedOption(option);
    if (option !== "Other") {
      setOtherInput(""); // Reset input if "Other" is not selected
    }
  };

  const handleContinue = async () => {
    const currentQuestion = questions[stepIndex];

    if (!currentQuestion) return;

    const responseObj = {
      questionId: currentQuestion._id,
      selectedOptions: [selectedOption],
    };

    // Store the response
    setResponses((prev) => [...prev, responseObj]);

    if (stepIndex < questions.length - 1) {
      // Move to next question
      setStepIndex(stepIndex + 1);
      setSelectedOption("");
      setOtherInput("");
    } else {
      // All questions answered, submit final responses
      const payload = {
        responses: [...responses, responseObj],
      };

      try {
        console.log(responses);

        const response = await APIServices.put(
          `/onboarding/user-responses/${userId}`,
          payload
        );
        if (response.message) {
          navigate("/plans"); // Redirect to dashboard or next step
        }
      } catch (error) {
        console.error("Error submitting responses:", error);
      }
    }
  };

  return (
    <div className="wrapper welcome-page">
      <div className="main-content">
        <SignUpHeader userName={user.fullName} />
        {pageLoading ? (
          <div className="nodata-loader">{selectLoader(70)}</div>
        ) : (
          <div className="feature-sec question-sec">
            <div className="container">
              <p>{questions[stepIndex]?.text}</p>
              <div className="row align-content-center justify-content-center">
                <div className="col-lg-10">
                  <div className="question-tab-cover">
                    {questions[stepIndex]?.options.map(({ _id, text }) => (
                      <a
                        key={_id}
                        href="#"
                        className={`question-tab ${
                          selectedOption === _id ? "question-tab-active" : ""
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleOptionClick(_id);
                        }}
                      >
                        {text}
                      </a>
                    ))}
                  </div>
                </div>
              </div>

              {/* Show input field only when "Other" is selected */}
              {selectedOption === "Other" && (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group">
                      <input
                        maxLength={orgNameLimit}
                        type="text"
                        className="form-control question-input"
                        placeholder="Please specify"
                        value={otherInput}
                        onChange={(e) => setOtherInput(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <button
                className="btn-black"
                type="button"
                onClick={handleContinue}
                disabled={
                  !selectedOption || (selectedOption === "Other" && !otherInput)
                }
              >
                {stepIndex < questions.length - 1 ? "Continue" : "Finish"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;
