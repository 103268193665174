import React, { useEffect, useRef, useState } from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import { io } from 'socket.io-client'; // Import socket.io-client
import uploadSvg from '../../../assets/img/upload.svg';
import sendSvg from '../../../assets/img/send.svg';
import SidebarModal from '../../../components/SidebarModal';
import rightArrowIcon from '../../../assets/img/right-arrow.svg';
import UpdateProjectForm from '../forms/UpdateProjectForm';
import { useAuth } from '../../../contexts/AuthContext';
import APIServices from '../../../services/APIServices';
import { formatDateTime, hasPermission, selectLoader } from '../../../utils/Helpers';
import SignOffForm from '../forms/SignOffForm';
import ChangeOrderModal from '../updatedOverviewModals/ChangeOrderModal';
import ActivityCard from '../../../components/ActivityCard';
import AddQuoteModal from '../updatedOverviewModals/AddQuoteModal';
import ReqFeedbackModal from '../updatedOverviewModals/ReqFeedbackModal';
import closeIcon from '../../../assets/img/close.svg';
import fileImg from '../../../assets/img/file.svg';
import nickUser2 from '../../../assets/img/nick-user2.png';
import nickUser from '../../../assets/img/nick-user.png';
import uploadPic from '../../../assets/img/upload-img.svg';
import adduploadPic from '../../../assets/img/add-file.svg';
import RequestModal from '../updatedOverviewModals/RequestModal';
import { fetchProjectTeamMembers } from '../../../utils/fetchData';

const ActivityTab = ({ setCurrentTab, currentTab, projectId, setSelectedReqId, memberPermission, userRole , isInternalProject}) => {
  const scrollRef = useRef(null);
  const { user, isUser1, userId, socket } = useAuth();
  // const [projectId, setProjectId] = useState("");
  const [isQouteRequired, setQouteRequired] = useState(false);
  const [changeReqId, setChangeReqId] = useState('');
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [messageLoader, setMessageLoader] = useState(false);
  const [signOffRequired, setSignOffRequired] = useState(false);
  const [updatedMilestoneId, setUpdatedMilestoneId] = useState('');
  const [mentionUsers, setMentionUsers] = useState([]);

  const [modals, setModals] = useState({
    updateModal: false,
    signOffModal: false,
    changeOrderModal: false,
    requestModal: false,
    requestFeedbackModal: false,
    addQuoteModal: false
  });

  useEffect(() => {
    if (currentTab == 1 && projectId && user) {
      fetchActivities();
      setSelectedReqId('');
      fetchTeamMembers();
    }
  }, [currentTab, user, projectId]);

  //this functiion will fetch the list of users for the mentions in chat
  const fetchTeamMembers = async () => {
    let members = await fetchProjectTeamMembers(projectId, undefined, '');
    let membersWithoutUser = members?.filter((x) => x.value != user?._id);
    const formattedMembers = membersWithoutUser?.map((member) => ({
      id: member.value,
      display: member.label,
      avatar: member.profilePic || nickUser2
    }));
    setMentionUsers(formattedMembers);
    // console.log(formattedMembers);
  };

  const fetchActivities = async (page = 1) => {
    try {
      setLoading(true);
      setError(null); // Reset error state
      const response = await APIServices.get(`/project-activity/${projectId}?page=${page}&limit=10`);
      if (response.data.length > 0) {
        // Append new activities at the start of the list
        if (page == 1) {
          setActivities(response.data.reverse());
        } else {
          const scrollContainer = scrollRef.current;
          const previousScrollHeight = scrollContainer?.scrollHeight;
          const previousScrollTop = scrollContainer.scrollTop;

          setActivities((prev) => [...response.data.reverse(), ...prev]);
          setSelectedReqId('');

          setTimeout(() => {
            const newScrollHeight = scrollContainer?.scrollHeight;
            // Adjust scrollTop to maintain the same view position
            scrollContainer.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight);
          }, 0);
        }
        setCurrentPage(page);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen
    }));
  };

  const updateandCloseModal = (modalType) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: false
    }));
    // fetchActivities();
  };

  // Scroll to the bottom whenever activities change
  useEffect(() => {
    if (currentPage == 1) {
      scrollToBottom();
    }
  }, [activities]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      setTimeout(() => {
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }, 100);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        // Check if the scrollTop is at the top of the div
        if (scrollRef.current.scrollTop === 0 && projectId && !loading) {
          fetchActivities(currentPage + 1); // Fetch the next page
        }
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, projectId, loading]);

  // Function to handle file uploads
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  };

  // Function to remove an attachment
  const removeAttachment = (index) => {
    setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
  };

  // Function to handle sending the message
  const handleSendMessage = async () => {
    if (!message?.trim()) {
      return;
    }
    setMessageLoader(true);
    const formData = new FormData();
    // Extract tagged users and replace mentions in message
    const messageUserTags = [];
    const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;
    let processedMessage = message;
    let match;

    while ((match = regex.exec(message)) !== null) {
      const tagname = match[1];
      const userId = match[2];

      // Add to message_user_tags
      messageUserTags.push({ tagname, userId });

      // Replace the mention in the message with "@tagname"
      processedMessage = processedMessage.replace(match[0], `@${tagname} `);
    }
    console.log('Processed Message:', processedMessage);
    console.log('Message User Tags:', messageUserTags);
    attachments.forEach((file) => formData.append('attachments', file));
    formData.append('message', processedMessage);
    formData.append('projectId', projectId);
    // console.log(message, attachments);
    formData.append('message_user_tags', JSON.stringify(messageUserTags));

    try {
      const response = await APIServices.post('/project-activity', formData, {
        'Content-Type': 'multipart/form-data'
      });
      console.log(response);
      // Clear state on successful send
      setMessage('');
      setAttachments([]);
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setMessageLoader(false);
    }
  };

  const sendMessage = () => {
    if (socket) {
      if (message && message.length > 0) {
        socket.emit('projectActivityUpdate', {
          messageType: 'Hello',
          Message: message,
          projectId: projectId
        });
        setMessage('');
      } else {
        alert('Please Write a message');
      }
    }
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on('ProjectRequest', (activity) => {
        setActivities((prevActivities) => [...prevActivities, activity]);
        scrollToBottom();
        // console.log("ProjectRequest", activity);
      });
      socket.on('ProjectUpdate', (activity) => {
        setActivities((prevActivities) => [...prevActivities, activity]);
        scrollToBottom();
        // console.log("ProjectUpdate", activity);
      });
      socket.on('ProjectMilestoneFeedback', (activity) => {
        setActivities((prevActivities) => [...prevActivities, activity]);
        scrollToBottom();
        // console.log("ProjectMilestoneFeedback", activity);
      });
      socket.on('ProjectRequestQuote', (activity) => {
        setActivities((prevActivities) => [...prevActivities, activity]);
        scrollToBottom();
        // console.log("ProjectRequestQuote", activity);
      });
      socket.on('ProjectActivity', (activity) => {
        setActivities((prevActivities) => [...prevActivities, activity]);
        scrollToBottom();
        // console.log("ProjectActivity", activity);
      });
      socket.on('Milestone', (activity) => {
        setActivities((prevActivities) => [...prevActivities, activity]);
        scrollToBottom();
        // console.log("Milestone", activity);
      });
    }
  }, [socket]);

  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="card-body-content" ref={scrollRef}>
      {/* {loading ? selectLoader(80) :
    } */}
      {loading && <div>{selectLoader(50)}</div>}

      {activities.length > 0 &&
        activities.map((activity, index) => (
          <ActivityCard
            key={activity._id}
            activity={activity}
            setCurrentTab={setCurrentTab}
            id={`item_${index}`}
            setSelectedReqId={setSelectedReqId}
          />
        ))}

      <div className="user-chatBox-cover">
        <ul className="chat-btn-list">
          {hasPermission(userRole, 'update_projects', memberPermission) && (
            <>
              <li>
                <a className="cursor_pointer" onClick={() => toggleModal('updateModal', true)}>
                  + Update
                </a>
              </li>
            </>
          )}

          {hasPermission(userRole, 'send_signoff_requests', memberPermission) && (
            <li>
              <a className="cursor_pointer" onClick={() => toggleModal('signOffModal', true)}>
                + Sign-Off
              </a>
            </li>
          )}

          {hasPermission(userRole, 'change_order', memberPermission) && (
            <li>
              <a className="cursor_pointer" onClick={() => toggleModal('changeOrderModal', true)}>
                + Change Order
              </a>
            </li>
          )}
          {hasPermission(userRole, 'change_request', memberPermission) && (
            <li>
              <a className="cursor_pointer" onClick={() => toggleModal('requestModal', true)}>
                + Request
              </a>
            </li>
          )}
          {hasPermission(userRole, 'feedback_request', memberPermission) && (
            <li>
              <a className="cursor_pointer" onClick={() => toggleModal('requestFeedbackModal', true)}>
                + Request Feedback
              </a>
            </li>
          )}
        </ul>
        <div className="chat-msg-cover ">
          {/* <div className="chat-userList">
            <li>
              <img src={nickUser2} alt="nickUser2" className="nickUser" />
              <p>All</p>
            </li>
            <li>
              <img src={nickUser} alt="nickUser" className="nickUser" />
              <p>Rocco</p>
            </li>
            <li>
              <img src={nickUser2} alt="nickUser2" className="nickUser" />
              <p>TJ Sokoll</p>
            </li>
          </div> */}
          <div className="upload-cover">
            {attachments.map((file, index) => (
              <div className="upload-box" key={index}>
                <img
                  src={closeIcon}
                  className="closeicon"
                  onClick={() => removeAttachment(index)}
                  alt="Remove attachment"
                />
                <img
                  src={file.type.startsWith('image/') ? URL.createObjectURL(file) : fileImg}
                  className="fileimg"
                  alt={file.name}
                />
                <div className="upload-text">
                  <p>{file.name}</p>
                  <span>{(file.size / 1024).toFixed(2)} KB</span>
                </div>
              </div>
            ))}
            {/* <div className="upload-box3">
          <label htmlFor="fileUpload">
            <img src={adduploadPic} className="adduploadPic" alt="Upload" />
          </label>
          <input
            id="fileUpload"
            type="file"
            style={{ display: "none" }}
            multiple
            onChange={handleFileUpload}
          />
        </div> */}
          </div>
          <div className="send-msg-text">
            <label htmlFor="reqfileUpload">
              <img src={uploadSvg} className="upload-icon" alt="Upload" />
            </label>

            <input
              id="reqfileUpload"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              multiple
              onChange={handleFileUpload}
            />

            {/* Mentions Input */}
            <div style={{ position: 'relative', flex: 1 }}>
              <MentionsInput
                // style={{ maxHeight: "200px", overflowY: "scroll" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="formControl"
                placeholder="Type your message..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent default Enter key behavior
                    handleSendMessage();
                  }
                  // e.target.style.height = "auto !important"; // Reset the height
                  // e.target.style.height = `${e.target.scrollHeight + 2}px !important`; // Set the height to fit content
                }}
                // aria-rowspan={1}
              >
                <Mention
                  trigger="@"
                  data={mentionUsers}
                  displayTransform={(id, display) => `@${display} `}
                  renderSuggestion={(suggestion) => (
                    <>
                      <img src={suggestion.avatar} alt="nickUser2" className="nickUser" />
                      <p>{suggestion.display}</p>
                    </>
                  )}
                />
              </MentionsInput>
            </div>

            {/* Send Button */}
            <div className="send-msg-cover" onClick={handleSendMessage}>
              {messageLoader ? selectLoader(15) : <img src={sendSvg} alt="Send" />}
            </div>
          </div>
        </div>
      </div>

      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.requestModal}
        onHide={() => toggleModal('requestModal', false)}
        headerTitle={'New Request'}>
        <RequestModal
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal('requestModal');
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.requestFeedbackModal}
        onHide={() => toggleModal('requestFeedbackModal', false)}
        headerTitle={'Request Feedback'}>
        <ReqFeedbackModal
          isInternalProject={isInternalProject}
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal('requestFeedbackModal');
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.addQuoteModal}
        onHide={() => toggleModal('addQuoteModal', false)}
        headerTitle={'Quote'}>
        <AddQuoteModal
          projectId={projectId}
          changeReqId={changeReqId}
          closeModal={() => {
            updateandCloseModal('addQuoteModal');
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.changeOrderModal}
        onHide={() => toggleModal('changeOrderModal', false)}
        headerTitle={'Change Order'}>
        <ChangeOrderModal
          projectId={projectId}
          setChangeReqId={setChangeReqId}
          setQouteRequired={setQouteRequired}
          closeModal={() => {
            if (isQouteRequired == true) {
              setModals((prevState) => ({
                ...prevState,
                addQuoteModal: true,
                changeOrderModal: false
              }));
            } else {
              updateandCloseModal('changeOrderModal');
            }
          }}
        />
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.updateModal}
        onHide={() => toggleModal('updateModal', false)}
        headerTitle={'Update Project'}>
        {
          <UpdateProjectForm
            projectId={projectId}
            setSignOffRequired={setSignOffRequired}
            closeModal={() => {
              // if (updatedMilestoneId) {
              //   console.log("updatedMilestoneId", updatedMilestoneId);
              //   toggleModal("updateModal", false);
              //   toggleModal("signOffModal", true);
              // } else {
              //   setUpdatedMilestoneId("");
              updateandCloseModal('updateModal');
              // }
            }}
          />
        }
      </SidebarModal>
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.signOffModal}
        onHide={() => {
          toggleModal('signOffModal', false);
          setUpdatedMilestoneId('');
        }}
        headerTitle={'Sign-off Request'}>
        <SignOffForm
          updatedMilestoneId={updatedMilestoneId}
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal('signOffModal');
            setUpdatedMilestoneId('');
          }}
        />
      </SidebarModal>
    </div>
  );
};

export default ActivityTab;
