import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { CLOCK_ICON } from '../../assets/image';
import Avatar from '../../shared/Avatar';

export default function DueDateSelection({ handleDueDate, dueDate }) {
  const ExampleCustomInput = forwardRef(({ value, onClick, className }, ref) => (
    <div className={'d-flex align-items-center gap-2 ' + className} onClick={onClick} ref={ref}>
      <Avatar style={{ border: 1, borderStyle: 'dashed', borderColor: '#CBD5E1' }}>
        <img src={CLOCK_ICON} alt="plus-button-icon" />
      </Avatar>
      <div>{value ? value : 'Due date'}</div>
    </div>
  ));
  return (
    <DatePicker
      selected={dueDate}
      onChange={handleDueDate}
      customInput={<ExampleCustomInput className="example-custom-input" />}
      dateFormat={'MMM dd'}
      minDate={new Date()}
    />
  );
}
