import React from 'react';
import loginLogo from '../assets/img/login-logo2.svg';
import logoHover from '../assets/img/login-logohover.svg';
import { useNavigate } from 'react-router-dom';

const Header = ({ content }) => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLogoClick = () => {
    navigate('/projects'); // Redirect to the home page
  };
  return (
    <header className="header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="header-content">
              <a className="happyLogo-cover">
                <img src={loginLogo} alt="Happy Ending" className="happy-logo" onClick={handleLogoClick} />
                <img src={logoHover} alt="Happy Ending" className="happy-logo2" onClick={handleLogoClick} />
              </a>
              {content}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
