import RoutesComponent from './Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-quill/dist/quill.snow.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <div className="">
      <div className="App">
        <DndProvider backend={HTML5Backend}>
          <RoutesComponent />
        </DndProvider>
        <ToastContainer autoClose={2000} />
      </div>
    </div>
  );
}

export default App;
