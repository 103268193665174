import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios'; // Make sure to install axios with `npm install axios`
import APIServices from '../services/APIServices';
import { useNavigate } from 'react-router-dom';
import { formatDateTime, selectLoader } from '../utils/Helpers';
import { useAuth } from '../contexts/AuthContext';
import appToast, { TOAST_TYPE } from '../utils/AppToast';
import { useStore } from '../hooks/useStore';

const Notifications = ({ closeModal }) => {
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const [store] = useStore();
  const { user, socket } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [userId, setUserId] = useState('');
  const [activeTab, setActiveTab] = useState('all'); // Tabs: all, mentions, requests
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [hasMore, setHasMore] = useState(true); // To track if more projects exists

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        // Check if the scrollTop is at the top of the div
        if (
          Math.abs(scrollRef.current.scrollHeight - scrollRef.current.scrollTop - scrollRef.current.clientHeight) <=
            2 &&
          // projectId &&
          !loading &&
          hasMore
        ) {
          fetchNotifications(activeTab, currentPage + 1, false); // Fetch the next page
        }
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, loading]);

  const readNotification = async (id) => {
    try {
      const response = await APIServices.put(`/notification/${id}/read`);
    } catch (error) {
      // Handle errors
      // appToast(error, TOAST_TYPE.ERROR);
    } finally {
      // setLoading(false); // Reset the submitting state
    }
  };

  const clearNotification = async () => {
    // let payload = {
    //   userId: userId,
    // };
    try {
      const response = await APIServices.put(`/notification/clear`, {
        userId
      });
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        fetchNotifications();
      }
      console.log(response);
    } catch (error) {
      // Handle errors
      appToast(error, TOAST_TYPE.ERROR);
    } finally {
      // setLoading(false); // Reset the submitting state
    }
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on('Notification', (Notification) => {
        setNotifications((prevNotifications) => [Notification, ...prevNotifications]);
        // scrollToBottom();
        // console.log("Notification", Notification);
      });
    }
  }, [socket]);

  useEffect(() => {
    fetchNotifications();
    if (user) {
      setUserId(user._id);
    }
  }, []);

  useEffect(() => {
    if (activeTab == 'requests') {
      fetchNotifications('request');
    } else if (activeTab == 'mentions') {
      fetchNotifications('mentioned');
    } else {
      fetchNotifications('');
    }
  }, [activeTab]);

  const getTimeDate = (notification) => {
    return (
      <small>
        {formatDateTime(
          notification.createdDate,
          store.currentDateFormat?.toUpperCase(),
          store.currentTimeFormat
        ).replace('-', '')}
      </small>
    );
  };

  const handleUpdateProject = (notificationData) => {
    navigate(`/overview/${notificationData._id}`);
    closeModal();
  };

  // Fetch notifications from the backend
  const fetchNotifications = async (type = '', page = 1, shouldLoad = true) => {
    setLoading(shouldLoad);
    try {
      const response = await APIServices.get(`/notification?page=${page}&limit=10&type=${type}`); // Replace with your API endpoint
      let data = response.data || [];
      setNotifications((prev) => (page === 1 ? data : [...prev, ...data]));
      setCurrentPage(page);
      setHasMore(data.length === 10);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const NotificationCard = ({ notification, index }) => {
    const renderRequest = () => (
      <div className={`accordion-body ${notification.isRead && 'accordion-body-bglight'}`}>
        <p>
          <strong>{notification.createdBy?.fullName}</strong> has
          {['change_request_submitted', 'change_order_submitted'].includes(notification.notificationType) &&
            ' documented '}
          {['change_request_edited', 'change_order_edited'].includes(notification.notificationType) && ' updated '}
          {['change_request_closed', 'change_order_close'].includes(notification.notificationType) && ' closed '}a{' '}
          <strong>
            <a className="cursor_pointer" onClick={() => handleUpdateProject(notification)}>
              {notification.notificationTypeId?.requestType.replace('_', ' ')}
            </a>
          </strong>
          .
        </p>
        {getTimeDate(notification)}
      </div>
    );

    const renderFeedback = () => (
      <div className={`accordion-body ${notification.isRead && 'accordion-body-bglight'}`}>
        <p>
          <strong>{notification.createdBy?.fullName}</strong> has
          {['feedback_requested'].includes(notification.notificationType) && ' requested '}
          {['feedback_given'].includes(notification.notificationType) && ' provided '}
          <strong>
            <a className="cursor_pointer" onClick={() => handleUpdateProject(notification)}>
              feedback
            </a>
          </strong>
          .
        </p>
        {getTimeDate(notification)}
      </div>
    );

    const renderSignoff = () => (
      <div className={`accordion-body ${notification.isRead && 'accordion-body-bglight'}`}>
        <p>
          <strong>{notification.createdBy?.fullName}</strong> has
          {['signoff_submitted'].includes(notification.notificationType) && ' documented a '}
          {['signoff_edited'].includes(notification.notificationType) && ' updated a '}
          {['signoff_approved'].includes(notification.notificationType) && ' signed the '}
          <strong>
            <a
              className="cursor_pointer"
              onClick={() => {
                handleUpdateProject(notification);
              }}>
              Sign-off Request
            </a>
          </strong>
          .
        </p>
        {getTimeDate(notification)}
      </div>
    );

    const renderChatMessage = () => (
      <div
        className={`accordion-body cursor_pointer ${notification.isRead && 'accordion-body-bglight'}`}
        onClick={() => handleUpdateProject(notification)}>
        <p dangerouslySetInnerHTML={{ __html: notification.notificationText }}>
          {/* <strong>New Message from {notification.createdBy.fullName}:</strong> has */}
          {/* {notification.notificationText} */}
        </p>
        {getTimeDate(notification)}
      </div>
    );

    return (
      <div
        key={notification._id}
        className={`tab-pane fade show active accordion-item`}
        id={`notification-${index}`}
        role="tabpanel">
        {/* {["signoff_submitted", "signoff_edited", "signoff_approved"].includes(
        activity.activityType
      ) && renderSignoff()} */}
        {[
          'change_request_submitted',
          'change_request_edited',
          'change_request_closed',
          'change_order_submitted',
          'change_order_close',
          'change_order_edited'
        ].includes(notification.notificationType) && renderRequest()}
        {['feedback_requested', 'feedback_given'].includes(notification.notificationType) && renderFeedback()}
        {['signoff_submitted', 'signoff_edited', 'signoff_approved'].includes(notification.notificationType) &&
          renderSignoff()}
        {[
          'activity_message',
          'mentioned_message',
          'update_made',
          'change_request_cancelled',
          'change_order_cancelled',
          'change_request_to_change_order',
          'quote_for_change_order',
          'edit_quote_change',
          'quote_approved',
          'milestone_completed',
          'time_delayed',
          'change_order_documented',
          'request_expired',
          'quote_expired',
          'sign_off_expired',
          'request_expiring_today',
          'quote_expiring_today',
          'sign_off_expiring_today',
          'request_expiring_in_hour',
          'quote_expiring_in_hour',
          'sign_off_expiring_in_hour',
          'project_created',
          'mentioned_request_message',
          "request_message",
          "project_closed",
          "milestone_started"
        ].includes(notification.notificationType) && renderChatMessage()}
      </div>
    );
  };

  // if (loading) {
  //   return <div className="nodata-loader">{selectLoader(70)}</div>;
  // }

  return (
    <div className="notification-sidebar">
      <button disabled={notifications.length == 0} className="clear-notification" onClick={clearNotification}>
        Clear all
      </button>
      <ul className="nav nav-tabs d-lg-flex" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
            onClick={() => setActiveTab('all')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'all'}>
            All
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'mentions' ? 'active' : ''}`}
            onClick={() => setActiveTab('mentions')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'mentions'}>
            Mentions
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'requests' ? 'active' : ''}`}
            onClick={() => setActiveTab('requests')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'requests'}>
            Requests
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent" ref={scrollRef}>
        {loading ? (
          <div className="nodata-loader">{selectLoader(70)}</div>
        ) : notifications.length > 0 ? (
          notifications.map((notification, index) => <NotificationCard notification={notification} index={index} />)
        ) : (
          <div className="no-notifications">No new notifications at the moment.</div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
