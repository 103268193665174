import React, { useCallback, useEffect, useState } from 'react';
import '../../assets/css/clients.css';
import ResponsivePagination from 'react-responsive-pagination';
import Header from '../../components/Header';
import leftArrow from '../../assets/img/left-arrow.svg';
import SidebarModal from '../../components/SidebarModal';
import { useLocation, useNavigate } from 'react-router-dom';
import UserForm from './forms/UserForm';
import APIServices from '../../services/APIServices';
import { getformattedPhoneNumber, getOrgUserRole, hasPermission, PAGE_LIMIT, selectLoader } from '../../utils/Helpers';
import appToast, { TOAST_TYPE } from '../../utils/AppToast';
import { useAuth } from '../../contexts/AuthContext';
import EmptyList from '../../components/EmptyList';
import DeleteModal from '../../components/DeleteModal';
import { fetchRolesList } from '../../utils/fetchData';
import addicon from '../../assets/img/add2.svg';
import AttentionModal from 'components/AttentionModal';

const OrganizationUsers = () => {
  const { user, inviterId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const orgId = location.state?.orgId;
  const orgName = location.state?.orgName;
  const { userRole } = useAuth();
  const [Organization, setOrganizations] = useState(null);
  const [organizationName, setOrganizationName] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState(null);
  const [teamId, setTeamId] = useState('');
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [totalPages, setTotalPages] = useState(0);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const [existUserName, setExistUserName] = useState('');
  const [attentionModal, setAttentionModal] = useState(false);

  useEffect(() => {
    if (orgId) {
      setOrganizationId(orgId);
      fetchOrgUsers(1, orgId);
    }
    if (orgName) {
      setOrganizationName(orgName);
    }
  }, [orgId]);

  const handlePageChange = useCallback(
    (page) => {
      if (page && page !== currentPage) {
        setCurrentPage(page);
        fetchOrgUsers(page, organizationId);
      }
    },
    [currentPage]
  );

  const handleBackClick = () => {
    navigate(-1); // This goes back to the previous page
  };

  const toggleModal = (isOpen) => {
    setModalOpen(isOpen);
    if (isOpen == false) {
      setMember(null);
    }
  };

  const fetchOrgUsers = async (currentPage = 1, orgId = '') => {
    try {
      const response = await APIServices.get(`/organizations/${orgId}/users?page=${currentPage}&limit=${PAGE_LIMIT}`);

      if (response.users) {
        setTeams(response.users || []);
        setCurrentPage(Number(response.currentPage));
        setTotalPages(response.totalPages);
      } else if (response?.data?.message) {
        setTeams([]);
      }
    } catch (error) {
      console.error('Error fetching the teams:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setTeamId(user?.companyId?._id);

      fetchRolesList(setRoles, setLoading, setError, true);
    }
  }, [user]);

  const closeAndUpdateList = (userName) => {
    fetchOrgUsers(1, organizationId);
    toggleModal(false);
    if (userName) {
      setExistUserName(userName);
      setAttentionModal(true);
    }else{
      setExistUserName("");
    }
  };

  const handleResendInvite = async (member) => {
    const currentOrgMember = getOrgUserRole(member, orgId);
    let payload = {
      fullName: member.fullName,
      email: member.email,
      phoneNumber: member.phoneNumber,
      userRoleName: currentOrgMember?.userType?._id,
      countryDialCode: member.countryDialCode,
      countryCode: member.countryCode,
      inviterId: inviterId,
      organizationId: organizationId,
      isResendInvitation: true
    };
    try {
      const response = await APIServices.post('/user/invite-user', payload);
      if (response.message) {
        appToast(`Invitation successfully resent to ${member.fullName} (${member.email})`, TOAST_TYPE.SUCCESS);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (member) => {
    setMember(member);
    toggleModal(true);
  };

  const handleDeleteMember = (id) => {
    setMemberToDelete(id); // Set the member ID to be deleted
    setDeleteOpen(true); // Open the delete confirmation modal
  };

  const confirmDeleteMember = async () => {
    // setDeleteOpen(false);
    if (memberToDelete) {
      setLoading(true);
      const payload = {
        organizationId
      };
      try {
        let response = await APIServices.put(`/team/member/${memberToDelete}`, payload);
        appToast(response.message, TOAST_TYPE.SUCCESS);
        fetchOrgUsers(1, organizationId); // Refresh the team list after deletion
        setDeleteOpen(false); // Close the modal
        setMemberToDelete(null); // Clear the member to delete
      } catch (error) {
        console.error('Error deleting Member:', error);
        appToast('Failed to delete Member', TOAST_TYPE.ERROR);
      }
    }
  };

  const handleToggle = async (userId, newTeamStatus, status) => {
    const data = {
      userId: userId,
      teamStatus: newTeamStatus,
      organizationId
    };
    if (status == 'invited') {
      appToast('User needs to accept the invitation from the email first.', TOAST_TYPE.WARNING);
    } else {
      // Update the team status in the state after successful API call
      setTeams((prevTeams) =>
        prevTeams.map((member) => (member._id === userId ? { ...member, teamStatus: newTeamStatus } : member))
      );
      try {
        // Example API call (adjust as per your actual API endpoint)
        const response = await APIServices.put('/team/member/status/update', data); // Adjust endpoint accordingly

        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          fetchOrgUsers(1, organizationId);
        } else {
          appToast('Failed to update team status', TOAST_TYPE.ERROR);
        }
      } catch (error) {
        console.error('Error updating team status:', error);
        appToast('Failed to update team status', TOAST_TYPE.ERROR);
      }
    }
  };

  const HeaderContent = (
    <>
      <h2>{organizationName}</h2>
      <div className="head-right">
        <a onClick={handleBackClick}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  const ModalContent = (
    <>
      <UserForm
        setExistUserName={setExistUserName}
        initialData={member}
        roleOptions={roles}
        closeModal={closeAndUpdateList}
        orgId={organizationId}
      />
    </>
  );

  const closeConfirmContent = (
    <div>
      <h2 className="mb-3">Attention</h2>
      <h3>User found and added to client list as {existUserName} </h3>
    </div>
  );
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="wrapper">
      <div className="main-content client_directory">
        <Header content={HeaderContent} />
        {loading ? (
          <div className="nodata-loader">{selectLoader(100)}</div>
        ) : teams?.length == 0 ? (
          <EmptyList buttonText={'Add New Users'} openModal={() => toggleModal(true)} />
        ) : (
          <section className="companies-sec">
            <div className="container">
              <div className="row client-desktop-view">
                <div className="col-lg-12 col-md-12">
                  <div className="companies-top-head">
                    <button onClick={() => toggleModal(true)} type="button" className="add-user">
                      + Add New User
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="companies-card">
                    <div className="companies-list">
                      <div className="top-headTitle">
                        <h2>Users</h2>
                        <button className="team-add-btn" onClick={() => toggleModal(true)}>
                          <img src={addicon} alt="addicon" className="addicon" />
                          Add
                        </button>
                      </div>
                      <table className="grid">
                        <thead>
                          <tr>
                            <th>Team Member</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Role</th>
                            <th>Status</th>
                            {hasPermission(userRole, 'modify_team') && <th>Actions</th>}
                          </tr>
                        </thead>

                        <tbody>
                          {teams?.map((member, memberIndex) => {
                            const currentOrgMember = getOrgUserRole(member, orgId);
                            // console.log(currentOrgMember);

                            return (
                              <tr key={`${memberIndex}`}>
                                <td>{member.fullName}</td>
                                <td className="mobile-view-td">
                                  <ul>
                                    <li>
                                      <a href="#">{member.email}</a>
                                    </li>
                                    <li>
                                      {member.countryDialCode + getformattedPhoneNumber(member.phoneNumber)}
                                      {/* {member.countryDialCode + member.phoneNumber} */}
                                    </li>
                                  </ul>
                                </td>
                                <td className="mobile-view-td">
                                  <ul className=" py-0 no-before">
                                    <li> {currentOrgMember?.userType?.name}</li>
                                    {/* <li className="accept-text"> Accepted</li> */}
                                  </ul>
                                </td>
                                <td className="desktop-view-td">{member.email}</td>
                                <td className="desktop-view-td">
                                  {member.countryDialCode + ' ' + getformattedPhoneNumber(member.phoneNumber)}
                                  {/* {member.countryDialCode + member.phoneNumber} */}
                                </td>
                                <td className="desktop-view-td">
                                  <li> {currentOrgMember?.userType?.name}</li>
                                </td>
                                <td className="desktop-view-td">{currentOrgMember.status}</td>
                                {hasPermission(userRole, 'modify_team') && (
                                  <td>
                                    <ul className="actions">
                                      <li>
                                        <button className="btn btn-pen" onClick={() => handleEditClick(member)}>
                                          <i className="fas fa-pen"></i>
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="btn btn-trash"
                                          onClick={() => handleDeleteMember(member._id)}>
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      </li>
                                      <li>
                                        <button
                                          className="btn btn-trash"
                                          disabled={currentOrgMember.status != 'invited'}
                                          onClick={() => handleResendInvite(member)}>
                                          <i className="fas fa-envelope"></i>
                                        </button>
                                      </li>
                                      <li className="custom-switch-right">
                                        <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                          <input
                                            checked={
                                              currentOrgMember.status == 'invited' ? false : currentOrgMember.teamStatus
                                            }
                                            readOnly={currentOrgMember.status == 'invited'}
                                            type="checkbox"
                                            className="custom-control-input cursor_pointer"
                                            id={`customSwitch-${memberIndex}`} // Make id dynamic
                                            onChange={() =>
                                              handleToggle(
                                                member._id,
                                                !currentOrgMember.teamStatus,
                                                currentOrgMember.status
                                              )
                                            }
                                          />
                                          <label
                                            className="custom-control-label cursor_pointer"
                                            htmlFor={`customSwitch-${memberIndex}`} // Make for dynamic
                                          >
                                            &nbsp;
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                        {totalPages > 1 && (
                          <tfoot>
                            <tr colSpan={5} className="tr_empty"></tr>
                            <tr>
                              <td colSpan="6" style={{ padding: 0 }}>
                                <div className="pagination-pages">
                                  <ResponsivePagination
                                    current={currentPage}
                                    total={totalPages}
                                    onPageChange={handlePageChange}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* Modal Component */}
        <SidebarModal
          extraClass={'edit-profile-modal client_modal'}
          show={modalOpen}
          onHide={() => toggleModal(false)}
          headerTitle={member ? 'Edit Organization User' : 'Add New User'}>
          {ModalContent}
        </SidebarModal>
        <DeleteModal
          show={deleteOpen}
          onHide={() => {
            setDeleteOpen(false);
            setMemberToDelete(null); // Clear the member to delete on cancel
          }}
          handleDelete={confirmDeleteMember} // Pass the deletion logic here
          loading={loading}
          itemName={'member'}
        />
        <AttentionModal
          show={attentionModal}
          onHide={() => {
            setAttentionModal(false);
            setExistUserName('');
          }}
          confirmationText={closeConfirmContent}
          handleAction={() => {
            setAttentionModal(false);
          }} // Pass the deletion logic here
          // loading={isClosingProject}
          closeText="Yes"
        />
      </div>
    </div>
  );
};

export default OrganizationUsers;
