import React, { useState } from 'react';
import EDIT_ICON from 'assets/img/edit-01.svg';
import EditBigBox from './EditBigBox';

export default function EditableText({
  title,
  handleSubmitTitle,
  hideEditButton,
  defaultEditMode,
  handleChangeTitle,
  handleCloseTitle = () => {}
}) {
  const [showEditMode, setShowEditMode] = useState(defaultEditMode || false);

  const handleSubmit = (data) => {
    handleSubmitTitle(data);
    setShowEditMode(false);
  };
  return (
    <>
      {!showEditMode ? (
        <div className="editable-text-title d-flex align-items-center gap-2">
          <div className="heading">{title}</div>
          {!hideEditButton && (
            <div style={{ width: '24px', height: '24px' }}>
              <button className="btn-icon">
                <img src={EDIT_ICON} alt="edit" className="clickable-item" onClick={() => setShowEditMode(true)} />
              </button>
            </div>
          )}
        </div>
      ) : (
        <EditBigBox
          onChange={handleChangeTitle}
          title={title}
          onClose={() => {
            handleCloseTitle();
            setShowEditMode(false);
          }}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}
