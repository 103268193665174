import { useEffect, useState } from "react";
import starSvg from "../assets/img/star.svg";
import { useAuth } from "../contexts/AuthContext";
import {
  formatDate,
  formatDateTime,
  renderHighlightedMessage,
} from "../utils/Helpers";
import { useStore } from "../hooks/useStore";
import fileIcon from "../assets/img/file-picture.svg";
const ActivityCard = ({ activity, setCurrentTab, id, setSelectedReqId }) => {
  const [userId, setUserId] = useState("");
  const [store, setStore] = useStore();
  const { user, isUser1 } = useAuth();

  const handleRequestDetails = (reqId, tabIndex) => {
    setStore({ selectedReqId: reqId });
    setCurrentTab(tabIndex);
    setSelectedReqId(reqId);
  };

  useEffect(() => {
    if (user) {
      // console.log(user._id);

      setUserId(user._id);
    }
  }, [user]);
  const renderUpdateMade = () => (
    <div className="lightbg-card chat-bubble">
      <p>
        <b className="darkgreen-text">Project Update!</b>{" "}
        <b className="lightblue-text">
          {userId == activity.createdBy?._id
            ? ""
            : `@${activity?.createdBy?.fullName}`}
        </b>{" "}
        {userId == activity.createdBy?._id && (
          <b>{activity?.createdBy?.fullName}</b>
        )}{" "}
        has updated the project.
      </p>
      <ul>
        {/* <li>
          <b>{activity?.milestoneId?.name}</b> % completed from{" "}
          <b>{activity?.activityTypeId?.fromPercentage}%</b> to{" "}
          <b>{activity?.activityTypeId?.toPercentage}%</b>
        </li> */}
        <li>
          <b className="mediumblack-text">Project status is:</b>{" "}
          {activity?.activityTypeId?.updateStatus?.value === "ontime" && (
            <b className="darkgreen-text">On Time</b>
          )}
          {activity?.activityTypeId?.updateStatus?.value === "early" && (
            <b className="darkblue-text">
              Early by {activity?.activityTypeId.daysEarly} day
              {activity?.activityTypeId.daysEarly > 1 && "s"}
            </b>
          )}
          {activity?.activityTypeId?.updateStatus?.value === "late" && (
            <b className="red-text">
              Late by {activity?.activityTypeId.daysLate} day
              {activity?.activityTypeId.daysLate > 1 && "s"}
            </b>
          )}
        </li>
      </ul>
      <p className="mt-3">
        <b>Note:</b> {activity?.activityTypeId?.updateNote}
      </p>
    </div>
  );

  const renderSignoff = () => (
    <div className="lightbluebg-card chat-bubble">
      <p>
        <b className="lightbluebg-text">Sign-off Request:</b>{" "}
        <b className="lightblue-text">
          {/* {userId == activity.createdBy?._id ? "@all" : `@${activity?.createdBy?.fullName}`} */}
          {userId == activity.createdBy?._id
            ? ""
            : `@${activity?.createdBy?.fullName}`}
        </b>{" "}
        {userId == activity.createdBy?._id && (
          <b>{activity?.createdBy?.fullName}</b>
        )}{" "}
        has
        {activity.activityType === "signoff_edited"
          ? " updated a"
          : activity.activityType === "signoff_approved"
          ? " signed the"
          : " submitted a"}{" "}
        <a
          onClick={() => {
            handleRequestDetails(
              activity.activityTypeId?.parentRequestId?._id ||
                activity.activityTypeId._id,
              2
            );
          }}
        >
          Sign-off Request
        </a>{" "}
        for {activity.milestoneId.name}.
      </p>
    </div>
  );

  const renderRequest = () => (
    <div className="bluebg-card chat-bubble">
      <p>
        <b className="darkblue-text">
          {activity.activityType === "change_request_submitted" && "New"}{" "}
          Request!
        </b>{" "}
        <b className="lightblue-text">
          {userId == activity.createdBy?._id
            ? ""
            : `@${activity?.createdBy?.fullName}`}
        </b>{" "}
        {userId == activity.createdBy?._id && (
          <b>{activity?.createdBy?.fullName}</b>
        )}{" "}
        {activity.activityType === "change_request_cancelled"
          ? " cancelled a"
          : activity.activityType === "change_request_closed"
          ? " closed a"
          : activity.activityType === "change_request_edited"
          ? " updated a"
          : " has documented a"}{" "}
        <a
          onClick={() => {
            handleRequestDetails(activity.activityTypeId._id, 2);
          }}
        >
          Request
        </a>{" "}
        for {activity.activityTypeId.title}.
      </p>
    </div>
  );

  const renderChangeOrder = () => (
    <div className="purplebg-card chat-bubble">
      <p>
        <b className="darkpurple-text">Change Order!</b>{" "}
        <b className="lightblue-text">
          {userId == activity.createdBy?._id
            ? ""
            : `@${activity?.createdBy?.fullName}`}
        </b>{" "}
        {userId == activity.createdBy?._id && (
          <b>{activity?.createdBy?.fullName}</b>
        )}{" "}
        has{" "}
        {activity.activityType === "change_order_cancelled"
          ? "cancelled"
          : activity.activityType === "change_order_close"
          ? "closed"
          : activity.activityType === "change_order_edited"
          ? "updated"
          : activity.activityType === "change_request_to_change_order"
          ? "converted"
          : "submitted"}{" "}
        <a
          onClick={() => {
            handleRequestDetails(
              activity.activityTypeId?.parentRequestId?._id ||
                activity.activityTypeId._id,
              2
            );
          }}
        >
          {activity.activityType === "change_request_to_change_order" &&
            "request into a "}
          change order
        </a>
        .
      </p>
    </div>
  );

  const renderQuote = () => (
    <div className="quote-card chat-bubble">
      <p>
        <b className="quotebubble-text">Quote!</b>{" "}
        <b className="lightblue-text">
          {userId == activity.createdBy?._id
            ? ""
            : `@${activity?.createdBy?.fullName}`}
        </b>{" "}
        {userId == activity.createdBy?._id && (
          <b>{activity?.createdBy?.fullName}</b>
        )}{" "}
        has{" "}
        {activity.activityType === "edit_quote_change"
          ? "updated"
          : activity.activityType === "quote_approved"
          ? "approved"
          : "added"}{" "}
        a{" "}
        <a
          onClick={() => {
            handleRequestDetails(activity.activityTypeId.projectRequestId, 2);
          }}
        >
          quote
        </a>{" "}
        {activity.activityType === "quote_approved" ? "in" : "to"}{" "}
        <a
          onClick={() => {
            handleRequestDetails(activity.activityTypeId.projectRequestId, 2);
          }}
        >
          change order
        </a>
        .
      </p>
    </div>
  );

  const renderFeedback = () => (
    <div className="orange-card chat-bubble">
      <p>
        <b className="orange-text">Feedback!</b>{" "}
        <b className="lightblue-text">
          {userId == activity.createdBy?._id
            ? ""
            : `@${activity?.createdBy?.fullName}`}
        </b>{" "}
        {userId == activity.createdBy?._id && (
          <b>{activity?.createdBy?.fullName}</b>
        )}{" "}
        has{" "}
        {activity.activityType === "feedback_given" ? (
          <>
            provided{" "}
            <a
              onClick={() => {
                handleRequestDetails(
                  activity.activityTypeId?.feedbackId?._id ||
                    activity.activityTypeId?._id,
                  4
                );
              }}
            >
              feedback
            </a>
            .
          </>
        ) : (
          <>
            sent a{" "}
            <a
              onClick={() => {
                handleRequestDetails(activity.activityTypeId._id, 4);
              }}
            >
              feedback request
            </a>
            {" "}to : {activity?.activityTypeId?.clients.map((x, index)=><span>{x.fullName}{(activity?.activityTypeId?.clients.length - 1) == index ? "." : ", "}</span>)}
          </>
        )}
      </p>
      <p className="mt-3">
        {Array.from({ length: activity.activityTypeId.rating }, (_, index) => (
          <img
            key={index}
            src={starSvg}
            alt="Star"
            style={{ width: "20px", height: "20px", marginRight: "5px" }}
          />
        ))}
      </p>
    </div>
  );

  const renderChatMessage = () => (
    <>
      <div className="wire-frame_design">
        <div className="request_header_inner">
          <p
            dangerouslySetInnerHTML={{
              __html: renderHighlightedMessage(activity),
            }}
          ></p>
        </div>
        <div className="file_uploaded_inner">
          {activity.attachments.length > 0 &&
            activity.attachments.map((file, index) => (
              <div className="file_item" key={index}>
                <a href={file.url} target="_blank" className="attachment-tag">
                  <img src={fileIcon} />
                  <div className="file_name">
                    <h6>{file.name}</h6>
                    <span className="file_size">123KB</span>
                  </div>
                </a>
              </div>
            ))}
        </div>
      </div>
    </>
  );

  const renderMilestoneUpdate = () => (
    <>
      <div className="wire-frame_design">
        <div className="request_header_inner">
          <p>
            Milestone: <b>{activity.milestoneId.name}</b> was{" "}
            {activity.activityType === "milestone_completed"
              ? "completed"
              : "started"}{" "}
            by <b>{activity.createdBy.fullName}</b> on{" "}
            {formatDateTime(
              activity.modifiedDate,
              store.currentDateFormat?.toUpperCase(),
              store.currentTimeFormat,
              true,
              false
            )}
            .
          </p>
        </div>
      </div>
    </>
  );

  const renderProjectStart = () => (
    <>
      <div className="wire-frame_design">
        <div className="request_header_inner">
          <p>
            Project: <b>{activity.activityTypeId.projectName}</b> was{" "}
            {activity.activityType === "project_created"
              ? "created"
              : "closed"}{" "}
            by <b>{activity.createdBy.fullName}</b> on{" "}
            {formatDateTime(
              activity.modifiedDate,
              store.currentDateFormat?.toUpperCase(),
              store.currentTimeFormat,
              true
            )}
            .
          </p>
        </div>
      </div>
    </>
  );
  return (
    <div
      id={id}
      key={activity?._id}
      className={`${
        userId == activity.createdBy?._id
          ? "chat-bubbleMe-cover"
          : "chat-bubbleYou-cover"
      }`}
    >
      {activity.activityType === "update_made" && renderUpdateMade()}
      {["signoff_submitted", "signoff_edited", "signoff_approved"].includes(
        activity.activityType
      ) && renderSignoff()}
      {[
        "change_order_documented",
        "change_order_submitted",
        "change_order_close",
        "change_order_cancelled",
        // "quote_approved",
        "change_request_to_change_order",
        "change_order_edited",
      ].includes(activity.activityType) && renderChangeOrder()}
      {[
        "quote_for_change_order",
        "edit_quote_change",
        "quote_approved",
      ].includes(activity.activityType) && renderQuote()}
      {["feedback_requested", "feedback_given"].includes(
        activity.activityType
      ) && renderFeedback()}
      {[
        "change_request_submitted",
        "change_request_edited",
        "change_request_closed",
        "change_request_cancelled",
      ].includes(activity.activityType) && renderRequest()}
      {["activity_message", "mentioned_message"].includes(
        activity.activityType
      ) && renderChatMessage()}
      {["milestone_started", "milestone_completed"].includes(
        activity.activityType
      ) && renderMilestoneUpdate()}
       {["project_created", "project_closed"].includes(
        activity.activityType
      ) && renderProjectStart()}

      <div className="chat-time">
        {activity?.createdBy?.fullName}{" "}
        {formatDateTime(
          activity.createdDate,
          store.currentDateFormat?.toUpperCase(),
          store.currentTimeFormat
        )}
      </div>
    </div>
  );
};

export default ActivityCard;
