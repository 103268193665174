import React from "react";
import leftArrow from "../assets/img/left-arrow.svg";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const HeaderContent = (
    <>
      <h2>Services</h2>
      <div className="head-right">
        <a onClick={() => navigate("/")}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );
  return (
    <div className="wrapper create_project">
      <div className="main-content">
        <Header content={HeaderContent} />
        <div className="container">
        <div className="terms-condition">
          <p className="text-content">Welcome to <a href="https://happyending.ai">https://happyending.ai</a> and any associated software, applications, or services (collectively, the <b>“Happy Ending Platform”).</b> The <b>Happy Ending Platform</b> is owned and operated by <b> Happy Ending Inc.</b></p> 
          <p className="text-content">By accessing or using the <b>Happy Ending Platform</b>, you agree to these Terms of Service (”Terms”). If you do not agree, please do not use the Platform</p>
         
          <p className="head-title">1. Copyright & Intellectual Property</p>
          <p className="text-content">All content on the Happy Ending Platform, including text, images, software, AI-driven integrations, and proprietary technology, is protected by copyright laws unless otherwise stated. You may not copy, modify, distribute, or use content without our express written consent.</p>
        
        <p className="head-title">2. No Warranty</p>
         <p>We strive to provide accurate and reliable services, but we do not guarantee the completeness, accuracy, or availability of any content on the Platform.</p>
           <ul>
            <li>The Platform is provided “AS IS” and “AS AVAILABLE” without warranties of any kind, whether express or implied.</li>
            <li>We are not responsible for errors, delays, or omissions in the information provided.</li>
           </ul>
           <p className="text-content">Additionally, we may collect non-personal data such as browser type, IP address, and website usage statistics to enhance user experience.</p>
       
           <p className="head-title">3. Limitation of Liability</p>
          <p>To the fullest extent permitted by law, Happy Ending Inc. shall not be liable for any:</p>
           <ul>
            <li>Direct, indirect, incidental, consequential, or punitive damages</li>
            <li>Loss of data, revenue, profits, or business opportunities</li>
            <li>Any issues arising from reliance on AI-driven insights or third-party integrations</li>
           </ul>
           <p className="text-content">Your use of the Platform is at your own risk.</p>

           <p className="head-title">4. Images & Branding</p>
           <p className="text-content">All images, trademarks, and branding materials on the Happy Ending Platform are owned or licensed by us. You may not use, modify, or reproduce them without prior written permission</p> 
           
           <p className="head-title">5. Trademarks</p>
           <p className="text-content">All trademarks, logos, and service marks displayed on the Platform are the exclusive property of Happy Ending Inc. or third-party partners. Unauthorized use is strictly prohibited.</p>
          
         
           <p className="head-title">6. External Links</p>
           <p className="text-content">Our Platform may contain links to third-party websites for your convenience. However, we do not endorse or control these external sites and are not responsible for their content or security.</p>
          
          
           <p className="head-title">7. User Submissions & Content</p>
           <p>Any content, feedback, or materials submitted to Happy Ending Inc. (e.g., reviews, suggestions, AI-generated data) are considered non-confidential and non-proprietary.</p>
            <ul>
              <li>We may use, modify, or distribute your submissions without compensation or acknowledgment.</li>
              <li>Do not submit content you wish to keep private or proprietary.</li>
            </ul>
          
           <p className="head-title">8. Hold Harmless & Indemnity</p>
           <p>By using the Happy Ending Platform, you agree to:</p>
            <ul>
              <li>Defend, indemnify, and hold harmless Happy Ending Inc. from any claims, damages, or expenses resulting from your breach of these Terms.</li>
              <li>Assume full responsibility for any legal consequences of your use of our services.</li>
            </ul>
           <p className="head-title">9. Platform Availability & Compliance</p>
            <ul>
              <li>The Happy Ending Platform operates under U.S. laws, primarily governed by California regulations.</li>
              <li>We do not guarantee international compliance—users outside the U.S. must ensure their local laws allow Platform usage.</li>
            </ul>
            <p className="head-title">10. Feedback Policy</p>
           <p>Any feedback, suggestions, or ideas submitted to Happy Ending Inc. may be used freely. By submitting feedback, you agree that:</p>
            <ul>
              <li>We do not owe compensation for ideas, improvements, or recommendations.</li>
              <li>Feedback is considered voluntary and non-confidential.</li>
            </ul>

            <p className="head-title">11. SMS Communications & Consent</p>
           <p>By providing your phone number and opting in, you consent to receive SMS messages related to your account, updates, and promotional offers.</p>
            <ul>
              <li>You can opt out anytime by replying STOP to any SMS message.</li>
              <li>Standard carrier rates may apply.</li>
              <li>Your opt-in data will never be shared with third parties for marketing purposes.</li>
            </ul>

            <p className="head-title">12. Disclaimers & Additional Limitations of Liability</p>
            <ul>
              <li>The Happy Ending Platform relies on AI and other data-driven technologies—we do not guarantee their accuracy or outcomes.</li>
              <li>Any reliance on our software for business decisions is at your own risk.</li>
              <li>No warranties (express or implied) apply to service availability, AI outputs, or customer success rates.</li>
            </ul>

            <p className="head-title">13. Governing Law & Dispute Resolution</p>
            <p>These Terms shall be governed by and interpreted under the laws of the State of California.</p>
            <ul>
              <li>Any disputes arising from or relating to these Terms shall be resolved exclusively in Los Angeles County, California.</li>
              <li>Users waive the right to a jury trial and agree to binding arbitration if necessary</li>
         
            </ul>

            <p className="head-title">14. Account Termination</p>
            <p>We reserve the right to suspend, restrict, or terminate user accounts for:</p>
            <ul>
              <li>Violation of these Terms</li>
              <li>Suspicious, abusive, or illegal activity</li>
              <li>Non-compliance with payment or usage policies</li>
            </ul>
             <p className="text-content">Certain clauses (e.g., Sections 1, 7-13) remain in effect even after termination.</p>
            <p className="head-title">15. General Terms</p>
            <ul>
              <li>We may update these Terms at any time. Any changes will be posted on this page.</li>
              <li>Your continued use of the Platform after modifications constitutes acceptance of the new Terms.</li>
              <li>These Terms constitute the entire agreement between you and Happy Ending Inc.</li>
            </ul>

            <p className="head-title">16. Contact Information</p>
            <p className="text-content">For questions or concerns, contact us at:</p>
      
           <a href="mailto:support@happyending.ai" >📧 support@happyending.ai</a>
      

        </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
