export const hasOnlySpecialCharacters = (inputStr) => {
  // Regular expression that matches any character that is not a letter or a number
  const specialCharRegex = /^[^a-zA-Z0-9]*$/;

  // Check if the string matches the special character pattern
  return specialCharRegex.test(inputStr);
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const findLetter = (value) => {
  const name = value?.split(' ');
  if (name?.length === 1) {
    return name[0][0]?.toUpperCase();
  } else if (name?.length > 1) {
    return name[0][0]?.toUpperCase() + name[1][0]?.toUpperCase();
  }
};
