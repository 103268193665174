import React from 'react';
import style from './Activity.module.css';

export default function UserActivity({ name, activity, time }) {
  return (
    <div>
      <div className={style.commented_name_wrapper}>
        <div className={style.name}>{name}</div>
        <div className="caption">
          <div dangerouslySetInnerHTML={{ __html: activity }} />
        </div>
      </div>
      <div className="time-stamp">{time}</div>
    </div>
  );
}
