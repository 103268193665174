import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PhoneNumberInput from '../../../components/PhoneInput';
import { useAuth } from '../../../contexts/AuthContext';
import APIServices from '../../../services/APIServices';
import appToast, { TOAST_TYPE } from '../../../utils/AppToast';
import {
  emailLimit,
  getformattedPhoneNumber,
  getOrgUserRole,
  getPlainPhoneNumber,
  selectLoader,
  userNameLimit,
  validateForm
} from '../../../utils/Helpers';
import ConfirmModal from 'components/ConfirmModal';
import AttentionModal from 'components/AttentionModal';

const UserForm = ({setExistUserName, initialData, roleOptions, closeModal, orgId = '', setAddedUserId }) => {
  const { user } = useAuth();
  const [error, setError] = useState();
  const [organizationId, setOrganizationId] = useState('');
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    userRoleName: null,
    countryDialCode: '',
    countryCode: 'US'
  });
  const [memberId, setMemberId] = useState('');
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (orgId) {
      setOrganizationId(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    if (initialData) {
      const currentOrgMember = getOrgUserRole(initialData, orgId);
      console.log(currentOrgMember);

      setFormData((prevFormData) => ({
        ...prevFormData,
        fullName: initialData.fullName || '',
        email: initialData.email || '',
        phoneNumber: getformattedPhoneNumber(initialData.phoneNumber) || '',
        userRoleName: orgId ? currentOrgMember?.userType?._id : initialData.userType?._id,
        countryDialCode: initialData.countryDialCode || '',
        countryCode: initialData.countryCode || 'US',
        userId: initialData._id
      }));
      setEditing(true);
      setMemberId(initialData._id);
    }
  }, [initialData]);

  useEffect(() => {
    if (roleOptions) {
      setRoleData(roleOptions);
    }
  }, [roleOptions]);

  useEffect(() => {
    if (user && !initialData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        inviterId: user._id
      }));
    }
  }, [user]);

  // Handle input change and clear errors
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  const handlePhoneChange = (val, countryDialCode, countryCode) => {
    setFormData({
      ...formData,
      phoneNumber: val,
      countryDialCode: `${countryDialCode}`,
      countryCode: countryCode
    });
    console.log(countryCode);

    // Clear the error message for the current field being changed
    setErrors({
      ...errors,
      phoneNumber: ''
    });
  };

  // Handle select change for Role
  const handleSelectChange = (selected) => {
    setFormData((prevData) => ({ ...prevData, userRoleName: selected.id }));
    if (errors.userRoleName) {
      setErrors((prevErrors) => ({ ...prevErrors, userRoleName: '' }));
    }
  };

  // Form Validation
  const validateFormDetails = () => {
    let formErrors = {};

    formErrors = validateForm(formData);

    if (!formData.userRoleName) {
      formErrors.userRoleName = 'Please select role.';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = memberId ? `/team/member` : '/user/invite-user';
    if (validateFormDetails()) {
      setLoading(true);
      // Submit form data
      let plainNumber = getPlainPhoneNumber(formData.phoneNumber);
      const updatedFormValues = {
        ...formData,
        phoneNumber: plainNumber,
        organizationId,
        email: formData.email.trim().toLowerCase()
      };
      try {
        const response = memberId
          ? await APIServices.put(endpoint, updatedFormValues)
          : await APIServices.post(endpoint, updatedFormValues);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
         
          if (setAddedUserId) {
            console.log(response?.user?._id);

            setAddedUserId(response?.user?._id);
          }
          if (response?.isNewUser === false && setExistUserName) {
            closeModal(response.user.fullName);
            // appToast(`User found and added to client list as ${response.user.fullName}`, TOAST_TYPE.SUCCESS);
          }else{
            closeModal();
          }
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
      if (!memberId) {
        // Reset form
        // setFormData({
        //   fullName: "",
        //   email: "",
        //   phoneNumber: "",
        //   userRoleName: null,
        //   countryDialCode: "",
        //   countryCode: "US",
        //   inviterId: "",
        // });
        setErrors({});
      }
      console.log(formData);
    }
  };


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              maxLength={userNameLimit}
              type="text"
              className={`form-control ${errors.fullName ? 'form-invalid' : ''}`}
              name="fullName"
              placeholder="User Name"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            {errors.fullName && <small className="error-message">{errors.fullName}</small>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              maxLength={emailLimit}
              type="text"
              className={`form-control ${errors.email ? 'form-invalid' : ''}`}
              name="email"
              placeholder="Email Address"
              value={formData.email}
              disabled={isEditing}
              onChange={handleInputChange}
            />
            {errors.email && <small className="error-message">{errors.email}</small>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <PhoneNumberInput
              isDisable={false}
              value={formData.phoneNumber}
              onPhoneChange={(val) => handlePhoneChange(val, formData.countryDialCode, formData.countryCode)}
              countryDialCode={formData.countryDialCode}
              countryName={formData.countryCode}
              onCountryChange={(country) => {
                setFormData({
                  ...formData,
                  countryDialCode: country.dialCode,
                  countryCode: country.value
                });
                handlePhoneChange(formData.phoneNumber, country.dialCode, country.value);
              }}
              errors={errors.phoneNumber}
            />
            {errors.phoneNumber && <small className="error-message">{errors.phoneNumber}</small>}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="form-group team-form-group">
            <Select
              className={`form-control select2 form-control-select  ${errors.userRoleName ? 'form-invalid' : ''}`}
              options={roleData}
              value={roleData.find((option) => option.id === formData.userRoleName)}
              onChange={handleSelectChange}
              placeholder="Role"
              styles={{ width: '100%' }}
            />
            {errors.userRoleName && <small className="error-message">{errors.userRoleName}</small>}
          </div>
        </div>
      </div>

      <button className="btn-black">{!loading ? (memberId ? 'Save' : 'Add') : selectLoader(35)}</button>
      
    </form>
  );
};

export default UserForm;
