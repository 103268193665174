import React, { useEffect, useState } from 'react';
import style from './Milestone.module.css';
import DropTarget from './DragAndDrop/DropTarget';
import dotsThreeIcon from 'assets/img/DotsThree.svg';
import { Dropdown } from 'react-bootstrap';
import EditBox from 'pages/projects/kanban/shared/EditBox';
import AddMilestoneCard from './AddMilestoneCard';
import { useDrop } from 'react-dnd';
import boardToast, { TOAST_TYPE } from '../utils/boardToast';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    style={{ cursor: 'pointer' }}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    {children}
  </div>
));

export default function ChannelWrapper({
  name,
  milestones,
  handleDrop,
  handleMilestoneClick,
  handleSubmitChannel,
  handleDeleteChannel,
  projectBoardId,
  id,
  cancelEditChannelName,
  viewMode,
  progressBarList,
  isProjectRelatedBoard
}) {
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'MILESTONE_CARD',
      drop: (item) => handleDrop(item, { name: name, _id: id }),
      collect: (monitor) => ({
        isOver: monitor.isOver()
      })
    }),
    []
  );
  const filterMilestones = milestones.filter((value) => value.milestoneKBStatus?._id && value.milestoneKBStatus?._id === id);
  const [showRenameBox, setShowRenameBox] = useState(false);
  const handleSubmit = (value) => {
    if (progressBarList?.some((status) => status.name === value)) {
      boardToast('Channel name already exists.', TOAST_TYPE.ERROR);
      return;
    }
    handleSubmitChannel(value);
    setShowRenameBox(false);
  };

  useEffect(() => {
    if (!id) {
      setShowRenameBox(true);
    }
  }, [id]);

  const handleClose = () => {
    setShowRenameBox(false);
    cancelEditChannelName();
  };

  const handleValidateDeleteChannel = () => {
    if (filterMilestones?.length > 0) {
      boardToast('Please move all milestones before deleting the channel.', TOAST_TYPE.ERROR);
    } else {
      handleDeleteChannel();
    }
  };

  return (
    <div className={style.task_wrapper} ref={dropRef}>
      <div className={style.task_wrapper_heading}>
        {showRenameBox ? (
          <EditBox title={name} onClose={handleClose} handleSubmit={handleSubmit} />
        ) : (
          <>
            <div style={{ cursor: 'pointer' }} onClick={() => setShowRenameBox(true)}>
              {name}
            </div>
            {!viewMode && (
              <div>
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <img src={dotsThreeIcon} alt="menu" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="2" onClick={handleValidateDeleteChannel}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </>
        )}
      </div>
      <DropTarget
        channel={name}
        milestones={filterMilestones}
        handleMilestoneClick={handleMilestoneClick}
        isOver={isOver}
        viewMode={viewMode}
      />
      {!viewMode && id && (
        <AddMilestoneCard
          projectBoardId={projectBoardId}
          statusId={id}
          statusName={name}
          isProjectRelatedBoard={isProjectRelatedBoard}
        />
      )}
    </div>
  );
}
