import React, { useEffect, useRef, useState } from 'react';
import starSvg from '../../../assets/img/star.svg';
import starOpSvg from '../../../assets/img/starOpacity.svg';
import SidebarModal from '../../../components/SidebarModal';
import ReqFeedbackModal from '../updatedOverviewModals/ReqFeedbackModal';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios'; // Assuming axios is used for API requests
import APIServices from '../../../services/APIServices';
import AddFeedbackModal from '../updatedOverviewModals/AddFeedbackModal';
import { formatDateTime, hasPermission, PAGE_LIMIT, selectLoader } from '../../../utils/Helpers';
import { useStore } from 'hooks/useStore';

const FeedbackTab = ({ selectedReqId, projectId, currentTab, memberPermission, userRole, isInternalProject }) => {
  const [store, setStore] = useStore();
  const { user, isUser1, socket } = useAuth();
  const activeTabRef = useRef(null);
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false); // State for feedback
  const [pageLoading, setPageLoading] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]); // State for feedback
  const [requestId, setRequestId] = useState('');
  const [milestoneId, setMilestoneId] = useState('');
  const [activeTab, setActiveTab] = useState('requested');
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
   const [isGeneralFeedback, setGeneralFeedback] = useState(false);
  const [modals, setModals] = useState({
    requestFeedbackModal: false,
    AddFeedbackModal: false
  });

  useEffect(() => {
    if (projectId && currentTab == 4 && !selectedReqId) {
      if (store?.feedbackTab == 'given') {
        setActiveTab('given');
      } else {
        const newTab = isUser1 ? 'requested' : 'requested';
        setActiveTab(newTab);
        fetchFeedback(newTab, true, 1);
        activeTabRef.current = newTab; // Keep the ref updated
      }
    }
  }, [projectId, currentTab]);

  useEffect(() => {
    if (selectedReqId && currentTab == 4) {
      fetchSingleFeedback();
      console.log(selectedReqId);
    } else {
      const newTab = isUser1 ? 'requested' : 'requested';
      setActiveTab(newTab);
      fetchFeedback(newTab, true, 1);
      activeTabRef.current = newTab; // Keep the ref updated
    }
  }, [selectedReqId]);

  useEffect(() => {
    if (activeTab && !pageLoading) {
      fetchFeedback(activeTab, true, 1);
      activeTabRef.current = activeTab;
    }
  }, [activeTab]);

  const fetchFeedback = async (type = 'requested', shouldLoad = false, page = 1) => {
    try {
      if (pageLoading || loading) {
        return;
      }
      if (shouldLoad) {
        setPageLoading(true);
      } else {
        setLoading(true);
      }
      const response = await APIServices.get(
        `/project-milestone-feedback/${projectId}?feedbackType=${type.toLowerCase()}&page=${page}&limit=${PAGE_LIMIT}`
      ); // Update with your API endpoint

      if (response.data) {
        setFeedbackData((prev) => (page === 1 ? response.data : [...prev, ...response.data]));
        setCurrentPage(page);
      }
    } catch (error) {
      console.error('Error fetching feedback data:', error);
    } finally {
      setLoading(false);
      setPageLoading(false);
    }
  };

  // Adjust scroll position after new data is loaded
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer && currentPage > 1) {
      const newScrollHeight = scrollContainer.scrollHeight;
      const previousScrollHeight = scrollContainer.dataset.previousScrollHeight;
      if (previousScrollHeight) {
        scrollContainer.scrollTop = scrollContainer.scrollTop + (newScrollHeight - previousScrollHeight);
      }
      scrollContainer.dataset.previousScrollHeight = newScrollHeight;
    }
  }, [feedbackData]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        // Check if the scrollTop is at the top of the div
        if (
          Math.abs(scrollRef.current.scrollHeight - scrollRef.current.scrollTop - scrollRef.current.clientHeight) <=
            1 &&
          projectId &&
          !loading
        ) {
          // console.log("fastaaaaaaaaaaaaa");

          fetchFeedback(activeTab, false, currentPage + 1); // Fetch the next page
        }
      }
    };

    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, loading]);

  const fetchSingleFeedback = async () => {
    try {
      setLoading(true);
      const response = await APIServices.get(`/project-milestone-feedback/detail/${selectedReqId}`); // Update with your API endpoint
      if (response.data) {
        setFeedbackData(response.data);
      }
    } catch (error) {
      console.error('Error fetching feedback data:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = (modalType, isOpen) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: isOpen
    }));
  };

  const updateandCloseModal = (modalType) => {
    setModals((prevState) => ({
      ...prevState,
      [modalType]: false
    }));
    if (selectedReqId) {
      fetchSingleFeedback();
    } else {
      if (modalType == 'requestFeedbackModal') {
        fetchFeedback('requested', true, 1);
      } else if (modalType == 'AddFeedbackModal') {
        setActiveTab(isInternalProject ? 'given' : 'Given');
        fetchFeedback('Given', true, 1);
      }
    }
  };

  const handleAddFeedback = (feedback) => {
    console.log(feedback);
    if(feedback.milestoneId){
      setMilestoneId(feedback.milestoneId._id);
      setGeneralFeedback(false);
    }else{
      setGeneralFeedback(true);
    }
    setRequestId(feedback._id);
    toggleModal('AddFeedbackModal', true);
  };

  useEffect(() => {
    // Listen for 'projectActivityUpdate' event
    if (socket) {
      socket.on('ProjectActivity', (activity) => {
        console.log('ProjectActivity', activity);
      });

      socket.on('ProjectMilestoneFeedback', (activity) => {
        if (
          (activity.activityType === 'feedback_requested' || activity.activityType === 'feedback_given') &&
          !pageLoading &&
          !loading
        ) {
          if (activeTabRef.current === 'requested') {
            fetchFeedback('requested', true, 1);
          } else if (activeTabRef.current === 'given') {
            fetchFeedback('given', true, 1);
          }
        }
        console.log('ProjectMilestoneFeedback', activity);
      });
    }
  }, [socket]);

  return (
    <div className="card-body-content request-content" ref={scrollRef}>
      {!selectedReqId && (
        <div className="bg-white btn-tab-group">
          <button
            className={`btn btn-custom ${activeTab === 'requested' ? 'btn-dark' : 'btn-light'}`}
            onClick={() => setActiveTab('requested')}>
            {isUser1 ? 'Requested' : 'Pending'}
          </button>
          <button
            className={`btn btn-custom  ${
              activeTab === (isInternalProject ? 'received' : 'Given') ? 'btn-dark' : 'btn-light'
            }`}
            onClick={() => setActiveTab(isInternalProject ? 'received' : 'Given')}>
            {isUser1 ? 'Received' : 'Given'}
          </button>
          {isInternalProject && hasPermission(userRole, 'feedback_give', memberPermission, isInternalProject) ? (
            <>
              <button
                className={`btn btn-custom ${activeTab === 'pending' ? 'btn-dark' : 'btn-light'}`}
                onClick={() => setActiveTab('pending')}>
                Pending
              </button>
              <button
                className={`btn btn-custom  ${activeTab === 'given' ? 'btn-dark' : 'btn-light'}`}
                onClick={() => setActiveTab('given')}>
                Given
              </button>
            </>
          ) : (
            ''
          )}
        </div>
      )}
      {!pageLoading ? (
        <>
          <ul className="chat-btn-list feedback-action">
            {hasPermission(userRole, 'feedback_request', memberPermission) && activeTab == 'requested' && (
              <li>
                <a className="cursor_pointer" onClick={() => toggleModal('requestFeedbackModal', true)}>
                  + Ask for Feedback
                </a>
              </li>
            )}
          </ul>

          {feedbackData?.length > 0 ? (
            feedbackData?.map((feedback) => (
              <div className="feedback_cover mb-2 " key={feedback._id}>
                <div className="feedback_card">
                  <div className="feedback_card_content">
                    <label>
                      <b className="darkblue-text">
                        {feedback.requestType == 'requested' && 'Feedback Request:'}{' '}
                        {feedback.milestoneId?.name || 'General Request'}{' '}
                        {/* {feedback.milestoneId?.name || 'N/A'} */}
                      </b>
                    </label>
                    <div className="feedback_pera">
                      <p>{feedback.description}</p>
                    </div>
                    <div className="star_review request_footer_inner">
                      <ul>
                        {[...Array(5)].map((_, index) => (
                          <li key={index}>
                            <img src={index < (feedback.rating || 0) ? starSvg : starOpSvg} alt="Star" />
                          </li>
                        ))}
                      </ul>

                      <div className={`chat-time you`}>
                        {feedback?.createdBy?.fullName} {/* {activity.createdBy.email.split("@")[0]} -{" "} */}
                        {formatDateTime(feedback.createdDate)}
                      </div>
                    </div>
                    {hasPermission(userRole, 'feedback_give', memberPermission, isInternalProject) &&
                      (!isUser1 || activeTab == 'pending') &&
                      feedback.requestType != 'given' && (
                        <div className="request_footer_inner">
                          <button
                            className="edit-btn"
                            style={{
                              width: 'fitContent',
                              minWidth: '10px'
                            }}
                            onClick={() => handleAddFeedback(feedback)}>
                            + Add Feedback
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No feedback available.</p>
          )}
        </>
      ) : (
        <div className="">{selectLoader(70)}</div>
      )}

      {loading && <div className="">{selectLoader(70)}</div>}
      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.AddFeedbackModal}
        onHide={() => toggleModal('AddFeedbackModal', false)}
        headerTitle={'Feedback'}>
        <AddFeedbackModal
          projectId={projectId}
          requestId={requestId}
          milestoneId={milestoneId}
          isGeneralFeedback={isGeneralFeedback}
          closeModal={() => {
            updateandCloseModal('AddFeedbackModal');
          }}
        />
      </SidebarModal>

      <SidebarModal
        extraClass={'edit-profile-modal'}
        show={modals.requestFeedbackModal}
        onHide={() => toggleModal('requestFeedbackModal', false)}
        headerTitle={'Request Feedback'}>
        <ReqFeedbackModal
          isInternalProject={isInternalProject}
          projectId={projectId}
          closeModal={() => {
            updateandCloseModal('requestFeedbackModal');
          }}
        />
      </SidebarModal>
    </div>
  );
};

export default FeedbackTab;
