import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Input from 'shared/form/Input/Input';
import style from './Tasks.module.css';
import Task from './Task';
import plusIcon from 'assets/img/plus.svg';
import DueDateSelection from './DueDateSelection';
import AssignSelection from './AssignSelection';
import { hasOnlySpecialCharacters } from '../../utils/general';

export default function Tasks({ tasks, handleAddTask, handleChecked, viewMode, members }) {
  const [newItem, setNewItem] = useState(null);
  const [error, setError] = useState('');

  const addItem = () => {
    setNewItem({});
  };

  const handleCancel = () => setNewItem(null);

  const handleAddTasks = () => {
    if (!newItem.name?.trim()) {
      setError('Please enter value');
      return;
    } else if (newItem.name?.length < 2) {
      setError('Min 2 character required');
      return;
    } else if (hasOnlySpecialCharacters(newItem.name)) {
      setError('The input contains only special characters. Please enter valid text.');
      return;
    } else {
      handleAddTask(newItem);
      setNewItem();
      setError('');
    }
  };

  const handleDueDate = (date) => {
    setNewItem({ ...newItem, dueDate: date });
  };

  const handleMember = (data) => {
    if (newItem.assignees?.length > 0) {
      if (newItem.assignees.find((value) => value._id !== data._id)) {
        setNewItem({ ...newItem, assignees: [...newItem.assignees, data] });
      }
    } else {
      setNewItem({ ...newItem, assignees: [data] });
    }
  };

  const handleRemove = (userId) => {
    const filterTeam = newItem.assignees?.filter((value) => value._id !== userId);
    setNewItem({ ...newItem, assignees: filterTeam });
  };

  return (
    <div className={style.tasks_wrapper}>
      {tasks?.map((value) => (
        <Task
          key={value._id}
          isCompleted={value.isCompleted}
          title={value.name}
          dueDate={value.dueDate}
          assignees={value.assignees}
          handleChecked={() => handleChecked(value)}
          disabled={viewMode}
        />
      ))}
      {!viewMode && (
        <>
          {!newItem && (
            <Button variant="light" className={style.add_item_button} onClick={addItem}>
              <div className="icon-btn">
                <img src={plusIcon} alt="search-icon" className="search-icon-right" />
              </div>
              Add an item
            </Button>
          )}
          {newItem && (
            <div className="w-100">
              <Input
                placeholder="Add an item"
                value={newItem.name || ''}
                onChange={(e) => {
                  setError('');
                  setNewItem((prevItem) => ({ ...prevItem, name: e.target.value }));
                }}
                helperText={error}
                error={!!error}
                shouldFocus={true}
              />
              <div className="mt-2 d-flex justify-content-between">
                <div className="d-flex  gap-2">
                  <Button variant="success" onClick={handleAddTasks}>
                    Add
                  </Button>
                  <Button variant="link" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
                <div className="d-flex gap-2">
                  <DueDateSelection handleDueDate={handleDueDate} dueDate={newItem?.dueDate} />
                  <AssignSelection
                    assignees={newItem?.assignees}
                    members={members}
                    handleMember={handleMember}
                    handleRemove={handleRemove}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
