import InviteMember from 'pages/projects/kanban/shared/InviteMember/InviteMember';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import PLUS_BUTTON_ICON from 'assets/img/plus-button.svg';
import AvatarGroup from '../../shared/AvatarGroup';
import Avatar from '../../shared/Avatar';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div className="d-flex align-items-center gap-2" ref={ref} onClick={onClick}>
    <Avatar>
      <img src={PLUS_BUTTON_ICON} alt="plus-button-icon" />
    </Avatar>
    <div>{children ? children : 'Assign'}</div>
  </div>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ style, className, 'aria-labelledby': labeledBy, members, handleMember, assignees, handleRemove }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <InviteMember members={members} handleMember={handleMember} assignees={assignees} handleRemove={handleRemove} />
      </div>
    );
  }
);

const AssignSelection = ({ members, handleMember, assignees, handleRemove }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {assignees?.length > 0 && <AvatarGroup size={34} members={assignees} />}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CustomMenu}
        style={{ width: '350px', border: 'none', marginTop: '10px' }}
        members={members}
        handleMember={handleMember}
        assignees={assignees}
        handleRemove={handleRemove}
      />
    </Dropdown>
  );
};

export default AssignSelection;
