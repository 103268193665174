import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000
});

export default Axios;

// Request interceptor
Axios.interceptors.request.use(
  (config) => {
    const token = cookies.get('authToken');
    // Modify config before sending the request
    config.headers['x-auth-token'] = token;
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
Axios.interceptors.response.use(
  (response) => {
    // Modify response data before passing it to the calling function
    return response.data;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);
