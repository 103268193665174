import React, { useEffect, useRef, useState } from 'react';
import style from './style.module.css';
import X_CLOSE_ICON from 'assets/img/x-close.svg';
import CHECK_ICON from 'assets/img/check2.svg';

export default function EditBox({ onClose, handleSubmit, title }) {
  const [value, setValue] = useState(title);
  const inputRef = useRef(null);

  // Focus the input field when the component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={style.editbox_wrapper}>
      <input
        value={value}
        ref={inputRef}
        onChange={(e) => setValue(e.target.value)}
        maxLength={24}
        placeholder="Enter a title"
      />
      <div>
        <div className={style.form_submit_control}>
          <div className={style.button_wrapper}>
            <img src={CHECK_ICON} alt="check" onClick={() => handleSubmit(value)} />
          </div>
          <div className={style.button_wrapper}>
            <img src={X_CLOSE_ICON} alt="close" onClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
}
