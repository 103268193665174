import React from "react";
import { Link } from "react-router-dom";
import userIcon from "../../../assets/img/nick-user2.png";
import arrowDown from "../../../assets/img/arrow-down.svg";
import { ProjectListStrings } from "../strings/ProjectStrings";

const ProfileModalContent = ({
  profileUrl,
  formValues,
  isVerified,
  toggleModal,
  userRole,
  hasPermission,
  logout,
}) => {
  return (
    <>
      <div className="usee-deatils profilepic-cover">
        {profileUrl || formValues?.profilePic ? (
          <img
            src={profileUrl || formValues?.profilePic}
            alt={ProjectListStrings.userImageAlt}
            className="user-pic"
          />
        ) : (
          <img
            src={userIcon}
            alt={ProjectListStrings.userImageAlt}
            className="user-pic"
          />
        )}
        <div className="profilepic-details">
          <h2>{formValues?.fullName}</h2>
          <p>{formValues?.email}</p>
        </div>
      </div>
      <div className="profile-details">
        <a
          className="edit-profile"
          onClick={() => toggleModal("updateProfile", true)}
        >
          {ProjectListStrings.editProfileText}
          {(!isVerified.isEmailVerified ||
            !isVerified.isPhoneNumberVerified) && (
            <p className="verify">{ProjectListStrings.verifyText}</p>
          )}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </a>
        {hasPermission(userRole, "update_projects") ? (
          <>
            <Link to="/templates">
              {ProjectListStrings.templatesLibraryText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </Link>
            <Link to="/comingsoon">
              {ProjectListStrings.resourcesText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </Link>

            <Link to="/client-directory">
              {ProjectListStrings.clientDirectoryText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </Link>

            {hasPermission(userRole, "modify_team") && (
              <Link to="/myteam">
                {ProjectListStrings.myTeamText}
                <p>
                  {formValues?.companyName !== "undefined"
                    ? formValues?.companyName
                    : ""}
                </p>
                <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
              </Link>
            )}
          </>
        ) : (
          <>
            {/* <Link to="/myteam">
              {ProjectListStrings.company}
              <p>
                {formValues?.companyName !== "undefined"
                  ? formValues?.companyName
                  : ""}
              </p>
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </Link> */}
            <a href="#">
              {ProjectListStrings.supportText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </a>
            <a href="#">
              {ProjectListStrings.whatsNewText}
              <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
            </a>
          </>
        )}
        <Link to="/settings">
          {ProjectListStrings.settingsText}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </Link>
        <Link to="/analytics">
          {ProjectListStrings.analyticsText}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </Link>
        <Link to="/support">
          {ProjectListStrings.supportText}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </Link>
        <a
          href="#"
          className="logout"
          onClick={() => {
            logout();
          }}
        >
          {ProjectListStrings.logoutText}
          <img src={arrowDown} alt={ProjectListStrings.arrowIconAlt} />
        </a>
      </div>
    </>
  );
};

export default ProfileModalContent;
