import React, { useState, useEffect } from 'react';
import SignUpHeader from './SignUpHeader';
import APIServices from '../../../services/APIServices';
import { selectLoader } from '../../../utils/Helpers';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import appToast, { TOAST_TYPE } from '../../../utils/AppToast';

const Features = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [featuresData, setFeaturesData] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (user) {
      // console.log(user._id);

      setUserId(user._id);
    }
  }, [user]);

  const fetchFeatures = async () => {
    try {
      const response = await APIServices.get(`/onboarding/questions`);
      const featuresQuestion = response.find((item) => item.text === 'What features are important to you?');

      if (featuresQuestion) {
        setFeaturesData(featuresQuestion.options);
        setQuestionId(featuresQuestion._id); // Store the question ID for later use
      }
    } catch (error) {
      console.error('Error fetching features:', error);
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatures();
  }, []);

  const handleCheckboxChange = (id) => {
    setSelectedFeatures((prev) => (prev.includes(id) ? prev.filter((featureId) => featureId !== id) : [...prev, id]));
  };

  const handleContinue = async () => {
    if (!questionId || selectedFeatures.length === 0) {
      appToast('No features selected.', TOAST_TYPE.ERROR);
      // console.warn("No features selected or question ID missing");
      return;
    }

    setLoading(true);
    const payload = {
      userId,
      responses: [
        {
          questionId,
          selectedOptions: selectedFeatures
        }
      ]
    };

    try {
      console.log(payload);
      const response = await APIServices.post(`/onboarding/user-responses`, payload);
      if (response.message) {
        navigate('/Questions', {
          state: {
            userResponse: payload.responses
          }
        });
        // appToast(response.message, TOAST_TYPE.SUCCESS);
      }
      console.log('Response submitted successfully:', response);
    } catch (error) {
      console.error('Error submitting responses:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper welcome-page">
      <div className="main-content">
        <SignUpHeader userName={user.fullName} />
        {pageLoading ? (
          <div className="nodata-loader">{selectLoader(70)}</div>
        ) : (
          <div className="feature-sec">
            <div className="container">
              <p>What features are important to you?</p>
              <div className="row">
                {featuresData.map(({ _id, text, description }) => (
                  <div key={_id} className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card-box-bg">
                      <div className="content-check">
                        <div className="form-check checkbox label-title d-flex align-items-center">
                          <input
                            type="checkbox"
                            id={_id}
                            checked={selectedFeatures.includes(_id)}
                            onChange={() => handleCheckboxChange(_id)}
                          />
                          <label htmlFor={_id}></label>
                        </div>
                        <p>{text}</p>
                      </div>
                      <p className="text-content">{description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <button className="btn-black" type="button" onClick={handleContinue}>
                {loading ? selectLoader(35) : 'Continue'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Features;
