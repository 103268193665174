import React, { useState } from 'react';
import style from './Activity.module.css';
import { AVATAR_IMAGE } from '../../assets/image';
import TextEditor from 'shared/form/TextEditor/TextEditor';
import { Button, Form } from 'react-bootstrap';

export default function AddComment({ handleAddComment, profilePic }) {
  const [value, setValue] = useState('');
  const [showTextEditor, setShowTextEditor] = useState(false);
  return (
    <div className={style.comment_wrapper}>
      <div className={style.comment_image}>
        <img src={profilePic || AVATAR_IMAGE} alt="avatar" />
      </div>
      {showTextEditor ? (
        <div style={{ flex: 1 }}>
          <TextEditor value={value} onChange={(data) => setValue(data)} />

          <div className={style.button_group}>
            <Button
              variant="success"
              onClick={() => {
                handleAddComment(value);
                setShowTextEditor(false);
                setValue('');
              }}>
              Save
            </Button>
            <Button variant="link" onClick={() => setShowTextEditor(false)}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Form.Group style={{ flex: 1 }} className='' controlId="exampleForm.ControlInput1" onClick={() => setShowTextEditor(true)}>
          <Form.Control placeholder="Write a comment" className={style.comment_input} />
        </Form.Group>
      )}
    </div>
  );
}
