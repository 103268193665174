import React, { useState } from "react";
import loginLogo from "../../assets/img/login-logo2.svg";
import logoHover from "../../assets/img/login-logohover.svg";
import "../../assets/css/verification.css";
import checkIcon from "../../assets/img/check.svg";
import failedIcon from "../../assets/img/cross-circle.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { verificationStatusStrings } from "./strings/authStrings";
import { selectLoader, validateEmail } from "../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../utils/AppToast";
import APIServices from "../../services/APIServices";

const VerificationStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isVerified = location.state?.isVerified;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailErrors] = useState("");
  const [isMailSent, setMailSent] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailErrors("");
  };


  const handleResendVerification = async (e) => {
    e.preventDefault();
    if (validateEmail(email, setEmailErrors)) {
      const url = "/send-verification-email";
      const payload = {
        email: email,
      };
      try {
        setLoading(true);
        const response = await APIServices.post(url, payload);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          setMailSent(true);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
      } catch (err) {
        console.error("Error while verifying email", err);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <div className="form_right verification_success">
      <div className="login-box">
        <div className="login-logo">
          <a className="happyLogo-cover" href="index.html">
            <img
              src={loginLogo}
              alt={verificationStatusStrings.happyEnding}
              className="happy-logo"
            />
            <img
              src={logoHover}
              alt={verificationStatusStrings.happyEnding}
              className="happy-logo2"
            />
          </a>
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">
              {verificationStatusStrings.happyEnding}
            </p>
            <p className="login-box-smalltext">
              {verificationStatusStrings.verifyAccount}
            </p>
            <form action="" method="post">
              <img
                src={isVerified ? checkIcon : failedIcon}
                className="check-icon"
                alt={verificationStatusStrings.happyEnding}
              />
              <h2
                className={`verify-title verify-email ${
                  isVerified ? "" : "unsuccess-title"
                }`}
              >
                {isVerified
                  ? verificationStatusStrings.verificationSuccessful
                  : verificationStatusStrings.verificationUnSuccessful}
              </h2>

              <p className="email-msg-text">
                {isVerified
                  ? verificationStatusStrings.successMessage
                  : verificationStatusStrings.unSuccessMassage}
              </p>
              {!isVerified && (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group input-group2 mb-3">
                      <input
                        disabled={isMailSent}
                        maxLength={30}
                        type="text"
                        className={`form-control ${
                          emailError ? "form-invalid" : ""
                        }`}
                        name="email"
                        placeholder={verificationStatusStrings.emailPlaceholder}
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {emailError && (
                        <span className="error-message">{emailError}</span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  {!isVerified && (
                    <button
                      disabled={isMailSent}
                      type="submit"
                      onClick={handleResendVerification}
                      className="btn btn-black btn-block resend-button"
                    >
                      {!loading
                        ? verificationStatusStrings.resendEmail
                        : selectLoader(35)}
                    </button>
                  )}
                  {isVerified && (
                    <button
                      disabled={loading}
                      type="submit"
                      onClick={() => {
                        navigate("/comingsoon");
                      }}
                      className="btn btn-black btn-block mb-2"
                    >
                      {verificationStatusStrings.goToHome}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationStatus;
