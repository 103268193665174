import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchProjectMilestones, fetchProjectTeamMembers } from '../../../utils/fetchData';
import { selectLoader, validateTextInput } from '../../../utils/Helpers';
import appToast, { TOAST_TYPE } from '../../../utils/AppToast';
import APIServices from '../../../services/APIServices';
import feedbackReview from '../../../assets/img/starbg.svg';
import plusBtn from '../../../assets/img/plus.svg';
import deleteBtn from '../../../assets/img/delete.svg';

const AddFeedbackModal = ({ projectId, closeModal, milestoneId, requestId, isGeneralFeedback }) => {
  const [loading, setLoading] = useState(true);
  const [mileStonesList, setMileStonesList] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [description, setDescription] = useState('');
  const [clients, setClients] = useState([{ id: 1, selectedClient: null }]); // Initial client row
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [rating, setRating] = useState(0); // For star rating

  useEffect(() => {
    if (projectId) {
      fetchMilestones();
    }
  }, [projectId]);

  const fetchMilestones = async () => {
    setLoading(true);
    try {
      let milestones = await fetchProjectMilestones(projectId, 'update');
      if (milestones?.length > 0) {
        const formattedPermissions = milestones.map((option) => ({
          value: option?._id,
          label: option?.name
        }));
        setMileStonesList(formattedPermissions);
        let currentMilestone = formattedPermissions.find((x) => x.value == milestoneId);
        if (currentMilestone) {
          setSelectedMilestone(currentMilestone);
        }
      }
    } catch (error) {
      console.error('Error fetching milestones:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleStarClick = (index) => {
    setRating(index + 1); // Stars are 1-indexed
  };

  const validateForm = () => {
    const newErrors = {};
    if (!isGeneralFeedback && !selectedMilestone) {
      newErrors.milestone = 'Please select a milestone.';
    }
    // if (!validateTextInput(description).isValid) {
    //   if (!validateTextInput(description).cleanedValue) {
    //     newErrors.description = "Please enter description.";
    //   } else {
    //     newErrors.description = validateTextInput(description).message;
    //   }
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setSubmitLoading(true);
      try {
        const payload = {
          milestoneId: selectedMilestone?.value,
          projectId,
          description,
          rating,
          feedbackId: requestId
        };
        const response = await APIServices.post('/project-milestone-feedback/give-feedback', payload);
        if (response.message) {
          console.log(response.message[0]);
          appToast(response.message, TOAST_TYPE.SUCCESS);
          closeModal();
        } else if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
          closeModal();
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
      {isGeneralFeedback &&
        <div className="row my-3">
          <div className="col-lg-12">
            <div className="input-group">
              <div className={` internal-switch input-desable`}>
                <h4>General Feedback</h4>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    checked={isGeneralFeedback}
                    name="isGeneralFeedback"
                    hidden="hidden"
                    id="generalfeedback"
                    disabled={true}
                  />
                  <label className="switch" htmlFor="generalfeedback"></label>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {!isGeneralFeedback && (
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="form-group">
                <Select
                  isDisabled={milestoneId}
                  className={`form-control select2 ${errors.milestone ? 'form-invalid' : ''}`}
                  options={mileStonesList}
                  placeholder="Select Milestone"
                  isClearable
                  isLoading={loading}
                  value={selectedMilestone}
                  onChange={(option) => {
                    setErrors((prevErrors) => ({ ...prevErrors, milestone: '' }));
                    setSelectedMilestone(option);
                  }}
                />
                {errors.milestone && <small className="text-danger">{errors.milestone}</small>}
              </div>
            </div>
          </div>
        )}

        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="form-group feedback-body">
              <p>Share Your Insights: Help Us Improve!</p>
              <textarea
                placeholder="Write your feedback here"
                className={`form-control ${errors.description ? 'form-invalid' : ''}`}
                value={description}
                onChange={(e) => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    description: ''
                  }));

                  setDescription(e.target.value);
                }}
              />
              {errors.description && <small className="text-danger">{errors.description}</small>}
              <ul className="feedback-review d-flex">
                {[...Array(5)].map((_, index) => (
                  <li key={index} onClick={() => handleStarClick(index)}>
                    <img
                      src={feedbackReview}
                      alt={`Star ${index + 1}`}
                      className={`feedbackReview-img ${index < rating ? '' : 'opacity50'}`}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <button type="submit" className="btn-black">
          {submitLoading ? selectLoader(35) : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default AddFeedbackModal;
