import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSocket } from '../context/SocketContext';
import { formatOptions, selectLoader } from 'utils/Helpers';
import { fetchEnumOptions, fetchMilestoneDetail } from '../utils/fetchData';
import Activity from './Activity/Activity';
import Description from './Description/Description';
import MileStoneSetup from './MilestoneSetup/MileStoneSetup';
import TaskWrapper from './Task/TaskWrapper';
import './MilestoneModal.css';

const MilestoneModal = ({ show, onHide, milestoneId, members, viewMode, projectBoardId }) => {
  const socket = useSocket();
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [milestone, setMilestone] = useState();

  const modalRef = useRef(null);

  // Function to handle clicks outside the modal
  const handleOutsideClick = useCallback(
    (event) => {
      if (milestone?._id && modalRef.current && !modalRef.current.contains(event.target)) {
        socket.emit('update-milestone', {
          id: milestone?._id,
          payload: { description: milestone.description },
          projectBoardId
        });
      }
    },
    [milestone?._id, milestone?.description, projectBoardId, socket]
  );

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick, show]);

  const fetchPriorityOptions = useCallback(async () => {
    try {
      let options = await fetchEnumOptions('milestonpriority');

      if (options && options.length > 0) {
        const formattedOptions = formatOptions(options);

        setPriorityOptions(formattedOptions);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  const fetchMilestone = useCallback(async () => {
    if (milestoneId) {
      const data = await fetchMilestoneDetail(milestoneId);
      if (data) {
        setMilestone(data);
      }
    }
  }, [milestoneId]);

  useEffect(() => {
    fetchPriorityOptions();
    fetchMilestone();
  }, [fetchMilestone, fetchPriorityOptions]);

  useEffect(() => {
    socket?.on('milestone-detail', (data) => {
      if (data?._id === milestoneId) {
        setMilestone(data);
      }
    });
  }, [milestoneId, socket]);

  const handleUpdateMilestone = async (data) => {
    socket.emit('update-milestone', { id: milestone._id, payload: { description: data }, projectBoardId });
  };

  return (
    <Modal show={show} onHide={onHide} className="right-sidebar-modal right miltestone-modal-wrapper">
      <div ref={modalRef}>
        <Modal.Header closeButton={true}>
          <Modal.Title>
            {milestone && (
              <MileStoneSetup
                milestone={milestone}
                priorityOptions={priorityOptions}
                members={members}
                viewMode={viewMode}
                createdBy={milestone.createdBy?.fullName}
                projectBoardId={projectBoardId}
              />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!milestone ? (
            <div className="nodata-loader">{selectLoader(60)} </div>
          ) : (
            <>
              <div className="mb-3">
                {milestone && (
                  <Description
                    viewMode={viewMode}
                    description={milestone?.description}
                    handleSubmitDescription={handleUpdateMilestone}
                    handleDescriptionChange={(data) => {
                      setMilestone((prevState) => ({
                        ...prevState,
                        description: data
                      }));
                    }}
                  />
                )}
              </div>
              <div className="mb-3">
                <TaskWrapper
                  members={members}
                  milestoneId={milestoneId}
                  viewMode={viewMode}
                  projectBoardId={projectBoardId}
                />
              </div>
              <div>
                <Activity
                  milestoneId={milestoneId}
                  viewMode={viewMode}
                  projectBoardId={projectBoardId}
                  defaultActivities={milestone.activities}
                />
              </div>
            </>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default MilestoneModal;
