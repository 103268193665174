import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import { useSocket } from '../context/SocketContext';
import boardToast, { TOAST_TYPE } from '../utils/boardToast';
import { CROSS_ICON, PLUS_ICON, STAND_ICON } from '../assets/image';
import { hasOnlySpecialCharacters } from '../utils/general';
import style from './Milestone.module.css';

export default function AddMilestoneCard({ projectBoardId, statusId, statusName, isProjectRelatedBoard }) {
  const socket = useSocket();
  const inputRef = useRef(null);
  const { projectId } = useParams();
  const { user } = useAuth();
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState('');

  // Focus the input field when the component is mounted
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);
  const handleBlur = (e) => {
    const message = e.target.value;
    if (message?.trim() && hasOnlySpecialCharacters(message)) {
      setError(true);
      boardToast('The input contains only special characters. Please enter valid text.', TOAST_TYPE.ERROR);
      return;
    }
    if (message?.trim()?.length < 2) {
      setError(true);
      boardToast('Min two characters are required', TOAST_TYPE.ERROR);
      return;
    }
    setError(false);
    if (message?.trim()) {
      setShowInput(false);
      if (statusId) {
        socket.emit('add-milestone', {
          projectId: isProjectRelatedBoard ? projectId : undefined,
          name: message,
          createdBy: user?._id,
          projectBoardId,
          milestoneKBStatus: statusId,
          statusName
        });
      } else {
        boardToast('Status name is required', TOAST_TYPE.ERROR);
      }
    }
  };

  return (
    <>
      {showInput ? (
        <div className={style.milestone_edit_card_wrapper} style={error ? { borderColor: '#D11A1A ' } : {}}>
          <div className={style.flex_wrapper}>
            <div className={style.image_wrapper}>
              <img src={STAND_ICON} alt="search-icon" />
            </div>
            <input
              ref={inputRef}
              placeholder="Add title"
              onBlur={handleBlur}
              onChange={() => {
                setError(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleBlur(e);
              }}
            />
            <div className={style.image_wrapper} onClick={() => setShowInput(false)}>
              <img src={CROSS_ICON} alt="close-icon" />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={style.add_new_milestone}
          onClick={() => {
            setShowInput(true);
          }}>
          <div className={style.image_wrapper}>
            <img src={PLUS_ICON} alt="search-icon" />
          </div>
          <div>Add</div>
        </div>
      )}
    </>
  );
}
