import React, { useCallback, useEffect, useState } from 'react';
import '../../assets/css/clients.css';
import ResponsivePagination from 'react-responsive-pagination';
import Header from '../../components/Header';
import leftArrow from '../../assets/img/left-arrow.svg';
import addicon from '../../assets/img/add2.svg';
import SidebarModal from '../../components/SidebarModal';
import { useNavigate } from 'react-router-dom';
import OrganizationForm from './forms/OrganizationForm';
import APIServices from '../../services/APIServices';
import appToast, { TOAST_TYPE } from '../../utils/AppToast';
import { getformattedPhoneNumber, hasPermission, PAGE_LIMIT, selectLoader } from '../../utils/Helpers';
import EmptyList from '../../components/EmptyList';
import DeleteModal from '../../components/DeleteModal';
import editIcon from '../../assets/img/edit.svg';
import deleteIcon from '../../assets/img/delete2.svg';
import mailIcon from '../../assets/img/mail2.svg';
import { useAuth } from 'contexts/AuthContext';

const ClientDirectory = () => {
  const navigate = useNavigate();
  const {userRole } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingOrg, setEditingOrg] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [Organization, setOrganizations] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Default to page 1
  const [totalPages, setTotalPages] = useState(0);
  const [orgToDelete, setOrgToDelete] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleBackClick = () => {
    navigate('/'); // This goes back to the previous page
  };

  const handleToggle = async (id, status = true) => {
    // setLoading(true); // Example loading state
    const payload = {
      id,
      status
    };

    // Update the team status in the state after successful API call
    setOrganizations((prevOrgs) => prevOrgs.map((org) => (org._id === id ? { ...org, status: status } : org)));
    try {
      const response = await APIServices.put('/organizations/update/status', payload);
      if (response.message) {
        appToast(response.message || 'Status updated successfully', TOAST_TYPE.SUCCESS);
        fetchOrganizations(currentPage);
      } else {
        appToast('Failed to update team status', TOAST_TYPE.ERROR);
      }
    } catch (err) {
      console.error('Error updating team status:', err);
      appToast('Failed to update team status', TOAST_TYPE.ERROR);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handlePageChange = useCallback(
    (page) => {
      if (page && page !== currentPage) {
        fetchOrganizations(page);
      }
    },
    [currentPage]
  );

  const toggleModal = (isOpen) => {
    setModalOpen(isOpen);
    if (isOpen == false) {
      setTimeout(() => {
        setEditingOrg(false);
      }, 100);
    }
  };

  // Fetch organizations data
  const fetchOrganizations = async (page = 1) => {
    setLoading(true);
    try {
      const response = await APIServices.get(`/organizations?page=${page}&limit=${PAGE_LIMIT}`);
      if (response.organizations) {
        setOrganizations(response.organizations);
        setCurrentPage(response.currentPage);
        setTotalPages(response.totalPages);
      } else if (response?.data?.message) {
        appToast(response?.data?.message, TOAST_TYPE.ERROR);
        setOrganizations([]);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleEditClick = (org) => {
    setEditingOrg(org);
    toggleModal(true);
  };

  const redirectToOrg = (org) => {
    if (org._id) {
      navigate('/client-directory/Client', {
        state: { orgId: org._id, orgName: org.name }
      });
    }
  };
  const handleDeleteOrg = (id) => {
    setOrgToDelete(id);
    setDeleteOpen(true); // Open the delete confirmation modal
  };

  const confirmDeleteOrg = async () => {
    if (orgToDelete) {
      setLoading(true);
      try {
        let response = await APIServices.delete(`/organizations/${orgToDelete}`);
        if (response.message) {
          appToast(response.message, TOAST_TYPE.SUCCESS);
        } else {
          appToast(response.data.message, TOAST_TYPE.ERROR);
        }
        fetchOrganizations(); // Refresh the list after deletion
        setDeleteOpen(false); // Close the modal
        setOrgToDelete(null); // Clear the member to delete
      } catch (error) {
        console.error('Error deleting Member:', error);
        appToast('Failed to delete Member', TOAST_TYPE.ERROR);
      }
    }
  };

  const closeAndUpdateList = () => {
    toggleModal(false);
    fetchOrganizations();
  };

  const HeaderContent = (
    <>
      <h2>Client Directory</h2>
      <div className="head-right">
        <a onClick={handleBackClick}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  const ModalContent = (
    <>
      <OrganizationForm initialData={editingOrg} closeModal={closeAndUpdateList} />
    </>
  );

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="wrapper">
      <div className="main-content client_directory">
        <Header content={HeaderContent} />
        {loading ? (
          <div className="nodata-loader">{selectLoader(100)}</div>
        ) : Organization?.length == 0 ? (
          <EmptyList buttonText={'Add New Client'} openModal={() => toggleModal(true)} />
        ) : (
          <section className="companies-sec">
            <div className="container">
              <div className="row client-desktop-view">
                <div className="col-lg-12 col-md-12">
                  <div className="companies-top-head">
                    <button onClick={() => toggleModal(true)} type="button" className="add-user">
                      + Add New Client
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="companies-card">
                    <div className="companies-list">
                      <div className="top-headTitle">
                        <h2>Client Company</h2>
                        <button className="team-add-btn" onClick={() => toggleModal(true)}>
                          <img src={addicon} alt="addicon" className="addicon" />
                          Add
                        </button>
                      </div>
                      <table className="grid">
                        <thead>
                          <tr>
                            <th>Client Directory</th>
                            <th>Phone</th>
                            <th>Website</th>
                            <th>City</th>
                            <th className="state">State</th>
                            {hasPermission(userRole, 'modify_team') &&<th>Actions</th>}
                          </tr>
                        </thead>

                        <tbody>
                          {Organization?.map((org, index) => (
                            <tr key={org._id}>
                              <td className="cursor_pointer" onClick={() => redirectToOrg(org)}>
                                {org.name}
                              </td>
                              <td className="mobile-view-td">
                                <ul>
                                  <li>
                                    <a href="#">{org.website}</a>
                                  </li>
                                  <li> {org.countryDialCode + getformattedPhoneNumber(org.phoneNumber)}</li>
                                </ul>
                              </td>
                              <td className="mobile-view-td">
                                <ul className=" py-0 no-before">
                                  <li>
                                    {org.city}, {org.state}
                                  </li>
                                </ul>
                              </td>
                              <td className="desktop-view-td">
                                {org.countryDialCode + ' ' + getformattedPhoneNumber(org.phoneNumber)}
                              </td>
                              <td className="desktop-view-td">{org.website}</td>

                              <td className="desktop-view-td">{org.city}</td>
                              <td className="state desktop-view-td">{org.state}</td>
                              {hasPermission(userRole, 'modify_team') && (
                                <td>
                                  <ul className="actions">
                                    <li>
                                      <button className="btn btn-pen" onClick={() => handleEditClick(org)}>
                                        <i className="fas fa-pen"></i>
                                      </button>
                                    </li>
                                    <li>
                                      <button className="btn btn-trash" onClick={() => handleDeleteOrg(org._id)}>
                                        <i className="fas fa-trash"></i>
                                      </button>
                                    </li>
                                    {/* <li className="mobile-view-td">
                                    <button
                                      className="btn btn-trash"
                                      onClick={() => handleDeleteOrg(org._id)}
                                    >
                                      <i className="fas fa-trash"></i>
                                      <i
                                        className="fa fa-envelope"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </li> */}
                                    <li className="custom-switch-right">
                                      {' '}
                                      <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input
                                          checked={org.status}
                                          // defaultChecked={org.isActive}
                                          // readOnly={org.status == "invited"}
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`customSwitch-${index}`}
                                          onChange={() => handleToggle(org._id, !org.status)}
                                        />
                                        <label className="custom-control-label" htmlFor={`customSwitch-${index}`}>
                                          &nbsp;
                                        </label>
                                      </div>
                                    </li>
                                  </ul>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                        {totalPages > 1 && (
                          <tfoot>
                            <tr colSpan={5} className="tr_empty"></tr>
                            <tr>
                              <td colSpan="6" style={{ padding: 0 }}>
                                <div className="pagination-pages">
                                  <ResponsivePagination
                                    current={currentPage}
                                    total={totalPages}
                                    onPageChange={handlePageChange}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        )}
                      </table>
                      {/* <table>
                        <thead>
                          <tr>
                            <th>Client Directory</th>
                            <th>Phone</th>
                            <th>Website</th>
                            <th>City</th>
                            <th className="state">State</th>
                            <th>Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {Organization?.map((org, index) => (
                            <tr key={org._id}>
                              <td
                                className="cursor_pointer"
                                onClick={() => redirectToOrg(org)}
                              >
                                {org.name}
                              </td>
                              <td>{org.countryDialCode + org.phoneNumber}</td>
                              <td>{org.website}</td>
                              <td>{org.city}</td>
                              <td className="state">{org.state}</td>
                              <td>
                                <ul className="actions">
                                  <li>
                                    <button
                                      className="btn btn-pen"
                                      onClick={() => handleEditClick(org)}
                                    >
                                      <i className="fas fa-pen"></i>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="btn btn-trash"
                                      onClick={() => handleDeleteOrg(org._id)}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button>
                                  </li>
                                  <li>
                                    {" "}
                                    <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                      <input
                                        defaultChecked={org.isActive}
                                        readOnly={true}
                                        type="checkbox"
                                        className="custom-control-input"
                                        // id="customSwitch3"
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customSwitch3"
                                      >
                                        &nbsp;
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr colSpan={5} className="tr_empty"></tr>
                          <tr>
                            <td colSpan="6" style={{ padding: 0 }}>
                              <div className="pagination-pages">
                                <ResponsivePagination
                                  current={currentPage}
                                  total={totalPages}
                                  onPageChange={handlePageChange}
                                />
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* Modal Component */}
        <SidebarModal
          extraClass={'edit-profile-modal client_modal'}
          show={modalOpen}
          onHide={() => toggleModal(false)}
          headerTitle={editingOrg ? 'Edit Client Company' : 'Add New Client Company'}>
          {ModalContent}
        </SidebarModal>
        <DeleteModal
          show={deleteOpen}
          onHide={() => {
            setDeleteOpen(false);
            setOrgToDelete(null); // Clear the member to delete on cancel
          }}
          handleDelete={confirmDeleteOrg} // Pass the deletion logic here
          loading={loading}
          itemName={'client'}
        />
      </div>
    </div>
  );
};

export default ClientDirectory;
