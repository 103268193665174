import React, { useEffect, useState } from "react";
import attechFile from "../../../assets/img/ios-attach.svg";
import filePic from "../../../assets/img/file-picture.svg";
import deleteBtn from "../../../assets/img/delete.svg";
import {
  hasPermission,
  MAX_FILE_SIZE,
  selectLoader,
  userNameLimit,
  validateTextInput,
} from "../../../utils/Helpers";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import APIServices from "../../../services/APIServices";
import { useAuth } from "../../../contexts/AuthContext";

const ChangeOrderModal = ({
  projectId = "",
  requestId = "",
  setQouteRequired = false,
  setChangeReqId,
  closeModal,
}) => {
  const { user, isUser1, userRole } = useAuth();
  const [files, setFiles] = useState([]);
  const [isEditing, setEditing] = useState(false);
  const [filestoDelete, setFilesToDelete] = useState([]);
  const [requestDetails, setRequestDetails] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isQoute, setQoute] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    attachments: [],
    description: "",
    projectId: projectId || "",
    title: "",
    requestType: "change_order",
  });

  useEffect(() => {
    if (requestId) {
      setEditing(true);
      fetchRequestDetails();
      console.log(requestId);
    }
  }, [requestId]);

  useEffect(() => {
    if (requestDetails) {
      console.log(requestDetails);
      setFormData((prevData) => ({
        ...prevData,
        description: requestDetails?.description,
        title: requestDetails?.title,
        attachments: requestDetails?.attachments,
      }));
      setFiles(requestDetails?.attachments);
    }
  }, [requestDetails]);

  const fetchRequestDetails = async () => {
    setPageLoading(true);
    try {
      let response = await APIServices.get(
        `/project-requests/detail/${requestId}`
      );
      if (response.data) {
        setRequestDetails(response.data);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
    } finally {
      setPageLoading(false);
    }
  };
  // Handle input change for title and description
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  // Validate fields before submission
  const validateForm = () => {
    const newErrors = {};
    if (!validateTextInput(formData.title).isValid) {
      if (!validateTextInput(formData.title).cleanedValue) {
        newErrors.title = "Request Title is required.";
      } else {
        newErrors.title = validateTextInput(formData.title).message;
      }
    }

    if (!validateTextInput(formData.description).isValid) {
      if (!validateTextInput(formData.description).cleanedValue) {
        newErrors.description = "Request Description is required.";
      } else {
        newErrors.description = validateTextInput(formData.description).message;
      }
    }

    // if (files.length === 0) newErrors.files = "At least one file must be attached.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle file changes
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const validFiles = [];
    const errors = [];
    const selectedFiles = Array.from(e.target.files);
    const totalFiles = files.length + selectedFiles.length;

    // Check if the total number of files exceeds the limit
    if (totalFiles > 5) {
      appToast("You can upload a maximum of 5 files.", TOAST_TYPE.ERROR);
      return;
    }

    // Calculate the total size of all files including the new ones
    const totalSize =
      files.reduce((sum, file) => sum + file.size, 0) +
      selectedFiles.reduce((sum, file) => sum + file.size, 0);

    // Check if the total size exceeds the 40MB limit
    if (totalSize > 40 * 1024 * 1024) {
      appToast(
        "The total size of uploaded files should not exceed 40MB.",
        TOAST_TYPE.ERROR
      );
      return;
    }
    newFiles.forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        errors.push(`${file.name} exceeds the maximum file size of 20MB.`);
      } else {
        validFiles.push(file);
      }
    });

    if (errors.length > 0) {
      appToast(errors.join(" "), TOAST_TYPE.ERROR);
    }

    if (validFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  // Remove file from the list
  const handleRemoveFile = (index) => {
    if (isEditing) {
      let fileToDelete = files.filter((_, i) => i == index);
      console.log(fileToDelete[0]._id);
      if (fileToDelete && fileToDelete.length > 0 && fileToDelete[0]._id) {
        setFilesToDelete((prevFiles) => [...prevFiles, fileToDelete[0]._id]);
      }
    }
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    setLoading(true);
    const formDataToSend = new FormData();

    // Dynamically append form fields
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "attachments") {
        // Skip attachments for now
        formDataToSend.append(key, value);
      }
    });

    // Append attachments separately
    files.forEach((file) => {
      formDataToSend.append("attachments", file);
    });

    if (isEditing && filestoDelete.length > 0) {
      formDataToSend.append("deletedAttachments", filestoDelete.join(","));
    }
    try {
      const response = isEditing
        ? await APIServices.put(
            `/project-requests/${requestId}`,
            formDataToSend,
            {
              "Content-Type": "multipart/form-data",
            }
          )
        : await APIServices.post("/project-requests", formDataToSend, {
            "Content-Type": "multipart/form-data",
          });
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
        setChangeReqId(response.data._id || "");
        setQouteRequired(isQoute);
        closeModal();
      } else if (response.data.message) {
        appToast(response.data.message, TOAST_TYPE.ERROR);
        closeModal();
      }
    } catch (error) {
      console.error("Error submitting change order request:", error);
      // appToast("An error occurred. Please try again.", TOAST_TYPE.ERROR);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setQouteRequired(isQoute);
  }, [isQoute]);

  if (pageLoading) {
    return <div className="nodata-loader"> {selectLoader(60)}</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="form-group">
            <input
              maxLength={userNameLimit}
              type="text"
              className={`form-control ${errors.title ? "form-invalid" : ""}`}
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Title"
            />
            {errors.title && (
              <small className="text-danger">{errors.title}</small>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <textarea
              // style={{ maxHeight: "320px" }}
              name="description"
              placeholder="Description"
              className={`form-control ${
                errors.description ? "form-invalid" : ""
              }`}
              value={formData.description}
              onChange={(e) => {
                handleInputChange(e);
                e.target.style.height = "auto"; // Reset the height
                e.target.style.height = `${e.target.scrollHeight + 2}px`; // Set the height to fit content
              }}
            />
            {errors.description && (
              <small className="text-danger">{errors.description}</small>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="cardbg-form-group">
            <div className="attech-file-cover">
              <a className="attech-btn">
                <img
                  src={attechFile}
                  alt="Attach"
                  className="attechFile mr-1"
                />
                Attach Files
              </a>
              <label className="add-btn-text cursor_pointer">
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                + Add
              </label>
            </div>
            {files.map((file, index) => (
              <div className="user-box-cover" key={index}>
                <div className="username">
                  <img src={filePic} alt="File" className="filePic" />
                  <div className="username-content">
                    <p>{file.name}</p>
                    <small>{(file.size / 1024).toFixed(2) !== "NaN" ? `${(file.size / 1024).toFixed(2)} KB` : file.size}</small>
                  </div>
                </div>
                <img
                  src={deleteBtn}
                  alt="Delete"
                  className="deleteBtn cursor_pointer"
                  onClick={() => handleRemoveFile(index)}
                />
              </div>
            ))}

            {/* {errors.files && <small className="text-danger">{errors.files}</small>} */}
          </div>
          {!isEditing && hasPermission(userRole, "add_qoute") && (
            <div className="form-group">
              <div className="qoute-cover form-control">
                <span>Add Quote</span>
                <div className="form-switch ">
                  <input
                    type="checkbox"
                    checked={isQoute}
                    onChange={() => {
                      setQoute((prev) => !prev);
                    }}
                    name="addQoute"
                    hidden="hidden"
                    id="projectdelay"
                  />
                  <label className="switch" htmlFor="projectdelay"></label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <button type="submit" className="btn-black">
        {loading ? selectLoader(35) : isEditing ? "Update" : "Submit"}
      </button>
    </form>
  );
};

export default ChangeOrderModal;
