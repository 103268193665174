import React from "react";
import SignUpHeader from "./SignUpHeader";
import lovely from "../../../assets/img/lovely.svg";
import tickcircle from "../../../assets/img/tick-circle.svg";
import closecircle from "../../../assets/img/close-circle.svg";
import crown from "../../../assets/img/crown.svg";
import share from "../../../assets/img/share.svg";

const SubPlan = () => {
  return (
    <div className="wrapper welcome-page">
      <div className="main-content">
        <SignUpHeader />
        <div className="feature-sec plan-list-sec">
          <div className="container">
            <p>What features are important to you?</p>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="plan-card-box">
                  <div className="plan-content-head">
                    <img
                      src={lovely}
                      alt={lovely}
                      className="lovely"
                    />
                    <h2>Starter</h2>
                  </div>
                  <p className="plan-text-content">
                  Perfect plan to get started
                  </p>
                  <span>$5 <sub>/month</sub> </span>
                  <ul className="plan-list">
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Client updates & notifications</p>
                      </li>
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Basic approval workflows</p>
                      </li>
                      <li>
                      <img
                      src={closecircle}
                      alt={closecircle}
                      className="closecircle"
                    />
                        <p>Change order management</p>
                      </li>
                      <li>
                      <img
                      src={closecircle}
                      alt={closecircle}
                      className="closecircle"
                    />
                        <p>AI-powered recommendations</p>
                      </li>
                      <li>
                      <img
                      src={closecircle}
                      alt={closecircle}
                      className="closecircle"
                    />
                        <p>Full-suite automation</p>
                      </li>
                   
                  </ul>
                  <button class="btn-black" type="button">
              Continue
            </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="plan-card-box">
                  <div className="plan-content-head">
                    <img
                      src={crown}
                      alt={crown}
                      className="crown"
                    />
                    <h2>Pro</h2>
                  </div>
                  <p className="plan-text-content">
                  Perfect plan for professionals!
                  </p>
                  <span>$12 <sub>/month</sub> </span>
                  <ul className="plan-list">
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Everything in Starter Plan</p>
                      </li>
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Change order management</p>
                      </li>
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Milestone tracking</p>
                      </li>
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>AI-driven recommendations</p>
                      </li>
                      <li>
                      <img
                      src={closecircle}
                      alt={closecircle}
                      className="closecircle"
                    />
                        <p>Full-suite automation</p>
                      </li>
                   
                  </ul>
                  <button class="btn-black" type="button">
              Continue
            </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="plan-card-box">
                  <div className="plan-content-head">
                    <img
                      src={share}
                      alt={share}
                      className="share"
                    />
                    <h2>Enterprise</h2>
                  </div>
                  <p className="plan-text-content">
                  Best suits for great company!
                  </p>
                  <span>$33 <sub>/month</sub> </span>
                  <ul className="plan-list">
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Everything in Pro Plan</p>
                      </li >
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Full-suite automation</p>
                      </li>
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Advanced integrations</p>
                      </li>
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Priority support</p>
                      </li>
                      <li className="dark-text">
                      <img
                      src={tickcircle}
                      alt={tickcircle}
                      className="tickcircle"
                    />
                        <p>Custom workflow automations</p>
                      </li>
                   
                  </ul>
                  <button class="btn-black" type="button">
              Continue
            </button>
                </div>
              </div>
            </div>

            <a href="#" className="choose-later">I’ll Choose Later</a>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubPlan;
