import React, { useEffect, useState } from "react";
import leftArrow from "../../../assets/img/left-arrow.svg";
import smartphone from "../../../assets/img/smartphone.svg";
import instagram1 from "../../../assets/img/instagram1.svg";
import facebook1 from "../../../assets/img/facebook1.svg";
import linkdin1 from "../../../assets/img/linkdin1.svg";
import close2 from "../../../assets/img/close2.svg";
import mail from "../../../assets/img/mail.svg";
import messageSquare from "../../../assets/img/message-square.svg";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/settings.css";
import axios from "axios"; // Add axios for API call
import TimePicker from "rc-time-picker";
import moment from "moment"; // Import moment
import "rc-time-picker/assets/index.css"; // Import the required styles
import { useAuth } from "../../../contexts/AuthContext";
import APIServices from "../../../services/APIServices";
import appToast, { TOAST_TYPE } from "../../../utils/AppToast";
import { selectLoader } from "../../../utils/Helpers";
import { useStore } from "../../../hooks/useStore";

const Settings = () => {
  const navigate = useNavigate();
  const { user, isUser1 } = useAuth();
  const [userId, setUserId] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [store, setStore] = useStore();
  // State for notifications, Do Not Disturb, and DateTime format
  const [notifications, setNotifications] = useState({
    mobile: true,
    mail: true,
    sms: false,
  });

  const [dndSettings, setDndSettings] = useState({
    dndStatus: true,
    timezoneOffset: "200",
    startTime: "",
    endTime: "",
  });

  const [dateTimeFormat, setDateTimeFormat] = useState({
    startCalenderWeek: "Monday",
    timeFormat: "12hour",
    dateFormat: "dd/mm/yyyy",
  });

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (user) {
      // console.log(user._id);

      setUserId(user._id);
    }
  }, [user]);

  const fetchSettings = async () => {
    try {
      const response = await APIServices.get(`/user-settings/${userId}`); // Replace with your actual endpoint
      console.log(response);

      if (response.userId) {
        // Set the state with the fetched data
        setNotifications(response.notification);
        setDndSettings(response.doNotDisturb);
        setDateTimeFormat(response.dateTimeFormat);
      } else {
        appToast(response.data.message, TOAST_TYPE.ERROR);
        // navigate(`/verification-status`, { state: { isVerified: false } });
      }
    } catch (error) {
      console.error("Error fetching settings:", error);
      appToast("An error occurred while fetching settings.", TOAST_TYPE.ERROR);
      alert("An error occurred while fetching settings.");
    } finally {
      setPageLoading(false);
    }
  };

  // Fetch settings from the backend when the component is mounted
  useEffect(() => {
    if (userId) {
      fetchSettings();
    }
  }, [userId]);

  // Handle time change for startTime
  const handleStartTimeChange = (value, isStartDate) => {
    const formattedTime = value ? value.format("HH:mm") : "00:00"; // Reset to "00:00" if closed
    setDndSettings((prevSettings) => {
      if (isStartDate) {
        const updatedSettings = {
          ...prevSettings,
          startTime: formattedTime,
        };
        handleSaveSettings(notifications, updatedSettings, dateTimeFormat);
        return { ...prevSettings, startTime: formattedTime };
      } else {
        const updatedSettings = {
          ...prevSettings,
          endTime: formattedTime,
        };
        handleSaveSettings(notifications, updatedSettings, dateTimeFormat);
        return { ...prevSettings, endTime: formattedTime };
      }
    });
  };
  const handleNotificationChange = (type, name = "none") => {
    setNotifications((prevNotifications) => {
      const updatedNotifications = {
        ...prevNotifications,
        [type]: name == "none" ? false : true,
      };
      // !prevNotifications[type]
      handleSaveSettings(updatedNotifications, dndSettings, dateTimeFormat);
      return updatedNotifications;
    });
  };

  const handleDndChange = (event) => {
    const { name, value, type, checked } = event.target;
    setDndSettings((prevDndSettings) => {
      const updatedDndSettings = {
        ...prevDndSettings,
        [name]: type === "checkbox" ? checked : value,
      };
      handleSaveSettings(notifications, updatedDndSettings, dateTimeFormat);
      return updatedDndSettings;
    });
  };

  const handleDateTimeFormatChange = (type, value) => {
    if(type === "dateFormat"){
      setStore({currentDateFormat: value})
    }else if(type === "timeFormat"){
      setStore({currentTimeFormat: value})
    }else if(type === "startCalenderWeek"){
      setStore({startCalenderWeek: value})
    }
    if (type && value) {
      setDateTimeFormat((prevDateTimeFormat) => {
        const updatedDateTimeFormat = {
          ...prevDateTimeFormat,
          [type]: value,
        };
        handleSaveSettings(notifications, dndSettings, updatedDateTimeFormat);
        return updatedDateTimeFormat;
      });
    }
  };

  const handleSaveSettings = async (
    updatedNotifications = notifications,
    updatedDndSettings = dndSettings,
    updatedDateTimeFormat = dateTimeFormat
  ) => {
    const payload = {
      userId: "6761a9079a623a88f8f4537c", // Replace with actual userId
      notification: updatedNotifications,
      doNotDisturb: updatedDndSettings,
      dateTimeFormat: updatedDateTimeFormat,
    };
    console.log(payload);

    try {
      const response = await APIServices.put(
        `/user-settings/${userId}`,
        payload
      );
      if (response.message) {
        appToast(response.message, TOAST_TYPE.SUCCESS);
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      // Optionally, show an error message
    }
  };

  const HeaderContent = (
    <>
      <h2>Settings</h2>
      <div className="head-right">
        <a onClick={handleBackClick}>
          <img src={leftArrow} alt="comp1" className="left-arrow" />
        </a>
      </div>
    </>
  );

  return (
    <div className="wrapper">
      <div className="main-content">
        <Header content={HeaderContent} />
        {pageLoading ? (
          <div className="nodata-loader">{selectLoader(70)}</div>
        ) : (
          <div className="setting-page">
            {/* Notifications section */}
            <div className="setting-sec">
              <div className="setting-head">
                <div className="setting-head-content">
                  <h2>Notifications</h2>
                  <p>Customise your notifications</p>
                </div>
              </div>
              <div className="setting-card">
                <div className="table-body">
                  <table className="table">
                    <tr>
                      <th>&nbsp;</th>
                      <th className="text-center">None</th>
                      <th className="text-right">All activity</th>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src={smartphone}
                          alt="smartphone"
                          className="smartphone"
                        /><span className="pt-2">Push Notification</span>
                      </td>
                      <td>
                        <div className="checkbox-cover text-center">
                          <input
                            type="checkbox"
                            id="mobile"
                            checked={!notifications.mobile}
                            onChange={() => handleNotificationChange("mobile")}
                          />
                          <label htmlFor="mobile">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-cover text-right">
                          <input
                            type="checkbox"
                            id="mobile-all"
                            checked={notifications.mobile}
                            onChange={() =>
                              handleNotificationChange("mobile", "all")
                            }
                          />
                          <label htmlFor="mobile-all">&nbsp;</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img src={mail} alt="mail" className="mail-img" /><span className="pt-2">Email</span>
                      </td>
                      <td>
                        <div className="checkbox-cover text-center">
                          <input
                            type="checkbox"
                            id="mail"
                            checked={!notifications.mail}
                            onChange={() => handleNotificationChange("mail")}
                          />
                          <label htmlFor="mail">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-cover text-right">
                          <input
                            type="checkbox"
                            id="mail-all"
                            checked={notifications.mail}
                            onChange={() =>
                              handleNotificationChange("mail", "all")
                            }
                          />
                          <label htmlFor="mail-all">&nbsp;</label>
                        </div>
                      </td>
                    </tr>
                    <tr className="last-row">
                      <td>
                        <img
                          src={messageSquare}
                          alt="messageSquare"
                          className="messageSquare"
                        /><span className="pt-2">SMS</span>
                      </td>
                      <td>
                        <div className="checkbox-cover text-center">
                          <input
                            type="checkbox"
                            id="sms"
                            checked={!notifications.sms}
                            onChange={() => handleNotificationChange("sms")}
                          />
                          <label htmlFor="sms">&nbsp;</label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox-cover text-right">
                          <input
                            type="checkbox"
                            id="sms-all"
                            checked={notifications.sms}
                            onChange={() =>
                              handleNotificationChange("sms", "all")
                            }
                          />
                          <label htmlFor="sms-all">&nbsp;</label>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            {/* Do Not Disturb (DND) section */}
            <div className="setting-sec">
              <div className="setting-head">
                <div className="setting-head-content">
                  <h2>Do Not Disturb (DND)</h2>
                  <p>
                    Set specific hours during which notifications will be
                    silenced{" "}
                  </p>
                </div>
                <div className="form-switch">
                  <input
                    type="checkbox"
                    name="dndStatus"
                    hidden
                    id="dndStatus"
                    checked={dndSettings.dndStatus}
                    onChange={handleDndChange}
                  />
                  <label className="switch" htmlFor="dndStatus"></label>
                </div>
              </div>
              <div className="setting-card">
                <div className="date-cover">
                  <div className="date-content">
                    <p>Start time</p>
                    <TimePicker
                      value={
                        dndSettings.startTime
                          ? moment(dndSettings.startTime, "HH:mm")
                          : moment("00:00", "HH:mm") // Ensure it's moment if there's a value
                      }
                      onChange={(val) => {
                        handleStartTimeChange(val, true);
                      }}
                      format="HH:mm" // 24-hour format
                      showSecond={false} // Hide seconds
                      use12Hours={false} // Use 24-hour format
                    />
                  </div>
                  <div className="date-content">
                    <p>End time</p>
                    <TimePicker
                      value={
                        dndSettings.endTime
                          ? moment(dndSettings.endTime, "HH:mm")
                          : moment("00:00", "HH:mm") // Ensure it's moment if there's a value
                      }
                      onChange={(val) => {
                        handleStartTimeChange(val, false);
                      }}
                      format="HH:mm" // 24-hour format
                      showSecond={false} // Hide seconds
                      use12Hours={false} // Use 24-hour format
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Time & Date format section */}
            <div className="setting-sec">
              <div className="setting-head">
                <div className="setting-head-content">
                  <h2>Time & Date Format</h2>
                  <p>Select the way times & dates are displayed </p>
                </div>
              </div>
              <div className="setting-card">
                <div className="week-cover">
                  <p>Start of the calendar week</p>
                  <div className="week-content">
                    <div className="checkbox-cover">
                      <input
                        type="checkbox"
                        id="monday"
                        checked={dateTimeFormat.startCalenderWeek === "Monday"}
                        onChange={() =>
                          handleDateTimeFormatChange(
                            "startCalenderWeek",
                            "Monday"
                          )
                        }
                      />
                      <label htmlFor="monday">Monday</label>
                    </div>
                    <div className="checkbox-cover">
                      <input
                        type="checkbox"
                        id="sunday"
                        checked={dateTimeFormat.startCalenderWeek === "Sunday"}
                        onChange={() =>
                          handleDateTimeFormatChange(
                            "startCalenderWeek",
                            "Sunday"
                          )
                        }
                      />
                      <label htmlFor="sunday">Sunday</label>
                    </div>
                  </div>

                  <p>Time format</p>
                  <div className="week-content">
                    <div className="checkbox-cover">
                      <input
                        type="checkbox"
                        id="hour2"
                        checked={dateTimeFormat.timeFormat === "12hour"}
                        onChange={() =>
                          handleDateTimeFormatChange("timeFormat", "12hour")
                        }
                      />
                      <label htmlFor="hour2">12 hour</label>
                    </div>
                    <div className="checkbox-cover">
                      <input
                        type="checkbox"
                        id="hour"
                        checked={dateTimeFormat.timeFormat === "24hour"}
                        onChange={() =>
                          handleDateTimeFormatChange("timeFormat", "24hour")
                        }
                      />
                      <label htmlFor="hour">24 hour</label>
                    </div>
                  </div>

                  <p>Date format</p>
                  <div className="week-content mb-0">
                    <div className="checkbox-cover">
                      <input
                        type="checkbox"
                        id="DateFormat2"
                        checked={dateTimeFormat.dateFormat === "dd/mm/yyyy"}
                        onChange={() =>
                          handleDateTimeFormatChange("dateFormat", "dd/mm/yyyy")
                        }
                      />
                      <label htmlFor="DateFormat2">dd/mm/yyyy</label>
                    </div>
                    <div className="checkbox-cover">
                      <input
                        type="checkbox"
                        id="DateFormat1"
                        checked={dateTimeFormat.dateFormat === "mm/dd/yyyy"}
                        onChange={() =>
                          handleDateTimeFormatChange("dateFormat", "mm/dd/yyyy")
                        }
                      />
                      <label htmlFor="DateFormat1">mm/dd/yyyy</label>
                    </div>
                    <div className="checkbox-cover">
                      <input
                        type="checkbox"
                        id="DateFormat3"
                        checked={dateTimeFormat.dateFormat === "yyyy/mm/dd"}
                        onChange={() =>
                          handleDateTimeFormatChange("dateFormat", "yyyy/mm/dd")
                        }
                      />
                      <label htmlFor="DateFormat3">yyyy/mm/dd</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* this functionalities willl be released on the next phase */}
              <div className="d-none">
                {/* section start */}
                <div className="setting-sec">
                  <div className="setting-head">
                    <div className="setting-head-content">
                      <h2>Referrals</h2>
                      <p>Share it with friends/referral </p>
                    </div>
                  </div>
                  <div className="setting-card">
                    <div className="referals-cover">
                      <h3>Love Happy Ending?</h3>
                      <p>
                        Share with your colleagues and friends to give them 10%
                        off!
                      </p>
                      <button className="shareable-btn">
                        Copy shareable link
                      </button>
                      <p className="sher-text">Share link via</p>
                      <div className="social-icon">
                        <a href="">
                          <img
                            src={instagram1}
                            alt="instagram1"
                            className="instagram1"
                          />
                        </a>
                        <a href="">
                          <img
                            src={facebook1}
                            alt="facebook1"
                            className="facebook1"
                          />
                        </a>
                        <a href="">
                          <img
                            src={linkdin1}
                            alt="linkdin1"
                            className="linkdin1"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* section start */}
                <div className="setting-sec">
                  <div className="setting-head">
                    <div className="setting-head-content">
                      <h2>Subscription</h2>
                      <p>Control your plan and preferences </p>
                    </div>
                  </div>
                  <div className="setting-card">
                    <div className="subscription-cover">
                      <p className="current-plan">Current Plan</p>
                      <p className="unlimited-text">Happy Ending Unlimited</p>
                      <span>$49.00 per month</span>
                      <div className="change-plan">
                        <p>Your plan will be changed on March 8, 2025.</p>
                        <p className="cancel-plan">Cancel plan</p>
                      </div>
                      <div className="payment-method-cover">
                        <p className="payment-method-text">Share link via</p>
                        <p className="add-newmethod">+ Add new method</p>
                      </div>
                      <div className="payment-card-details">
                        <label className="lableText-left">
                          Mastercard XXXX 1234
                        </label>
                        <label className="lableText-right">
                          Expires 11/2025{" "}
                          <img src={close2} alt="close2" className="close2" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
