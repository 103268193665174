import React from 'react';
import BoardWrapper from './BoardWrapper';
import { SocketProvider } from '../context/SocketContext';

export default function ProjectBoardWrapperPage({ projectBoardId, ...rest }) {
  return (
    <SocketProvider projectBoardId={projectBoardId}>
      <BoardWrapper projectBoardId={projectBoardId} {...rest} />
    </SocketProvider>
  );
}
